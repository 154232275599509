import Logic from "./logic";
import TradeEntryType from "../../components/analyser/TradeEntryType";
import WatchlistWildcardNone from "../../components/analyser/WatchlistWildcardNone";
import TradeAnalysis from "../../components/analyser/TradeAnalysis";
import InstrumentAnalytics from "../../components/analyser/InstrumentAnalytics";
import PositionType from "../../components/analyser/PositionType";
import Returns from "../../components/analyser/PercentReturns";
import InstrumentAnalyticsByDate from "../../components/analyser/InstrumentAnalyticsByDate";
import useWindowDimensions from "../../utils/width";
import Tooltip from "@mui/material/Tooltip";

const AnalyserPage = () => {
  const { trades, runSpeficCal, theme, changeYear, handleMode, auth } = Logic();
  const { width } = useWindowDimensions();

  return (
    trades && (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 auto",
          width: "100%",
          height: "100%",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: width >= 1430 ? "row" : "column",
            justifyContent: width >= 1430 ? "space-between" : "center",
            margin: "0 auto",
          }}
        >
          <h1
            style={{
              width: "100%",
              margin: "1.2em auto 0 auto",
              textAlign: "center",
              fontWeight: "300",
              fontSize: width <= 700 ? "18px" : "21px",
            }}
          >
            {auth.backtestingMode && "Backtesting Mode"}
            {!auth.backtestingMode && (
              <Tooltip title={"Change Equity Type"} placement="left">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleMode()}
                >
                  {localStorage.getItem("equity-mode")}
                </span>
              </Tooltip>
            )}
            {" - "}{" "}
            <Tooltip title={"Change Year"} placement="right">
              <span style={{ cursor: "pointer" }} onClick={() => changeYear()}>
                {localStorage.getItem("year") === "0"
                  ? "All Years"
                  : localStorage.getItem("year")}
              </span>
            </Tooltip>
          </h1>

          <div
            style={{
              width: width >= 1430 ? "50%" : "100%",
              margin: "0 auto",
            }}
          >
            <Returns
              trades={trades}
              runSpeficCal={runSpeficCal}
              theme={theme}
            />
            <TradeAnalysis
              trades={trades}
              runSpeficCal={runSpeficCal}
              theme={theme}
            />
            <TradeEntryType
              trades={trades}
              runSpeficCal={runSpeficCal}
              theme={theme}
            />
            <WatchlistWildcardNone
              trades={trades}
              runSpeficCal={runSpeficCal}
              theme={theme}
            />
            <PositionType
              trades={trades}
              runSpeficCal={runSpeficCal}
              theme={theme}
            />
            <InstrumentAnalyticsByDate
              trades={trades}
              runSpeficCal={runSpeficCal}
              theme={theme}
            />
          </div>
          <div
            style={{
              width: width >= 1430 ? "48%" : "100%",
            }}
          >
            <InstrumentAnalytics
              trades={trades}
              runSpeficCal={runSpeficCal}
              theme={theme}
            />
          </div>
        </div>
      </div>
    )
  );
};

export default AnalyserPage;
