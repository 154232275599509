import throwMessage from "../../utils/throwMessage";

function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
let running = false

export const mongoDBRequest = async (url, method, data) => {
  if (localStorage.getItem("TC-status") === "true" && url !== 'log-in') {
    if(!running)
    running = true
    const TC_TOKEN_AUTH = localStorage.getItem("TC_TOKEN_AUTH");
    let user = localStorage.getItem("user");

    const payload = data;
    // get role from cookie
    return fetch(
      `${process.env.REACT_APP_API}/${url}?` +
        new URLSearchParams({
          user_id: JSON.parse(user)?._id,
          role: JSON.parse(user)?.role,
        }),
      {
        method: `${method}`,
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${TC_TOKEN_AUTH}`,
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => {
        running = false
        return response.json();
      })
      .catch((err) => {
        running = false
        if (!isEmpty(err)) throwMessage("error","Database Error");
      });
  } else {
    throwMessage("error", "Check Your Network Connection");
  }
};
