import markets from "./markets";
import DoneIcon from "@mui/icons-material/Done";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const columnsComplex = (
  theme,
  throwMessage,
  idTradeGenerator,
  runDurationCal,
  handleOpenJournal,
  auth
) => {
  // const linkStyle = {
  //   color: theme[0] === "dark" ? "#FCFCFF" : "black",
  //   textDecoration: " none",
  // };

  const checkStopLossType = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);
    if (pairObject?.multiplier === 10) {
      return "Ticks";
    } else if (pairObject?.multiplier === 1) {
      return "Pips";
    }
  };

  let array = [
    {
      field: "pair",
      headerName: "Instrument",
      width: 110,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.pair}
            </p>
          );
        }
      },
    },
    {
      field: "journalID",
      headerName: "Journal",
      width: 95,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                margin: "0 15px 0 0",
              }}
            >
              <p
                style={{ cursor: "pointer", margin: "0 0 0 10px" }}
                onClick={() => {
                  if (!params.row.journalID) {
                    throwMessage("error", "No Journal Found");
                    handleOpenJournal(params.row, true);
                  } else {
                    handleOpenJournal(params.row, true);
                  }
                }}
              >
                {params.row.journalComplete === "true" ? (
                  <DoneIcon
                    style={{
                      color: "26a65d",
                    }}
                  />
                ) : (
                  <MenuBookIcon
                    style={{
                      color: "#f23645",
                    }}
                  />
                )}
              </p>
            </div>
          );
        }
      },
    },
    {
      field: "foreCastId",
      headerName: "Forecast",
      width: 93,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              margin: "0 15px 0 0",
            }}
          >
            {params.row.foreCastId === "Empty" ? (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            ) : params.row.foreCastId ? (
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "forecast-to-view",
                    params.row.foreCastId
                  );
                  localStorage.setItem("screen", 0);
                  auth.setTab(0);
                  localStorage.setItem("from-tracker", true);
                }}
              >
                <DoneIcon
                  style={{
                    color: "26a65d",
                  }}
                />
              </p>
            ) : (
              <QueryStatsIcon
                style={{
                  color: "#f23645",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.direction) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.direction === "Short"
                    ? "#db0671"
                    : params.row.direction === "Long"
                    ? "#0079cf"
                    : "",
              }}
            >
              {params.row.direction}
            </p>
          );
        }
      },
    },
    {
      field: "outcomePC",
      headerName: "Outcome PC",
      width: 105,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomePC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomePC === "Loss"
                    ? "#f23645"
                    : params.row.outcomePC === "Win"
                    ? "#26a65d"
                    : params.row.outcomePC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomePC}
            </p>
          );
        }
      },
    },
    {
      field: "outcomeIC",
      headerName: "Outcome IC",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomeIC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomeIC === "Loss"
                    ? "#f23645"
                    : params.row.outcomeIC === "Win"
                    ? "#26a65d"
                    : params.row.outcomeIC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomeIC}
            </p>
          );
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.grade) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.grade === "Low Risk"
                    ? "#26a65d"
                    : params.row.grade === "High Risk"
                    ? "#f23645"
                    : "",
              }}
            >
              {params.row.grade}
            </p>
          );
        }
      },
    },
    {
      field: "equityType",
      headerName: "Capital",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (params.row.equityType === "Personal & Investor Capital") {
          return <p>P I C</p>;
        } else if (params.row.equityType === "Personal Capital") {
          return <p>PC</p>;
        } else if (params.row.equityType === "Investor Capital") {
          return <p>IC</p>;
        } else if (params.row.equityType === "Backtest") {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossPercentagePC",
      headerName: "% PC",
      width: 85,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        // "Personal & Investor Capital"
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.profitLossPercentagePC) {
            return <p style={{ color: "#ED9E0E" }}>0 %</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentagePC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentagePC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentagePC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossDollarPersonal",
      headerName: "£ PC",
      width: 85,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.profitLossDollarPersonal) {
            return <p style={{ color: "#ED9E0E" }}>£ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossDollarPersonal < 0
                      ? "#f23645"
                      : params.row.profitLossDollarPersonal > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                £ {Math.abs(params.row.profitLossDollarPersonal)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "profitLossPercentageIC",
      headerName: "% IC",
      width: 85,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.profitLossPercentageIC) {
            return <p style={{ color: "#ED9E0E" }}>0 %</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentageIC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentageIC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentageIC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossDollarInvestor",
      headerName: "$ IC",
      width: 85,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.profitLossDollarInvestor) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossDollarInvestor < 0
                      ? "#f23645"
                      : params.row.profitLossDollarInvestor > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.profitLossDollarInvestor)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "feePC",
      headerName: "Fee PC",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.feePC) {
            return <p style={{ color: "#ED9E0E" }}>£ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.feePC < 0
                      ? "#f23645"
                      : params.row.feePC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                £ {Math.abs(params.row.feePC)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "feeIC",
      headerName: "Fee IC",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.feeIC) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.feeIC < 0
                      ? "#f23645"
                      : params.row.feeIC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.feeIC)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "returnTotalsPersonal",
      headerName: "P-R-R",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.returnTotalsPersonal) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.returnTotalsPersonal < 0
                      ? "#f23645"
                      : params.row.returnTotalsPersonal > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                £ {Math.abs(params.row.returnTotalsPersonal)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "returnTotalsInvestor",
      headerName: "I-R-R",
      width: 130,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.returnTotalsInvestor) {
            return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.returnTotalsInvestor < 0
                      ? "#f23645"
                      : params.row.returnTotalsInvestor > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                $ {Math.abs(params.row.returnTotalsInvestor)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "stopLossPipsPC",
      headerName: "Stop Loss PC",
      width: 120,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.stopLossPipsPC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsPC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "stopLossPipsIC",
      headerName: "Stop Loss IC",
      width: 120,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.stopLossPipsIC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsIC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "entryDate",
      headerName: "Entry Date",
      width: 95,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "entryTime",
      headerName: "Entry Time",
      width: 95,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "exitDate",
      headerName: "Exit Date",
      width: 95,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "exitTime",
      headerName: "Exit Time",
      width: 95,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "duration",
      headerName: "Duration",
      width: 90,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return runDurationCal(params);
      },
    },

    {
      field: "openPricePC",
      headerName: "Open PC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.openPricePC) {
            return "";
          } else {
            return <p>{params.row.openPricePC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "closePricePC",
      headerName: "Close PC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.closePricePC) {
            return "";
          } else {
            return <p>{params.row.closePricePC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "openPriceIC",
      headerName: "Open IC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.openPriceIC) {
            return "";
          } else {
            return <p>{params.row.openPriceIC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "closePriceIC",
      headerName: "Close IC",
      width: 85,
      editable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.closePriceIC) {
            return "";
          } else {
            return <p>{params.row.closePriceIC}</p>;
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "highlightedPair",
      headerName: "Watchlist",
      width: 100,
      headerAlign: "center",
      align: "center",
      editable: true,
    },
    {
      field: "firstEntryOrScaleIn",
      headerName: "Entry No",
      width: 90,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "entryType",
      headerName: "Entry Type",
      flex: 1,
      minWidth: 110,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "pattern",
      headerName: "Pattern",
      width: 225,
      headerAlign: "center",
      align: "center",
      editable: true,
    },

    {
      field: "timezone",
      headerName: "Timezone",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
  
        if (!params.row.timezone) {
          return "";
        } else {
          return <p>{params.row.timezone}</p>;
        }
      },
    },
    // {
    //   field: "newTransaction",
    //   headerName: "Funding",
    //   width: 150,
    //   valueFormatter: ({ value }) => `$ ${value}`,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "transanctionReason",
    //   headerName: "Type",
    //   width: 100,
    //   headerAlign: "center",
    //   align: "center",
    // },

    // {
    //   field: "transanctionNotes",
    //   headerName: "Note",
    //   width: 280,
    //   headerAlign: "center",
    //   align: "center",
    // },

    // {
    //   field: "min5_before",
    //   headerName: "5 Min Before",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.min5_before) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.min5_before}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "min15_before",
    //   headerName: "15 Min Before",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.min15_before) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.min15_before}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "hour1_before",
    //   headerName: "1 Hour Before",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.hour1_before) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.hour1_before}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "hour4_before",
    //   headerName: "4 Hour Before",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.hour4_before) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.hour4_before}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "daily_before",
    //   headerName: "Daily Before",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.daily_before) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.daily_before}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "weekly_before",
    //   headerName: "Weekly before",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.weekly_before) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.weekly_before}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "hour1_after",
    //   headerName: "1 Hour After",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.hour1_after) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.hour1_after}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "hour4_after",
    //   headerName: "4 Hour After",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.hour4_after) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.hour4_after}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },

    // {
    //   field: "daily_after",
    //   headerName: "Daily After",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.daily_after) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.daily_after}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },
    // {
    //   field: "weekly_after",
    //   headerName: "Weekly After",
    //   width: 140,
    //   headerAlign: "center",
    //   editable: true,

    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.weekly_after) {
    //       return "";
    //     } else {
    //       return (
    //         <a style={linkStyle} href={params.row.weekly_after}>
    //           View Snapshot
    //         </a>
    //       );
    //     }
    //   },
    // },
  ];

  const mode = localStorage.getItem("equity-mode");

  const PC_Fields = [
    "outcomePC",
    "profitLossPercentagePC",
    "profitLossDollarPersonal",
    "feePC",
    "returnTotalsPersonal",
    "stopLossPipsPC",
    "openPricePC",
    "closePricePC",
  ];
  const IC_Fields = [
    "outcomeIC",
    "profitLossPercentageIC",
    "profitLossDollarInvestor",
    "feeIC",
    "returnTotalsInvestor",
    "stopLossPipsIC",
    "openPriceIC",
    "closePriceIC",
  ];

  if (mode === "Personal Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !IC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else if (mode === "Investor Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !PC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else {
    return array;
  }
};

export default columnsComplex;
