import { React, useContext } from "react";

import ThemeContext from "../../lib/theme-context";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const CustomSelect = ({
  size,
  inputLabel,
  value,
  label,
  onChange,
  menuItemArray,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <FormControl fullWidth size={size}>
      <InputLabel
        sx={{
          color:
            theme[0] === "dark"
              ? "#FCFCFF !important"
              : "rgba(37,37,37) !important",
          borderOutline:
            theme[0] === "dark"
              ? "#FCFCFF !important"
              : "rgba(37,37,37) !important",
        }}
      >
        {inputLabel}
      </InputLabel>

      <Select
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              maxHeight: "450px",

              "& .MuiMenuItem-root": {
                padding: 1.5,
                justifyContent: "center",
              },
            },
          },
        }}
        defaultValue=""
        fullWidth
        sx={{
          ".MuiSvgIcon-root": {
            fill: theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
          },
          color: theme[0] === "dark" ? "#FCFCFF" : "black",

          "& fieldset": {
            // - The <fieldset> inside the Input-root

            borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
          },
          "&:hover fieldset": {
            borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
          },
          "&.Mui-focused fieldset": {
            // - Set the Input border when parent is focused
            borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
          },

          input: {
            margin: "0",
            color: theme[0] === "dark" ? "#FCFCFF" : "black",
          },
        }}
        value={value}
        label={label}
        onChange={onChange}
      >
        {JSON.parse(menuItemArray).map((item, index) => {
          return (
            <MenuItem
              key={item + index}
              sx={{
                color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
              }}
              value={item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
