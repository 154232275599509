import DoneIcon from "@mui/icons-material/Done";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import markets from "./markets";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const checkStopLossType = (p) => {
  const pairObject = markets.find((m) => m.ticker === p);
  if (pairObject?.multiplier === 10) {
    return "Ticks";
  } else if (pairObject?.multiplier === 1) {
    return "Pips";
  }
};

const columnsSimple = (
  theme,
  throwMessage,
  idTradeGenerator,
  runDurationCal,
  handleOpenJournal,
  auth
) => {
  let array = [
    {
      field: "pair",
      headerName: "Instrument",
      width: 175,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.pair}
            </p>
          );
        }
      },
    },

    {
      field: "journalID",
      headerName: "Journal",
      width: 95,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                margin: "0 15px 0 0",
              }}
            >
              <p
                style={{ cursor: "pointer", margin: "0 0 0 10px" }}
                onClick={() => {
                  if (!params.row.journalID) {
                    throwMessage("error", "No Journal Found");
                    handleOpenJournal(params.row, true);
                  } else {
                    handleOpenJournal(params.row, true);
                  }
                }}
              >
                {params.row.journalComplete === "true" ? (
                  <DoneIcon
                    style={{
                      color: "26a65d",
                    }}
                  />
                ) : (
                  <MenuBookIcon
                    style={{
                      color: "#f23645",
                    }}
                  />
                )}
              </p>
            </div>
          );
        }
      },
    },
    {
      field: "foreCastId",
      headerName: "Forecast",
      width: 93,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              margin: "0 15px 0 0",
            }}
          >
            {params.row.foreCastId === "Empty" ? (
              <HorizontalRuleIcon />
            ) : params.row.foreCastId ? (
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem(
                    "forecast-to-view",
                    params.row.foreCastId
                  );
                  localStorage.setItem("screen", 0);
                  auth.setTab(0);
                  localStorage.setItem("from-tracker", true);
                }}
              >
                <DoneIcon
                  style={{
                    color: "26a65d",
                  }}
                />
              </p>
            ) : (
              <QueryStatsIcon
                style={{
                  color: "#f23645",
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: "entryDate",
      headerName: "Entry Date",
      width: 156,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.entryDate) {
          return "";
        } else {
          return <p>{params.row.entryDate}</p>;
        }
      },
    },

    {
      field: "profitLossPercentagePC",
      headerName: "% PC",
      width: 156,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.profitLossPercentagePC) {
            return <p style={{ color: "#ED9E0E" }}>0 %</p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentagePC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentagePC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentagePC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "profitLossPercentageIC",
      headerName: "% IC",
      width: 156,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.profitLossPercentageIC) {
            return <p></p>;
          } else {
            return (
              <p
                style={{
                  color:
                    params.row.profitLossPercentageIC < 0
                      ? "#f23645"
                      : params.row.profitLossPercentageIC > 0
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
              >
                {Math.abs(params.row.profitLossPercentageIC)} %
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "direction",
      headerName: "Direction",
      width: 156,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.direction) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.direction === "Short"
                    ? "#db0671"
                    : params.row.direction === "Long"
                    ? "#0079cf"
                    : "",
              }}
            >
              {params.row.direction}
            </p>
          );
        }
      },
    },
    {
      field: "outcomePC",
      headerName: "Outcome PC",
      width: 156,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomePC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomePC === "Loss"
                    ? "#f23645"
                    : params.row.outcomePC === "Win"
                    ? "#26a65d"
                    : params.row.outcomePC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomePC}
            </p>
          );
        }
      },
    },
    {
      field: "outcomeIC",
      headerName: "Outcome IC",
      width: 156,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomeIC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomeIC === "Loss"
                    ? "#f23645"
                    : params.row.outcomeIC === "Win"
                    ? "#26a65d"
                    : params.row.outcomeIC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomeIC}
            </p>
          );
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 156,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (!params.row.grade) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.grade === "Low Risk"
                    ? "#26a65d"
                    : params.row.grade === "High Risk"
                    ? "#f23645"
                    : "",
              }}
            >
              {params.row.grade}
            </p>
          );
        }
      },
    },

    {
      field: "stopLossPipsPC",
      headerName: "Stop Loss PC",
      width: 156,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.stopLossPipsPC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsPC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "stopLossPipsIC",
      headerName: "Stop Loss IC",
      width: 156,
      headerAlign: "center",
      align: "center",
      editable: true,
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.stopLossPipsIC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsIC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },

    {
      field: "duration",
      headerName: "Duration",
      width: 156,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return runDurationCal(params);
      },
    },

    // {
    //   field: "profitLossDollar",
    //   headerName: "$ $ $",
    //   width: 157,
    //   valueFormatter: ({ value }) => `$ ${value}`,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     if (!params.row.profitLossDollar) {
    //       return "$ 0";
    //     } else {
    //       return (
    //         <p
    //           style={{
    //             color: params.row.profitLossDollar < 0 ? "#f23645" : "#26a65d",
    //           }}
    //         >
    //           $ {Math.abs(params.row.profitLossDollar)}
    //         </p>
    //       );
    //     }
    //   },
    // },
  ];

  const mode = localStorage.getItem("equity-mode");

  const PC_Fields = [
    "outcomePC",
    "profitLossPercentagePC",
    "profitLossDollarPersonal",
    "feePC",
    "returnTotalsPersonal",
    "stopLossPipsPC",
    "openPricePC",
    "closePricePC",
  ];
  const IC_Fields = [
    "outcomeIC",
    "profitLossPercentageIC",
    "profitLossDollarInvestor",
    "feeIC",
    "returnTotalsInvestor",
    "stopLossPipsIC",
    "openPriceIC",
    "closePriceIC",
  ];

  if (mode === "Personal Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !IC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else if (mode === "Investor Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !PC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else {
    return array;
  }
};

export default columnsSimple;
