import { useContext } from "react";
import Logic from "./logic";

import ThemeContext from "../../lib/theme-context";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import SaveIcon from "@mui/icons-material/Save";

import Tooltip from "@mui/material/Tooltip";

import CustomSelect from "../../components/select";
import CustomSingleTextField from "../../components/textfields/singleRow";

const TradeAssist = () => {
  const {
    markets,
    pair,
    setPair,
    timeFrame,
    setTimeFrame,
    timeframesArray,
    priceLarger,
    setPriceLarger,
    priceSmaller,
    setPriceSmaller,
    candleFormation,
    setCandleFormation,
    candleFormationArray,
    formSubmitted,
    submit,
    openPositions,
    action,
    setAction,
    actionArray
  } = Logic();

  const theme = useContext(ThemeContext);

  const style = { color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)" };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
      }}
    >
      <div
        style={{
          padding: "0 auto",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
        }}
      >
        <div
          style={{
            margin: "2em auto",
            padding: "0 1.2em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            height: "450px",
            width: "350px",
            borderRadius: "5%",
            border:
              theme[0] === "dark"
                ? "1px solid #FCFCFF"
                : "1px solid rgba(40, 42, 46)",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              margin: "0 auto",
              color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
            }}
          >
            Auto Trader
          </h1>
          <div>
            <CustomSelect
              size="small"
              inputLabel="Action"
              value={action}
              label="Action"
              onChange={(e) => setAction(e.target.value)}
              menuItemArray={JSON.stringify(actionArray)}
            />
          </div>
          <div>
            <FormControl fullWidth size={"small"}>
              <InputLabel
                sx={{
                  color:
                    theme[0] === "dark"
                      ? "#FCFCFF !important"
                      : "rgba(37,37,37) !important",
                  borderOutline:
                    theme[0] === "dark"
                      ? "#FCFCFF !important"
                      : "rgba(37,37,37) !important",
                }}
              >
                Instrument
              </InputLabel>

              <Select
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor:
                        theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      maxHeight: "450px",

                      "& .MuiMenuItem-root": {
                        padding: 1.5,
                        justifyContent: "center",
                      },
                    },
                  },
                }}
                defaultValue=""
                fullWidth
                sx={{
                  ".MuiSvgIcon-root": {
                    fill: theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  },
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",

                  "& fieldset": {
                    // - The <fieldset> inside the Input-root

                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
                  },
                  "&:hover fieldset": {
                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
                  },
                  "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor:
                      theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                  },

                  input: {
                    margin: "0",
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  },
                }}
                value={pair}
                label="Instrument"
                onChange={(event) => {
                  setPair(event.target.value);
                }}
              >
                {markets.map((m) => {
                  if (m.label === "Forex") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Forex
                      </ListSubheader>
                    );
                  } else if (m.label === "Indices") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Indices
                      </ListSubheader>
                    );
                  } else if (m.label === "Commodities") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Commodities
                      </ListSubheader>
                    );
                  } else if (m.label === "Crypto") {
                    return (
                      <ListSubheader
                        key={m.label}
                        sx={{
                          textAlign: "center",
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          color: "#ED9E0E",
                          fontSize: "0.6em",
                        }}
                      >
                        Crypto
                      </ListSubheader>
                    );
                  } else {
                    return (
                      <MenuItem key={m.label} sx={style} value={m.ticker}>
                        {m.label}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </div>
          <div>
            <CustomSelect
              size="small"
              inputLabel="Timeframe"
              value={timeFrame}
              label="Timeframe"
              onChange={(e) => setTimeFrame(e.target.value)}
              menuItemArray={JSON.stringify(timeframesArray)}
            />
          </div>
          <div>
            <CustomSingleTextField
              type="number"
              size="small"
              fullWidth
              label="Price Larger Than"
              inputProps={{ inputMode: "numeric" }}
              value={priceLarger}
              onChange={(event) => setPriceLarger(event.target.value)}
            />
          </div>
          <div>
            <CustomSingleTextField
              type="number"
              size="small"
              fullWidth
              label="Price Smaller Than"
              inputProps={{ inputMode: "numeric" }}
              value={priceSmaller}
              onChange={(event) => setPriceSmaller(event.target.value)}
            />
          </div>
          <div>
            <CustomSelect
              size="small"
              inputLabel="Candle Formation"
              value={candleFormation}
              label="Candle Formation"
              onChange={(e) => setCandleFormation(e.target.value)}
              menuItemArray={JSON.stringify(candleFormationArray)}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tooltip title="Save">
              <SaveIcon
                sx={{
                  color: formSubmitted ? "lightGrey" : "#26a65d",
                  cursor: "pointer",
                }}
                fontSize="large"
                onClick={() =>
                  submit(
                    pair,
                    timeFrame,
                    priceSmaller,
                    priceLarger,
                    candleFormation
                  )
                }
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <ul>
        {openPositions?.map((p) => {
          return (
            <li key={p.instrument}>
              {p.instrument} - {Number(p.long.units) > 0 ? "Long" : "Short"}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TradeAssist;
