import CircularProgress from "@mui/material/CircularProgress";

const Loading = () => {
  const theme = localStorage.getItem("theme");
  return (
    <div
      style={{
        backgroundColor: theme === "light" ? "#FCFCFF" : "rgba(40, 42, 46)"  ,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <CircularProgress
        size="3em"
        style={{
          color: theme === "dark" ? "#FCFCFF" : "black",
          marginBottom: "0",
        }}
      />
    </div>
  );
};

export default Loading;
