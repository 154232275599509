import { React, useContext } from "react";

import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";

import FormControl from "@mui/material/FormControl";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import Chip from "@mui/material/Chip";

const ArchivedTestsDropDown = ({
  selectedArchivedTestsBoolean,
  selectedArchivedTests,
  setSelectedArchivedTestsBoolean,
  setSelectedArchivedTests,
}) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);

  return (
    <FormControl sx={{ width: 260, height: "50px" }} size="small">
      <InputLabel
        sx={{
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
          borderOutline: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
        }}
      >
        {selectedArchivedTestsBoolean ? "Test" : "Archived"}
      </InputLabel>

      <Select
        sx={{
          ".MuiSvgIcon-root": {
            fill: theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
          },
          "& fieldset": {
            // - The <fieldset> inside the Input-root
            borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
          },
          "&:hover fieldset": {
            borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
          },
          "&.Mui-focused fieldset": {
            // - Set the Input border when parent is focused
            borderColor: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
          },

          input: {
            color: theme[0] === "dark" ? "#FCFCFF" : "black",
          },
        }}
        size="small"
        value={selectedArchivedTests ? selectedArchivedTests : ""}
        onChange={(event) => {
          setSelectedArchivedTestsBoolean(true);
          const {
            target: { value },
          } = event;

          setSelectedArchivedTests(
            typeof value === "string" ? value.split(",") : value
          );
        }}
        input={<OutlinedInput label="Chip" />}
        renderValue={(selected) => (
          <Chip
            key={JSON.parse(selected)[0]}
            label={JSON.parse(selected)[1]}
            sx={{
              color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
              border:
                theme[0] === "dark"
                  ? " 1px solid #FCFCFF"
                  : "1px solid rgba(37,37,37)",
            }}
          />
        )}
        MenuProps={{
          PaperProps: {
            sx: {
              bgcolor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              maxHeight: "450px",
              "& .MuiMenuItem-root": {
                padding: 1.5,
                justifyContent: "center",
              },
            },
          },
        }}
      >
        {auth.backtests
          ?.filter((trades) => trades.archived?.includes("true"))
          .map((test) => (
            <MenuItem
              sx={{
                color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
              }}
              key={test.testId}
              value={JSON.stringify([test._id, test.testId])}
            >
              {test.testId}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default ArchivedTestsDropDown;
