import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AuthContext from "../../lib/auth-context";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Monthly = ({ calculateFields, theme, years, selected, year }) => {
  const auth = useContext(AuthContext);
  const backTestId = localStorage.getItem("backtest-active");
  const backtestingMode = auth.backtestingMode;
  const [percentReturn, setPercentReturn] = useState([]);
  const [moneyReturn, setMoneyReturn] = useState([]);
  const mode = localStorage.getItem("equity-mode");
  const backtestActive = localStorage.getItem("backtest-active");

  useEffect(() => {
    workOutCurvePoints(!years ? year : years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, mode, backtestActive]);

  const all = mode === "Personal & Investor Capital";
  const personal = mode === "Personal Capital";
  const investor = mode === "Investor Capital";

  const workOutCurvePoints = async (years) => {
    let trades = await calculateFields(
      auth.trades,
      backtestingMode,
      backtestingMode ? backTestId : "",
      false,
      true
    );

    if (backtestingMode) {
      if (backTestId === "Missed Trades") {
        trades = trades.filter((t) => t.missedTrade === "Yes");
      } else {
        trades = trades.filter((t) => t.testId === backTestId);
      }
    }

    if (trades.length > 0);

    trades = trades.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
    trades = trades.filter(
      (t) => moment(t.entryDate, "DD/MM/YYYY").format("Y") === years
    );

    let january = [];
    let february = [];
    let march = [];
    let april = [];
    let may = [];
    let june = [];
    let july = [];
    let august = [];
    let september = [];
    let october = [];
    let november = [];
    let december = [];

    trades.forEach((trade) => {
      const month = moment(trade.entryDate, "DD/MM/YYYY").format("M");
      if (month === "1") {
        january.push(trade);
      } else if (month === "2") {
        february.push(trade);
      } else if (month === "3") {
        march.push(trade);
      } else if (month === "4") {
        april.push(trade);
      } else if (month === "5") {
        may.push(trade);
      } else if (month === "6") {
        june.push(trade);
      } else if (month === "7") {
        july.push(trade);
      } else if (month === "8") {
        august.push(trade);
      } else if (month === "9") {
        september.push(trade);
      } else if (month === "10") {
        october.push(trade);
      } else if (month === "11") {
        november.push(trade);
      } else if (month === "12") {
        december.push(trade);
      }
    });

    // strip non used fields from the trades array

    january = january.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    february = february.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    march = march.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    april = april.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    may = may.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    june = june.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    july = july.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    august = august.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    september = september.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    october = october.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    november = november.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });
    december = december.map((t) => {
      const schema = {
        profitLossPercentagePC: t.profitLossPercentagePC,
        profitLossDollarPersonal: t.profitLossDollarPersonal,
        profitLossPercentageIC: t.profitLossPercentageIC,
        profitLossDollarInvestor: t.profitLossDollarInvestor,
      };

      return schema;
    });

    // percentage
    let monthlyResult = [];
    let totalForEachMonth = [];
    // money
    let monthlyResultDollar = [];
    let totalForEachMonthDollar = [];

    for (let index = 0; index < moment().month() + 1; index++) {
      if (index === 0) {
        let percent = january.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(percent);
        let money = january.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(money);
      } else if (index === 1) {
        let percent = february.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = february.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 2) {
        let percent = march.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = march.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 3) {
        let percent = april.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = april.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 4) {
        let percent = may.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = may.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 5) {
        let percent = june.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = june.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 6) {
        let percent = july.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = july.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      }
      else if (index === 7) {
        let percent = august.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = august.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      }
      else if (index === 8) {
        let percent = september.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = september.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      }
      else if (index === 9) {
        let percent = october.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = october.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      }
      else if (index === 10) {
        let percent = november.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = november.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      }
      else if (index === 11) {
        let percent = december.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonth.push(percent);
        monthlyResult.push(totalForEachMonth.reduce((acc, curr) => acc + curr));
        let money = december.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachMonthDollar.push(money);
        monthlyResultDollar.push(
          totalForEachMonthDollar.reduce((acc, curr) => acc + curr)
        );
      }
    }
    setPercentReturn(monthlyResult);
    setMoneyReturn(monthlyResultDollar);
  };
  const options = {
    responsive: true,
    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

    interaction: {
      mode: "index",
      intersect: false,
    },

    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "",
      },
    },

    scales: {
      x: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        display: true,
        position: "bottom",
        grid: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
      y1: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "left",

        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
    },
  };
  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const data = {
    labels,
    datasets: [
      {
        label: personal
          ? "PC Percentage"
          : investor
          ? "IC Percentage"
          : all
          ? "PIC Percentage"
          : "",
        data: percentReturn,
        borderColor: "blue",
        backgroundColor: "blue",
        yAxisID: "y1",
      },
      {
        label: personal
          ? "PC Equity"
          : investor
          ? "IC Equity"
          : all
          ? "PIC Equity"
          : "",
        data: moneyReturn,
        borderColor: "red",
        backgroundColor: "red",
        yAxisID: "y",
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default Monthly;
