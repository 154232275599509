import { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import AuthContext from "../../lib/auth-context";
import moment from "moment";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Quarterly = ({ calculateFields, theme, years, selected, year }) => {
  const auth = useContext(AuthContext);
  const backTestId = localStorage.getItem("backtest-active");
  const backtestingMode = auth.backtestingMode;
  const [percentReturn, setPercentReturn] = useState([]);
  const [moneyReturn, setMoneyReturn] = useState([]);
  const mode = localStorage.getItem("equity-mode");
  const backtestActive = localStorage.getItem("backtest-active");
  
  useEffect(() => {
    workOutCurvePoints(!years ? year : years);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected,mode,backtestActive]);

 
  const all = mode === "Personal & Investor Capital";
  const personal = mode === "Personal Capital";
  const investor = mode === "Investor Capital";

  const workOutCurvePoints = async () => {
    let trades = await calculateFields(
      auth.trades,
      backtestingMode,
      backtestingMode ? backTestId : "",
      false,
      true
    );
    if(backtestingMode) {
      if(backTestId === "Missed Trades") {
        trades = trades.filter((t) => t.missedTrade === 'Yes');
      } else {
        trades = trades.filter((t) => t.testId === backTestId);
      }
      
    }
    

    if (trades.length > 0);

    trades = trades.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
    trades = trades.filter(
      (t) => moment(t.entryDate, "DD/MM/YYYY").format("Y") === years
    );

    const q1 = [];
    const q2 = [];
    const q3 = [];
    const q4 = [];

    trades.forEach((t) => {
      const trade_q = moment(t.entryDate, "DD/MM/YYYY").quarter();
      if (trade_q === 1) {
        q1.push(t);
      } else if (trade_q === 2) {
        q2.push(t);
      } else if (trade_q === 3) {
        q3.push(t);
      } else if (trade_q === 4) {
        q4.push(t);
      }
    });

    // percentage
    let quarterlyResult = [];
    let totalForEachQuarter = [];
    // money
    let quarterlyResultDollar = [];
    let totalForEachQuarterlyDollar = [];

    for (let index = 0; index < moment().quarter(); index++) {
      if (index === 0) {
        let percent = q1.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarter.push(percent);
        quarterlyResult.push(percent);
        let money = q1.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarterlyDollar.push(money);
        quarterlyResultDollar.push(money);
      } else if (index === 1) {
        let percent = q2.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarter.push(percent);
        quarterlyResult.push(
          totalForEachQuarter.reduce((acc, curr) => acc + curr)
        );
        let money = q2.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarterlyDollar.push(money);
        quarterlyResultDollar.push(
          totalForEachQuarterlyDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 2) {
        let percent = q3.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarter.push(percent);
        quarterlyResult.push(
          totalForEachQuarter.reduce((acc, curr) => acc + curr)
        );
        let money = q3.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarterlyDollar.push(money);
        quarterlyResultDollar.push(
          totalForEachQuarterlyDollar.reduce((acc, curr) => acc + curr)
        );
      } else if (index === 3) {
        let percent = q4.map((trade) =>
          personal
            ? parseFloat(trade.profitLossPercentagePC)
            : investor
            ? parseFloat(trade.profitLossPercentageIC)
            : all
            ? parseFloat(trade.profitLossPercentagePC) +
              parseFloat(trade.profitLossPercentageIC)
            : 0
        );
        percent =
          percent.length > 0 ? percent.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarter.push(percent);
        quarterlyResult.push(
          totalForEachQuarter.reduce((acc, curr) => acc + curr)
        );
        let money = q4.map((trade) =>
          personal
            ? parseFloat(trade.profitLossDollarPersonal)
            : investor
            ? parseFloat(trade.profitLossDollarInvestor)
            : all
            ? parseFloat(trade.profitLossDollarPersonal) +
              parseFloat(trade.profitLossDollarInvestor)
            : 0
        );
        money = money.length > 0 ? money.reduce((acc, curr) => acc + curr) : 0;
        totalForEachQuarterlyDollar.push(money);
        quarterlyResultDollar.push(
          totalForEachQuarterlyDollar.reduce((acc, curr) => acc + curr)
        );
      }
    }
    setPercentReturn(quarterlyResult);
    setMoneyReturn(quarterlyResultDollar);
  };
  const options = {
    responsive: true,
    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",

    interaction: {
      mode: "index",
      intersect: false,
    },

    stacked: false,
    plugins: {
      title: {
        display: false,
        text: "",
      },
    },

    scales: {
      x: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        display: true,
        position: "bottom",
        grid: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
      y1: {
        ticks: {
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
        type: "linear",
        display: true,
        position: "left",

        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        },
      },
    },
  };
  const labels = ["Q1", "Q2", "Q3", "Q4"];

  const data = {
    labels,
    datasets: [
      {
        label: personal
        ? "PC Percentage"
        : investor
        ? "IC Percentage"
        : all
        ? "PIC Percentage"
        : "",
        data: percentReturn,
        borderColor: "blue",
        backgroundColor: "blue",
        yAxisID: "y1",
      },
      {
        label: personal
        ? "PC Equity"
        : investor
        ? "IC Equity"
        : all
        ? "PIC Equity"
        : "",
        data: moneyReturn,
        borderColor: "red",
        backgroundColor: "red",
        yAxisID: "y",
      },
    ],
  };
  return <Line options={options} data={data} />;
};

export default Quarterly;
