/* eslint-disable no-restricted-globals */

const workercode = () => {
  let timerInterval;
  let time;
  self.onmessage = function ({ data: { turn } }) {
    if (turn === "off" || timerInterval) {
      clearInterval(timerInterval);
      time = new Date();
    }
    if (turn === "on") {
      timerInterval = setInterval(() => {
        if (new Date().getSeconds() === 0) {
          time = new Date();
          self.postMessage({ time });
        }
      }, 1000);
    }
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;
