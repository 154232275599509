import { React, useContext } from "react";
import TextField from "@mui/material/TextField";
import ThemeContext from "../../lib/theme-context";


const CustomMultiTextField = ({ label, value, onChange, rows ,variant,disableUnderline,fontSize,onBlur}) => {


    const theme = useContext(ThemeContext);

    return (

        <TextField
      
            InputLabelProps={{
                style: {
                    color:
                        theme[0] === "dark"
                            ? "#FCFCFF"
                            : "rgba(40, 42, 46)"  ,
                },

            }}
            InputProps={{
                // disableUnderline: disableUnderline ? disableUnderline : false,
                style: {
                    color:
                        theme[0] === "dark"
                            ? "#FCFCFF"
                            : "rgba(40, 42, 46)"  ,
                            fontSize: fontSize ? fontSize : null
                }
            }}
            sx={{

                "& fieldset": {
                    // - The <fieldset> inside the Input-root
                    borderColor:
                        theme[0] === "dark"
                            ? "#FCFCFF"
                            : "rgba(40, 42, 46)"  , // - Set the Input border
                },
                "&:hover fieldset": {
                    borderColor:
                        theme[0] === "dark"
                            ? "#FCFCFF"
                            : "rgba(40, 42, 46)"  , // - Set the Input border when parent has :hover
                },
                "&.Mui-focused fieldset": {
                    // - Set the Input border when parent is focused
                    borderColor:
                        theme[0] === "dark"
                            ? "#FCFCFF"
                            : "rgba(40, 42, 46)"  ,
                },
                input: {
                    color:
                        theme[0] === "dark"
                            ? "#FCFCFF"
                            : "rgba(40, 42, 46)"  ,
                },
            }}

            value={value}
            fullWidth
        // causing the issue
            multiline
            rows={rows}
            label={label}
            onBlur={onBlur}
            onChange={onChange}
            variant={variant ? variant : "outlined"}
        />

    )
}

export default CustomMultiTextField