import { React, useContext } from "react";

import ResultsTracker from "../../pages/results-tracker";
import Calandar from "../../pages/calandar/index";
import TradingPlan from "../../pages/tradingPlan";
import TradeAssist from "../../pages/trade-assist";
import Analyser from "../../pages/analyser";
import AuthContext from "../../lib/auth-context";


const PickScreen = (tab) => {
 

  switch (tab) {
    case 0:
      return <Calandar />;
    case 1:
      return <ResultsTracker />;
    case 2:
      return <Analyser />;
    case 3:
      if (JSON.parse(localStorage.getItem("user")).role === "admin") {
        return <TradingPlan />;
      } else {
        return null;
      }
    case 4:
      return <TradeAssist />;
    default:
      return <></>;
  }
};

const Screens = () => {
  const auth = useContext(AuthContext);
  const tab = auth.tab;

  return PickScreen(tab);
};

export default Screens;
