import { Button, FormControlLabel, TextField } from "@mui/material";

import { styled as Style } from "@mui/material/styles";


export const FormControl = Style(FormControlLabel)``;

export const UserName = Style(TextField)({
  input: {
    "&:-webkit-autofill": {
      // opacity: '0.5',
      WebkitBoxShadow: "0 0 0 100px #FCFCFF inset",
      WebkittextFillColor: "#FCFCFF",
    },
    margin: "1em auto 0 auto",
    height: "10px",
    maxWidth: "250px",
    padding: "1em",
    color: "#FCFCFF",
    borderRadius: "10px",
    border: "1px solid #FCFCFF",
    "&::placeholder": {
      color: "#FCFCFF",
    },
  },
});

export const Password = Style(TextField)({
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 100px #FCFCFF inset",
      WebkittextFillColor: "#FCFCFF",
    },
    margin: "-2em auto 0 auto",
    height: "10px",
    maxWidth: "250px",
    padding: "1em",
    color: "#FCFCFF",
    borderRadius: "10px",
    border: "1px solid #FCFCFF",
    "&::placeholder": {
      color: "#FCFCFF",
    },
  },
});

export const LogInBtn = Style(Button)({
  margin: "-5em auto 0 auto",
  color: "#FCFCFF",
  border: "1px solid #FCFCFF",
  height: "37px",
  width: "50%",
  borderRadius: "10px",
});
