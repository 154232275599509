import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";

const throwMessage = (type, message) => {
  switch (type) {
    case "info":
      NotificationManager.info(message, "", 800);
      break;
    case "success":
      NotificationManager.success(message, "", 800);
      break;
    case "warning":
      NotificationManager.warning(message, "", 1000);
      break;
    case "error":
      NotificationManager.error(message, "", 1500);
      break;
    default:
      break;
  }
};
export default throwMessage;
