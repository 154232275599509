import { React, useContext, useState, useEffect, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";

import ThemeContext from "../../lib/theme-context";
import { DataFetcher } from "../../utils/dataFetcher";
import throwMessage from "../../utils/throwMessage";

import moment from "moment";

import CustomMultiTextField from "../textfields/multiRow_standard";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import CustomSelect from "../select/index";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AuthContext from "../../lib/auth-context";

import { useLongPress } from "use-long-press";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";

import useWindowDimensions from "../../utils/width";

const imgStyle = {
  width: "100%",
  height: "430px",
  objectFit: "cover",
  borderRadius: "10px",
  cursor: "pointer",
};

const Journal = ({
  open,
  onClose,
  calculateFields,
  calculateOutcome,
  calculateOutcomeOfSlippage,
}) => {
  const { width } = useWindowDimensions();

  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const [usdPair, setUsdPair] = useState(undefined);
  const [takeAgain, setTakeAgain] = useState("");
  const [pairSelected, setPairSelected] = useState(undefined);
  const [pros, setPros] = useState("");
  const [cons, setCons] = useState("");
  const [emotions, setEmotions] = useState("");
  const [plan, setPlan] = useState("");
  const [linkedForecast, setLinkedForecast] = useState("");
  const [extraNotes, setExtraNotes] = useState("");
  const [accordion, setAccordion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [dxyHourlyBeforeNote, setDxyHourlyBeforeNote] = useState("");
  const [dxyHourlyAfterNote, setDxyHourlyAfterNote] = useState("");
  const [dxyDailyBeforeNote, setDxyDailyBeforeNote] = useState("");
  const [dxyDailyAfterNote, setDxyDailyAfterNote] = useState("");

  const [min5_beforeNote, setMin5_beforeNote] = useState("");
  const [min15_beforeNote, setMin15_beforeNote] = useState("");

  const [oneHour_beforeNote, setOneHour_beforeNote] = useState("");
  const [oneHour_afterNote, setOneHour_afterNote] = useState("");

  const [fourHour_afterNote, setFourHour_afterNote] = useState("");
  const [fourHour_beforeNote, setFourHour_beforeNote] = useState("");

  const [daily_beforeNote, setDaily_beforeNote] = useState("");
  const [daily_afterNote, setDaily_afterNote] = useState("");

  const [weekly_beforeNote, setWeekly_beforeNote] = useState("");

  const [weekly_afterNote, setWeekly_afterNote] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [isHoveringNext, setIsHoveringNext] = useState(false);
  const [isHoveringBack, setIsHoveringBack] = useState(false);

  const clearFields = () => {
    setPros("");
    setCons("");
    setEmotions("");
    setTakeAgain("");
    setPlan("");
    setExtraNotes("");
    setMin5_beforeNote("");
    setMin15_beforeNote("");
    setOneHour_beforeNote("");
    setOneHour_afterNote("");
    setFourHour_afterNote("");
    setFourHour_beforeNote("");
    setDaily_beforeNote("");
    setDaily_afterNote("");
    setWeekly_beforeNote("");
    setWeekly_afterNote("");
    setDxyHourlyBeforeNote("");
    setDxyHourlyAfterNote("");
    setDxyDailyBeforeNote("");
    setDxyDailyAfterNote("");
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const checkTypeOfpair = (pair) => {
    if (pair.includes("USD")) {
      setUsdPair(true);
    } else {
      setUsdPair(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getJournal(
      JSON.parse(localStorage.getItem("journal-portal")),
      JSON.parse(localStorage.getItem("trade-portal"))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const callback = useCallback(
    (props) => {
      if (props.target.getAttribute("id") === "setLinkedForecast") {
        setLinkedForecast("");
      } else {
        // setUrls([])
        setPairSelected({
          ...pairSelected,
          [props.target.alt]: "",
        });
      }
    },
    [pairSelected]
  );

  const bind = useLongPress(callback, { threshold: 2400 });

  const ScreenShots = (pairSelected) => {
    return (
      <>
        <Accordion
          onChange={(e) =>
            setTimeout(() => {
              setAccordion(accordion ? false : true);
            }, 200)
          }
          elevation={0}
          sx={{
            backgroundColor:
              theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
            color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          }}
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <p
              style={{
                fontSize: "14px",
                margin: "0 auto 0 auto",
                fontWeight: '300'
              }}
            >
              {!accordion ? "All Screenshots" : "Close"}
            </p>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              padding: "0",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                  5 Min Before
                </p>

                {pairSelected?.min5_before ? (
                  pairSelected?.min5_before.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.min5_before}
                      alt="min5_before"
                      height="auto"
                      onClick={() => openImageViewer(0)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          min5_before: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.min5_before}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          min5_before: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={min5_beforeNote ? 2 : 0}
                    variant="standard"
                    value={min5_beforeNote}
                    onChange={(e) => {
                      setMin5_beforeNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                  15 Min Before
                </p>

                {pairSelected?.min15_before ? (
                  pairSelected?.min15_before.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.min15_before}
                      alt="min15_before"
                      height="auto"
                      onClick={() => openImageViewer(1)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          min15_before: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.min15_before}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          min15_before: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={min5_beforeNote ? 2 : 0}
                    variant="standard"
                    value={min15_beforeNote}
                    onChange={(e) => {
                      setMin15_beforeNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                  1 Hour Before{" "}
                </p>

                {pairSelected?.hour1_before ? (
                  pairSelected?.hour1_before.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.hour1_before}
                      alt="hour1_before"
                      height="auto"
                      onClick={() => openImageViewer(2)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour1_before: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.hour1_before}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour1_before: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={oneHour_beforeNote ? 2 : 0}
                    variant="standard"
                    value={oneHour_beforeNote}
                    onChange={(e) => {
                      setOneHour_beforeNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                  1 Hour After{" "}
                </p>

                {pairSelected?.hour1_after ? (
                  pairSelected?.hour1_after.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.hour1_after}
                      alt="hour1_after"
                      height="auto"
                      onClick={() => openImageViewer(3)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour1_after: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.hour1_after}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour1_after: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={oneHour_afterNote ? 2 : 0}
                    variant="standard"
                    value={oneHour_afterNote}
                    onChange={(e) => {
                      setOneHour_afterNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              {usdPair && (
                <>
                  <Grid item xs={6}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "rgb(237, 158, 14)",
                        fontWeight: '300'
                      }}
                    >
                      DXY 1 Hour Before
                    </p>

                    {pairSelected?.dxyHourlyBefore ? (
                      pairSelected?.dxyHourlyBefore.startsWith(
                        "https://www.tradingview.com"
                      ) ? (
                        <img
                          {...bind()}
                          style={imgStyle}
                          src={pairSelected?.dxyHourlyBefore}
                          alt="dxyHourlyBefore"
                          height="auto"
                          onClick={() => openImageViewer(10)}
                        />
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#f23645",
                            cursor: "pointer",
                            fontWeight: '300'
                          }}
                          onClick={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyHourlyBefore: "",
                            });
                          }}
                        >
                          Invalid Link
                        </p>
                      )
                    ) : (
                      <div
                        style={{
                          minHeight: "60px",
                        }}
                      >
                        <CustomMultiTextField
                          labelColor="#f23645"
                          size="small"
                          rows={1}
                          variant="standard"
                          value={pairSelected?.dxyHourlyBefore}
                          label="No Screenshot Detected"
                          onChange={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyHourlyBefore: e.target.value,
                            });
                          }}
                          fontSize="16px"
                          disableUnderline={true}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        minHeight: "69px",
                      }}
                    >
                      <CustomMultiTextField
                        labelColor="white"
                        size="small"
                        rows={dxyHourlyBeforeNote ? 2 : 0}
                        variant="standard"
                        value={dxyHourlyBeforeNote}
                        onChange={(e) => {
                          setDxyHourlyBeforeNote(e.target.value);
                        }}
                        fontSize="15px"
                        disableUnderline={true}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "rgb(237, 158, 14)",
                        fontWeight: '300'
                      }}
                    >
                      DXY 1 Hour After
                    </p>

                    {pairSelected?.dxyHourlyAfter ? (
                      pairSelected?.dxyHourlyAfter.startsWith(
                        "https://www.tradingview.com"
                      ) ? (
                        <img
                          {...bind()}
                          style={imgStyle}
                          src={pairSelected?.dxyHourlyAfter}
                          alt="dxyHourlyAfter"
                          height="auto"
                          onClick={() => openImageViewer(11)}
                        />
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#f23645",
                            cursor: "pointer",
                            fontWeight: '300'
                          }}
                          onClick={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyHourlyAfter: "",
                            });
                          }}
                        >
                          Invalid Link
                        </p>
                      )
                    ) : (
                      <div
                        style={{
                          minHeight: "60px",
                        }}
                      >
                        <CustomMultiTextField
                          labelColor="#f23645"
                          size="small"
                          rows={1}
                          variant="standard"
                          value={pairSelected?.dxyHourlyAfter}
                          label="No Screenshot Detected"
                          onChange={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyHourlyAfter: e.target.value,
                            });
                          }}
                          fontSize="16px"
                          disableUnderline={true}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        minHeight: "69px",
                      }}
                    >
                      <CustomMultiTextField
                        labelColor="white"
                        size="small"
                        rows={dxyHourlyAfterNote ? 2 : 0}
                        variant="standard"
                        value={dxyHourlyAfterNote}
                        onChange={(e) => {
                          setDxyHourlyAfterNote(e.target.value);
                        }}
                        fontSize="15px"
                        disableUnderline={true}
                      />
                    </div>
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px" ,fontWeight: '300'}}>
                  4 Hour Before{" "}
                </p>

                {pairSelected?.hour4_before ? (
                  pairSelected?.hour4_before.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.hour4_before}
                      alt="hour4_before"
                      height="auto"
                      onClick={() => openImageViewer(4)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour4_before: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.hour4_before}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour4_before: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={fourHour_afterNote ? 2 : 0}
                    variant="standard"
                    value={fourHour_afterNote}
                    onChange={(e) => {
                      setFourHour_afterNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                  4 Hour After{" "}
                </p>

                {pairSelected?.hour4_after ? (
                  pairSelected?.hour4_after.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.hour4_after}
                      alt="hour4_after"
                      height="auto"
                      onClick={() => openImageViewer(5)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour4_after: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.hour4_after}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          hour4_after: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={fourHour_beforeNote ? 2 : 0}
                    variant="standard"
                    value={fourHour_beforeNote}
                    onChange={(e) => {
                      setFourHour_beforeNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px", fontWeight: '300' }}>
                  Daily Before{" "}
                </p>

                {pairSelected?.daily_before ? (
                  pairSelected?.daily_before.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.daily_before}
                      alt="daily_before"
                      height="auto"
                      onClick={() => openImageViewer(6)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          daily_before: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.daily_before}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          daily_before: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={daily_beforeNote ? 2 : 0}
                    variant="standard"
                    value={daily_beforeNote}
                    onChange={(e) => {
                      setDaily_beforeNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px", fontWeight: '300' }}>
                  Daily After{" "}
                </p>

                {pairSelected?.daily_after ? (
                  pairSelected?.daily_after.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.daily_after}
                      alt="daily_after"
                      height="auto"
                      onClick={() => openImageViewer(7)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          daily_after: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.daily_after}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          daily_after: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={daily_afterNote ? 2 : 0}
                    variant="standard"
                    value={daily_afterNote}
                    onChange={(e) => {
                      setDaily_afterNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>
              {usdPair && (
                <>
                  <Grid item xs={6}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "rgb(237, 158, 14)",
                        fontWeight: '300'
                      }}
                    >
                      DXY Daily Before
                    </p>

                    {pairSelected?.dxyDailyBefore ? (
                      pairSelected?.dxyDailyBefore.startsWith(
                        "https://www.tradingview.com"
                      ) ? (
                        <img
                          {...bind()}
                          style={imgStyle}
                          src={pairSelected?.dxyDailyBefore}
                          alt="dxyDailyBefore"
                          height="auto"
                          onClick={() => openImageViewer(12)}
                        />
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#f23645",
                            cursor: "pointer",
                            fontWeight: '300'
                          }}
                          onClick={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyDailyBefore: "",
                            });
                          }}
                        >
                          Invalid Link
                        </p>
                      )
                    ) : (
                      <div
                        style={{
                          minHeight: "60px",
                        }}
                      >
                        <CustomMultiTextField
                          labelColor="#f23645"
                          size="small"
                          rows={1}
                          variant="standard"
                          value={pairSelected?.dxyDailyBefore}
                          label="No Screenshot Detected"
                          onChange={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyDailyBefore: e.target.value,
                            });
                          }}
                          fontSize="16px"
                          disableUnderline={true}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        minHeight: "69px",
                      }}
                    >
                      <CustomMultiTextField
                        labelColor="white"
                        size="small"
                        rows={dxyDailyBeforeNote ? 2 : 0}
                        variant="standard"
                        value={dxyDailyBeforeNote}
                        onChange={(e) => {
                          setDxyDailyBeforeNote(e.target.value);
                        }}
                        fontSize="15px"
                        disableUnderline={true}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "16px",
                        color: "rgb(237, 158, 14)",
                        fontWeight: '300'
                      }}
                    >
                      DXY Daily After
                    </p>

                    {pairSelected?.dxyDailyAfter ? (
                      pairSelected?.dxyDailyAfter.startsWith(
                        "https://www.tradingview.com"
                      ) ? (
                        <img
                          {...bind()}
                          style={imgStyle}
                          src={pairSelected?.dxyDailyAfter}
                          alt="dxyDailyAfter"
                          height="auto"
                          onClick={() => openImageViewer(13)}
                        />
                      ) : (
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#f23645",
                            cursor: "pointer",
                            fontWeight: '300'
                          }}
                          onClick={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyDailyAfter: "",
                            });
                          }}
                        >
                          Invalid Link
                        </p>
                      )
                    ) : (
                      <div
                        style={{
                          minHeight: "60px",
                        }}
                      >
                        <CustomMultiTextField
                          labelColor="#f23645"
                          size="small"
                          rows={1}
                          variant="standard"
                          value={pairSelected?.dxyDailyAfter}
                          label="No Screenshot Detected"
                          onChange={(e) => {
                            setPairSelected({
                              ...pairSelected,
                              dxyDailyAfter: e.target.value,
                            });
                          }}
                          fontSize="16px"
                          disableUnderline={true}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        minHeight: "69px",
                      }}
                    >
                      <CustomMultiTextField
                        labelColor="white"
                        size="small"
                        rows={dxyDailyAfterNote ? 2 : 0}
                        variant="standard"
                        value={dxyDailyAfterNote}
                        onChange={(e) => {
                          setDxyDailyAfterNote(e.target.value);
                        }}
                        fontSize="15px"
                        disableUnderline={true}
                      />
                    </div>
                  </Grid>
                </>
              )}

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                  Weekly Before{" "}
                </p>

                {pairSelected?.weekly_before ? (
                  pairSelected?.weekly_before.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.weekly_before}
                      alt="weekly_before"
                      height="auto"
                      onClick={() => openImageViewer(8)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          weekly_before: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.weekly_before}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          weekly_before: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={weekly_beforeNote ? 2 : 0}
                    variant="standard"
                    value={weekly_beforeNote}
                    onChange={(e) => {
                      setWeekly_beforeNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>

              <Grid item xs={6}>
                <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                  Weekly After{" "}
                </p>

                {pairSelected?.weekly_after ? (
                  pairSelected?.weekly_after.startsWith(
                    "https://www.tradingview.com"
                  ) ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={pairSelected?.weekly_after}
                      alt="weekly_after"
                      height="auto"
                      onClick={() => openImageViewer(9)}
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                        fontWeight: '300'
                      }}
                      onClick={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          weekly_after: "",
                        });
                      }}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={pairSelected?.weekly_after}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setPairSelected({
                          ...pairSelected,
                          weekly_after: e.target.value,
                        });
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    minHeight: "69px",
                  }}
                >
                  <CustomMultiTextField
                    labelColor="white"
                    size="small"
                    rows={weekly_afterNote ? 2 : 0}
                    variant="standard"
                    value={weekly_afterNote}
                    onChange={(e) => {
                      setWeekly_afterNote(e.target.value);
                    }}
                    fontSize="15px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </>
    );
  };
  const goBack = () => {
    onClose();
    clearFields();
    setAccordion(false);
    localStorage.removeItem("trade-portal");
    localStorage.removeItem("journal-portal");
  };
  const getJournal = (journal, trade) => {
    if (
      journal !== undefined &&
      journal !== null &&
      trade !== undefined &&
      trade !== null
    ) {
      const journal_trade__id_to_find = trade._id;

      let found = auth.journals?.find(
        (j) => j?.trade_id === journal_trade__id_to_find
      );

      if (!found) {
        found = auth.journalsBacktesting?.find(
          (j) => j?.trade_id === journal_trade__id_to_find
        );
      }

      checkTypeOfpair(trade.pair);
      setPairSelected(trade);
      setPros(found.pros);
      setCons(found.cons);
      setEmotions(found.emotions);
      setPlan(found.plan);
      setTakeAgain(found.takeAgain);
      setExtraNotes(found.extraNotes);
      setLinkedForecast(found.foreCastId);

      setDxyHourlyBeforeNote(found.dxyHourlyBeforeNote);
      setDxyHourlyAfterNote(found.dxyHourlyAfterNote);
      setDxyDailyBeforeNote(found.dxyDailyBeforeNote);
      setDxyDailyAfterNote(found.dxyDailyAfterNote);
      setMin5_beforeNote(found.min5_beforeNote);
      setMin15_beforeNote(found.min15_beforeNote);
      setOneHour_beforeNote(found.oneHour_beforeNote);
      setOneHour_afterNote(found.oneHour_afterNote);
      setFourHour_afterNote(found.fourHour_afterNote);
      setFourHour_beforeNote(found.fourHour_beforeNote);
      setDaily_beforeNote(found.daily_beforeNote);
      setDaily_afterNote(found.daily_afterNote);
      setWeekly_beforeNote(found.weekly_beforeNote);
      setWeekly_afterNote(found.weekly_afterNote);
      setIsLoading(false);
    }
  };
  const editJournal = async (editedJournal) => {
    if (!formSubmitted);
    setFormSubmitted(true);

    const links = {
      dxyHourlyBefore: pairSelected.dxyHourlyBefore,
      dxyHourlyAfter: pairSelected.dxyHourlyAfter,
      dxyDailyBefore: pairSelected.dxyDailyBefore,
      dxyDailyAfter: pairSelected.dxyDailyAfter,

      min5_before: pairSelected.min5_before,
      min15_before: pairSelected.min15_before,
      hour1_before: pairSelected.hour1_before,
      hour1_after: pairSelected.hour1_after,
      hour4_before: pairSelected.hour4_before,
      hour4_after: pairSelected.hour4_after,
      daily_before: pairSelected.daily_before,
      daily_after: pairSelected.daily_after,
      weekly_before: pairSelected.weekly_before,
      weekly_after: pairSelected.weekly_after,
    };

    let schema;
    schema = {
      backtestingMode: auth.backtestingMode,
      trade_id: editedJournal.trade_id,
      journalID: editedJournal.journalID,
      trade_payload: Object.assign(editedJournal.tradeToUpdate, {
        links: links,
      }),
      journal_payload: {
        cons: editedJournal.cons,
        pros: editedJournal.pros,
        emotions: editedJournal.emotions,
        extraNotes: editedJournal.extraNotes,
        plan: editedJournal.plan,
        takeAgain: editedJournal.takeAgain,
        foreCastId: linkedForecast,
        dxyHourlyBeforeNote: editedJournal.dxyHourlyBeforeNote,
        dxyHourlyAfterNote: editedJournal.dxyHourlyAfterNote,
        dxyDailyBeforeNote: editedJournal.dxyDailyBeforeNote,
        dxyDailyAfterNote: editedJournal.dxyDailyAfterNote,
        min5_beforeNote: editedJournal.min5_beforeNote,
        min15_beforeNote: editedJournal.min15_beforeNote,
        oneHour_beforeNote: editedJournal.oneHour_beforeNote,
        oneHour_afterNote: editedJournal.oneHour_afterNote,
        fourHour_afterNote: editedJournal.fourHour_afterNote,
        fourHour_beforeNote: editedJournal.fourHour_beforeNote,
        daily_beforeNote: editedJournal.daily_beforeNote,
        daily_afterNote: editedJournal.daily_afterNote,
        weekly_beforeNote: editedJournal.weekly_beforeNote,
        weekly_afterNote: editedJournal.weekly_afterNote,
      },
    };

    const result = await DataFetcher("edit-journal", schema);

    if (result) {
      const updated_trade = result.trade;
      const updated_journal = result.journal;

      const filteredArrayTrades = auth.trades.filter(
        (x) => x._id !== updated_trade._id
      );
      filteredArrayTrades.push(updated_trade);
      auth.setTrades(filteredArrayTrades);

      if (auth.backtestingMode) {
        if (calculateFields !== false) {
          calculateFields(
            filteredArrayTrades,
            true,
            localStorage.getItem("backtest-active")
          );
        }
      } else {
        if (calculateFields !== false) {
          calculateFields(filteredArrayTrades, false);
        }
      }

      let filteredArray;
      if (auth.backtestingMode) {
        filteredArray = auth.journalsBacktesting.filter(
          (x) => x._id !== updated_journal._id
        );
        filteredArray.push(updated_journal);
        auth.setJournalsBacktesting(filteredArray);
        // goBack();
        throwMessage("success", "Success");
        setFormSubmitted(false);
      } else {
        filteredArray = auth.journals.filter(
          (x) => x._id !== updated_journal._id
        );
        filteredArray.push(updated_journal);
        auth.setJournals(filteredArray);
        // goBack();
        throwMessage("success", "Success");
        setFormSubmitted(false);
      }
    } else {
      throwMessage("error", "Something went wrong");
      setFormSubmitted(false);
    }
  };

  const noForecastToLinkItem = [
    {
      value: "Empty",
      label: "No Forecast Linked",
    },
  ];

  let forecastsMenuItem = auth.forecasts

    .map((item) => {
      return {
        value: item._id,
        label: `${moment(item.date).format("MMMM Do YYYY")} /  ${item.pair} - ${
          item.direction
        }`,
      };
    })
    .reverse()
    .slice(0, 50);

  let forecastsBacktestingMenuItem =
    localStorage.getItem("backtest-active") === "Missed Trades"
      ? auth.forecasts

          .map((item) => {
            return {
              value: item._id,
              label: `${moment(item.date).format("MMMM Do YYYY")} /  ${
                item.pair
              } - ${item.direction}`,
            };
          })
          .reverse()
          .slice(0, 50)
      : auth.forecastsBacktesting

          .map((item) => {
            return {
              value: item._id,
              label: `${moment(item.date).format("MMMM Do YYYY")} /  ${
                item.pair
              } - ${item.direction}`,
            };
          })
          .reverse()
          .slice(0, 50);

  forecastsBacktestingMenuItem = JSON.stringify([
    ...noForecastToLinkItem,
    ...forecastsBacktestingMenuItem,
  ]);

  forecastsMenuItem = JSON.stringify([
    ...noForecastToLinkItem,
    ...forecastsMenuItem,
  ]);

  // console.log('1', forecastsBacktestingMenuItem);
  // console.log('2', forecastsMenuItem);

  const urls = [
    pairSelected?.min5_before,
    pairSelected?.min15_before,
    pairSelected?.hour1_before,
    pairSelected?.hour1_after,
    pairSelected?.hour4_before,
    pairSelected?.hour4_after,
    pairSelected?.daily_before,
    pairSelected?.daily_after,
    pairSelected?.weekly_before,
    pairSelected?.weekly_after,
    pairSelected?.dxyHourlyBefore,
    pairSelected?.dxyHourlyAfter,
    pairSelected?.dxyDailyBefore,
    pairSelected?.dxyDailyAfter,
  ];

  const getTitle = (index) => {
    switch (index) {
      case 0:
        return "5 Min Before";
      case 1:
        return "15 Min Before";
      case 2:
        return "1 Hour Before";
      case 3:
        return "1 Hour After";
      case 4:
        return "4 Hour Before";
      case 5:
        return "4 Hour After";
      case 6:
        return "Daily Before";
      case 7:
        return "Daily After";
      case 8:
        return "Weekly Before";
      case 9:
        return "Weekly After";
      case 10:
        return "DXY 1 Hour Before";
      case 11:
        return "DXY 1 Hour After";
      case 12:
        return "DXY Daily Before";
      case 13:
        return "DXY Daily After";
      default:
        break;
    }
  };

  const getUrl = (index) => {
    switch (index) {
      case 0:
        return urls[0];
      case 1:
        return urls[1];
      case 2:
        return urls[2];
      case 3:
        return urls[3];
      case 4:
        return urls[4];
      case 5:
        return urls[5];
      case 6:
        return urls[6];
      case 7:
        return urls[7];
      case 8:
        return urls[8];
      case 9:
        return urls[9];
      case 10:
        return urls[10];
      case 11:
        return urls[11];
      case 12:
        return urls[12];
      case 13:
        return urls[13];
      default:
        break;
    }
  };
  useEffect(() => {
    document.onkeydown = checkKey;
    function checkKey(e) {
      if (e.keyCode === 39) {
        closeImageViewer(0);
      }
      if (e.keyCode === 37) {
        closeImageViewer(0);
      }
      if (e.keyCode === 76) {
        closeImageViewer(0);
      }
      if (e.keyCode === 72) {
        closeImageViewer(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeJournal = (direction, journals) => {
    let next;
    let back;
    let array;

    const mode = localStorage.getItem("equity-mode");
    const testId = localStorage.getItem("backtest-active");
    let trades = auth.trades;
    let journalIds;

    if (auth.backtestingMode) {
      trades = trades.filter((tests) => tests?.testId?.includes(testId));
    } else {
      trades = trades.filter((tests) => tests?.id?.includes("live-"));
    }

    if (mode === "Personal Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType !== "Investor Capital"
      );
    } else if (mode === "Investor Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType !== "Personal Capital"
      );
    }

    journalIds = trades.map((t) => t.journalID);

    if (auth.backtestingMode) {
      array = auth.journalsBacktesting.filter((j) =>
        journalIds.includes(j.journalID)
      );
    } else {
      array = auth.journals.filter((j) => journalIds.includes(j.journalID));
    }

    if (array.length > 1) {
      array = array.sort((a, b) => {
        return moment(a?.date).diff(b?.date);
      });

      const foundIndex = array.findIndex(
        (x) => x.journalID === pairSelected.journalID
      );

      next = foundIndex + 1;
      back = foundIndex - 1;

      if (foundIndex === 0) {
        back = array.length - 1;
      }
      if (foundIndex + 1 === array.length) {
        next = 0;
      }

      if (direction === "Next") {
        const trade = auth.trades.find(
          (t) => t.journalID === array[next]?.journalID
        );
        checkTypeOfpair(trade.pair);

        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          trade.closePricePC,
          trade.openPricePC,
          trade.direction,
          trade.pair
        );
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          trade.closePriceIC,
          trade.openPriceIC,
          trade.direction,
          trade.pair
        );

        Object.assign(trade, {
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: outcomeOfSlippageIC,
        });

        setPairSelected(trade);

        localStorage.setItem(
          "journal-portal",
          JSON.stringify({
            journalID: array[next]?.journalID,
            trade_id: trade._id,
          })
        );

        localStorage.setItem("trade-portal", JSON.stringify(trade));

        setPros(array[next]?.pros);
        setCons(array[next]?.cons ? array[next]?.cons : "");
        setEmotions(array[next]?.emotions ? array[next]?.emotions : "");
        setPlan(array[next]?.plan ? array[next]?.plan : "");
        setTakeAgain(array[next]?.takeAgain ? array[next]?.takeAgain : "");
        setExtraNotes(array[next]?.extraNotes ? array[next]?.extraNotes : "");
        setLinkedForecast(
          array[next]?.foreCastId ? array[next]?.foreCastId : ""
        );
        setMin5_beforeNote(
          array[next]?.min5_beforeNote ? array[next]?.min5_beforeNote : ""
        );
        setMin15_beforeNote(
          array[next]?.min15_beforeNote ? array[next]?.min15_beforeNote : ""
        );
        setOneHour_beforeNote(
          array[next]?.oneHour_beforeNote ? array[next]?.oneHour_beforeNote : ""
        );
        setOneHour_afterNote(
          array[next]?.oneHour_afterNote ? array[next]?.oneHour_afterNote : ""
        );
        setFourHour_afterNote(
          array[next]?.fourHour_afterNote ? array[next]?.fourHour_afterNote : ""
        );
        setFourHour_beforeNote(
          array[next]?.fourHour_beforeNote
            ? array[next]?.fourHour_beforeNote
            : ""
        );
        setDaily_beforeNote(
          array[next]?.daily_beforeNote ? array[next]?.daily_beforeNote : ""
        );
        setDaily_afterNote(
          array[next]?.daily_afterNote ? array[next]?.daily_afterNote : ""
        );
        setWeekly_beforeNote(
          array[next]?.weekly_beforeNote ? array[next]?.weekly_beforeNote : ""
        );
        setWeekly_afterNote(
          array[next]?.weekly_afterNote ? array[next]?.weekly_afterNote : ""
        );

        setDxyHourlyBeforeNote(
          array[next]?.dxyHourlyBeforeNote
            ? array[next]?.dxyHourlyBeforeNote
            : ""
        );
        setDxyHourlyAfterNote(
          array[next]?.dxyHourlyAfterNote ? array[next]?.dxyHourlyAfterNote : ""
        );
        setDxyDailyBeforeNote(
          array[next]?.dxyDailyBeforeNote ? array[next]?.dxyDailyBeforeNote : ""
        );
        setDxyDailyAfterNote(
          array[next]?.dxyDailyAfterNote ? array[next]?.dxyDailyAfterNote : ""
        );
      } else if (direction === "Back") {
        const trade = auth.trades.find(
          (t) => t.journalID === array[back]?.journalID
        );

        checkTypeOfpair(trade.pair);

        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          trade.closePricePC,
          trade.openPricePC,
          trade.direction,
          trade.pair
        );
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          trade.closePriceIC,
          trade.openPriceIC,
          trade.direction,
          trade.pair
        );

        Object.assign(trade, {
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: outcomeOfSlippageIC,
        });

        setPairSelected(trade);

        localStorage.setItem(
          "journal-portal",
          JSON.stringify({
            journalID: array[back]?.journalID,
            trade_id: trade._id,
          })
        );

        localStorage.setItem("trade-portal", JSON.stringify(trade));

        setPros(array[back]?.pros);
        setCons(array[back]?.cons ? array[back]?.cons : "");
        setEmotions(array[back]?.emotions ? array[back]?.emotions : "");
        setPlan(array[back]?.plan ? array[back]?.plan : "");
        setTakeAgain(array[back]?.takeAgain ? array[back]?.takeAgain : "");
        setExtraNotes(array[back]?.extraNotes ? array[back]?.extraNotes : "");
        setLinkedForecast(
          array[back]?.foreCastId ? array[back]?.foreCastId : ""
        );
        setMin5_beforeNote(
          array[back]?.min5_beforeNote ? array[back]?.min5_beforeNote : ""
        );
        setMin15_beforeNote(
          array[back]?.min15_beforeNote ? array[back]?.min15_beforeNote : ""
        );
        setOneHour_beforeNote(
          array[back]?.oneHour_beforeNote ? array[back]?.oneHour_beforeNote : ""
        );
        setOneHour_afterNote(
          array[back]?.oneHour_afterNote ? array[back]?.oneHour_afterNote : ""
        );
        setFourHour_afterNote(
          array[back]?.fourHour_afterNote ? array[back]?.fourHour_afterNote : ""
        );
        setFourHour_beforeNote(
          array[back]?.fourHour_beforeNote
            ? array[back]?.fourHour_beforeNote
            : ""
        );
        setDaily_beforeNote(
          array[back]?.daily_beforeNote ? array[back]?.daily_beforeNote : ""
        );
        setDaily_afterNote(
          array[back]?.daily_afterNote ? array[back]?.daily_afterNote : ""
        );
        setWeekly_beforeNote(
          array[back]?.weekly_beforeNote ? array[back]?.weekly_beforeNote : ""
        );
        setWeekly_afterNote(
          array[back]?.weekly_afterNote ? array[back]?.weekly_afterNote : ""
        );

        setDxyHourlyBeforeNote(
          array[back]?.dxyHourlyBeforeNote
            ? array[back]?.dxyHourlyBeforeNote
            : ""
        );
        setDxyHourlyAfterNote(
          array[back]?.dxyHourlyAfterNote ? array[back]?.dxyHourlyAfterNote : ""
        );
        setDxyDailyBeforeNote(
          array[back]?.dxyDailyBeforeNote ? array[back]?.dxyDailyBeforeNote : ""
        );
        setDxyDailyAfterNote(
          array[back]?.dxyDailyAfterNote ? array[back]?.dxyDailyAfterNote : ""
        );
      }
    }
  };
  const chooseType = (pairSelected, type) => {
    if (type === "Personal Capital") {
      return pairSelected.outcomePC === "Loss"
        ? "#f23645"
        : pairSelected.outcomePC === "Win"
        ? "#26a65d"
        : pairSelected.outcomePC === "Breakeven"
        ? "#ED9E0E"
        : "";
    } else if (type === "Investor Capital") {
      return pairSelected.outcomeIC === "Loss"
        ? "#f23645"
        : pairSelected.outcomeIC === "Win"
        ? "#26a65d"
        : pairSelected.outcomeIC === "Breakeven"
        ? "#ED9E0E"
        : "";
    } else {
      return pairSelected.outcomePC === "Loss"
        ? "#f23645"
        : pairSelected.outcomePC === "Win"
        ? "#26a65d"
        : pairSelected.outcomePC === "Breakeven"
        ? "#ED9E0E"
        : "";
    }
  };
  const chooseType2 = (pairSelected) => {
    const PC =
      pairSelected.outcomePC === "Loss"
        ? "#f23645"
        : pairSelected.outcomePC === "Win"
        ? "#26a65d"
        : pairSelected.outcomePC === "Breakeven"
        ? "#ED9E0E"
        : "";

    const IC =
      pairSelected.outcomeIC === "Loss"
        ? "#f23645"
        : pairSelected.outcomeIC === "Win"
        ? "#26a65d"
        : pairSelected.outcomeIC === "Breakeven"
        ? "#ED9E0E"
        : "";

    return [PC, IC];
  };

  return (
    !isLoading && (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            overflowY: !isViewerOpen ? "scroll" : null,
            overflowX: !isViewerOpen ? "hidden" : null,
            position: !isViewerOpen ? "absolute" : "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
            width: "100%",
            height: "100%",
            backgroundColor:
              theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
            padding: "10px 35px 0 35px",
            margin: "0 auto",
          }}
        >
          {isViewerOpen && (
            <div style={{ height: "100%", width: "100%" }}>
              <ImageViewer
                src={urls}
                currentIndex={currentImage}
                disableScroll={true}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
                leftArrowComponent={<p style={{ display: "none" }}></p>}
                rightArrowComponent={<p style={{ display: "none" }}></p>}
                closeComponent={<p style={{ display: "none" }}></p>}
              />

              <a
                target="_blank"
                href={getUrl(currentImage)}
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  textAlign: "center",
                  fontSize: "14px",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  left: "50%",
                  top: "17px",
                  zIndex: "1",
                  backgroundColor: "black",
                  padding: "0.6em 1em",
                  borderRadius: "0 0 5px 5px",
                  border: "1px solid white",
                }}
              >
                {getTitle(currentImage)}
              </a>
            </div>
          )}

          {!localStorage.getItem("reflection-to-view") && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "0.7em",
                margin: width <= 800 ? "5em 1em 0 1em" : "0em 1em 0 1em",
                color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              }}
            >
              <p
                onClick={() =>
                  changeJournal(
                    "Back",
                    !auth.backtestingMode
                      ? auth.journals
                      : auth.journalsBacktesting
                  )
                }
                onMouseEnter={() => setIsHoveringBack(true)}
                onMouseLeave={() => setIsHoveringBack(false)}
                style={{
                  cursor: "pointer",
                  position: "fixed",
                  left: "60px",
                  top: "35px",
                  color: isHoveringBack ? "#ED9E0E" : "",
                  fontWeight: '300'
                }}
              >
                Back
              </p>
              <p
                onMouseEnter={() => setIsHoveringNext(true)}
                onMouseLeave={() => setIsHoveringNext(false)}
                style={{
                  cursor: "pointer",
                  position: "fixed",
                  right: "60px",
                  top: "35px",
                  color: isHoveringNext ? "#ED9E0E" : "",
                  fontWeight: '300'
                }}
                onClick={() =>
                  changeJournal(
                    "Next",
                    !auth.backtestingMode
                      ? auth.journals
                      : auth.journalsBacktesting
                  )
                }
              >
                Next
              </p>
            </div>
          )}

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={4}
          >
            <Grid
              item
              xs={12}
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            >
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "300",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  maxWidth: "750px",
                  margin: "1em auto 50px auto",
                  padding: "0 0 15px 0",
                  fontSize: "26px",
                }}
              >
                {pairSelected ? (
                  <>
                    <span
                      style={{
                        color:
                          pairSelected.direction === "Short"
                            ? "#db0671"
                            : "#0079cf",
                      }}
                    >
                      {pairSelected.direction}{" "}
                    </span>
                    {pairSelected.pair}{" "}
                    {pairSelected.equityType === "Personal Capital" ||
                    pairSelected.equityType === "Backtest" ? (
                      <span
                        style={{
                          color: chooseType(
                            pairSelected,
                            pairSelected.equityType
                          ),
                        }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                          }}
                        >
                          PC
                        </span>{" "}
                        {pairSelected.outcomePC}
                      </span>
                    ) : pairSelected.equityType === "Investor Capital" ? (
                      <span
                        style={{
                          color: chooseType(
                            pairSelected,
                            pairSelected.equityType
                          ),
                        }}
                      >
                        <span
                          style={{
                            fontSize: "18px",
                          }}
                        >
                          IC
                        </span>{" "}
                        {pairSelected.outcomeIC}
                      </span>
                    ) : pairSelected.equityType ===
                      "Personal & Investor Capital" ? (
                      <>
                        <span
                          style={{
                            color: chooseType2(pairSelected)[0],
                          }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            PC
                          </span>{" "}
                          {pairSelected.outcomePC}
                        </span>{" "}
                        <span
                          style={{
                            color: chooseType2(pairSelected)[1],
                          }}
                        >
                          <span
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            IC
                          </span>{" "}
                          {pairSelected.outcomeIC}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  "Journal"
                )}
              </h3>
            </Grid>

            {!accordion && (
              <>
                <Grid item xs={6}>
                  <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                    Daily Before{" "}
                  </p>
                  {pairSelected?.daily_before ? (
                    <>
                      <img
                        style={{
                          width: "100%",
                          height: "430px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        src={pairSelected?.daily_before}
                        alt={pairSelected?.daily_before}
                        height="auto"
                        onClick={() => openImageViewer(6)}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: '300'
                        }}
                      >
                        {pairSelected.entryTime} - {pairSelected.entryDate}
                      </p>
                    </>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#f23645",
                        fontWeight: '300'
                      }}
                    >
                      No Screenshot Detected
                    </p>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <p style={{ textAlign: "center", fontSize: "16px",fontWeight: '300' }}>
                    1 Hour After
                  </p>

                  {pairSelected?.hour1_after ? (
                    <>
                      <img
                        style={{
                          width: "100%",
                          height: "430px",
                          objectFit: "cover",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        src={pairSelected?.hour1_after}
                        alt={pairSelected?.hour1_after}
                        height="auto"
                        onClick={() => openImageViewer(3)}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "16px",
                          fontWeight: '300'
                        }}
                      >
                        {pairSelected.exitTime} - {pairSelected.exitDate}
                      </p>
                    </>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "15px",
                        color: "#f23645",
                        fontWeight: '300'
                      }}
                    >
                      No Screenshot Detected
                    </p>
                  )}
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              {ScreenShots(pairSelected)}
            </Grid>
            <Grid item xs={5} style={{ marginTop: "1em" }}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: '300',
                  borderBottom: "0.5px solid #FCFCFF",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "40%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                What were the pros ?
              </p>
              <CustomMultiTextField
                size="small"
                rows={5}
                variant="standard"
                value={pros}
                label=""
                onChange={(e) => setPros(e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>

            <Grid item xs={5}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: '300',
                  borderBottom: "0.5px solid #FCFCFF",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "40%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                What were the cons ?
              </p>
              <CustomMultiTextField
                size="small"
                rows={4}
                value={cons}
                label=""
                onChange={(e) => setCons(e.target.value)}
                variant="standard"
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>

            <Grid item xs={5}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: '300',
                  borderBottom: "0.5px solid #FCFCFF",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "40%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                What emotions did I feel ?
              </p>
              <CustomMultiTextField
                size="small"
                rows={4}
                variant="standard"
                value={emotions}
                label=""
                onChange={(e) => setEmotions(e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>

            <Grid item xs={5}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: '300',
                  borderBottom: "0.5px solid #FCFCFF",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "40%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                Would I take this trade again ?
              </p>
              <CustomMultiTextField
                size="small"
                rows={4}
                variant="standard"
                value={takeAgain}
                label=""
                onChange={(e) => setTakeAgain(e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>

            <Grid item xs={5}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: '300',
                  borderBottom: "0.5px solid #FCFCFF",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "40%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                Did I follow my plan ?
              </p>
              <CustomMultiTextField
                size="small"
                rows={4}
                variant="standard"
                value={plan}
                label=""
                onChange={(e) => setPlan(e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>
            <Grid item xs={5}>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  fontWeight: '300',
                  borderBottom: "0.5px solid #FCFCFF",
                  paddingBottom: "10px",
                  width: width <= 500 ? "100%" : "40%",
                  margin: "0 auto 23px auto",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                Anything to add ?
              </p>
              <CustomMultiTextField
                size="small"
                rows={4}
                variant="standard"
                value={extraNotes}
                label=""
                onChange={(e) => setExtraNotes(e.target.value)}
                disableUnderline={true}
                fontSize={"14px"}
              />
            </Grid>

            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!localStorage.getItem("reflection-to-view") && (
                <div style={{ width: "350px", margin: "0 auto" }}>
                  {!linkedForecast ? (
                    <CustomSelect
                      size="small"
                      defaultValue=""
                      inputLabel="Link Forecast"
                      value={linkedForecast ? linkedForecast : ""}
                      label="Forecast"
                      onChange={(event) =>
                        setLinkedForecast(event.target.value)
                      }
                      menuItemArray={
                        auth.backtestingMode
                          ? forecastsBacktestingMenuItem
                          : forecastsMenuItem
                      }
                    />
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      <span
                        {...bind()}
                        id="setLinkedForecast"
                        style={{
                          margin: "0 auto 0 auto",
                          cursor: "pointer",
                          fontSize: "16px",
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          textAlign: "center",
                          fontWeight: '300'
                        }}
                      >
                        Linked Forecast:{" "}
                      </span>
                      <span
                        onClick={() => {
                          if (linkedForecast !== "Empty") {
                            localStorage.setItem(
                              "forecast-to-view",
                              linkedForecast
                            );
                            localStorage.removeItem("trade-portal");
                            localStorage.removeItem("journal-portal");
                            localStorage.setItem("screen", 0);
                            auth.setTab(0);
                          }
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: "16px",
                          color: "#26a65d",
                          textAlign: "center",
                        }}
                      >
                        {linkedForecast.substring(0, 10)}
                      </span>
                    </p>
                  )}
                </div>
              )}
            </Grid>

            <Grid
              item
              xs={width <= 500 ? 6 : 2}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: linkedForecast ? "0 0 1.7em 0" : "1em 0 1.7em 0",
              }}
            >
              <Tooltip title="Close">
                <CloseIcon
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    cursor: "pointer",
                    margin: "0 5px 0 0",
                  }}
                  fontSize="large"
                  onClick={() => goBack()}
                />
              </Tooltip>

              <Tooltip title="Save">
                <SaveIcon
                  sx={{
                    color: formSubmitted ? "lightGrey" : "#26a65d",
                    cursor: "pointer",
                    margin: "0 0 0 5px",
                  }}
                  fontSize="large"
                  onClick={() => {
                    const journal = JSON.parse(
                      localStorage.getItem("journal-portal")
                    );
                    const trade = JSON.parse(
                      localStorage.getItem("trade-portal")
                    );
                    editJournal({
                      trade_id: journal.trade_id,
                      journalID: journal.journalID,
                      pros: pros,
                      cons: cons,
                      emotions: emotions,
                      plan: plan,
                      takeAgain: takeAgain,
                      extraNotes: extraNotes,
                      tradeToUpdate: trade,
                      dxyHourlyBeforeNote: dxyHourlyBeforeNote,
                      dxyHourlyAfterNote: dxyHourlyAfterNote,
                      dxyDailyBeforeNote: dxyDailyBeforeNote,
                      dxyDailyAfterNote: dxyDailyAfterNote,
                      min5_beforeNote: min5_beforeNote,
                      min15_beforeNote: min15_beforeNote,
                      oneHour_beforeNote: oneHour_beforeNote,
                      oneHour_afterNote: oneHour_afterNote,
                      fourHour_afterNote: fourHour_afterNote,
                      fourHour_beforeNote: fourHour_beforeNote,
                      daily_beforeNote: daily_beforeNote,
                      daily_afterNote: daily_afterNote,
                      weekly_beforeNote: weekly_beforeNote,
                      weekly_afterNote: weekly_afterNote,
                    });
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    )
  );
};

export default Journal;
