module.exports = [

  { label: "DXY", ticker: "DXY", pipDistance: 0.001 },

  { label: "Forex", ticker: "", pipDistance: 0, multiplier: 0 },

  { label: "AUD/CAD", ticker: "AUDCAD", pipDistance: 0.0001, multiplier: 1 },
  { label: "AUD/CHF", ticker: "AUDCHF", pipDistance: 0.0001, multiplier: 1 },
  { label: "AUD/JPY", ticker: "AUDJPY", pipDistance: 0.01, multiplier: 1 },
  { label: "AUD/NZD", ticker: "AUDNZD", pipDistance: 0.0001, multiplier: 1 },
  { label: "AUD/USD", ticker: "AUDUSD", pipDistance: 0.0001, multiplier: 1 },
  { label: "CAD/JPY", ticker: "CADJPY", pipDistance: 0.01, multiplier: 1 },
  { label: "CAD/CHF", ticker: "CADCHF", pipDistance: 0.0001, multiplier: 1 },
  { label: "CHF/JPY", ticker: "CHFJPY", pipDistance: 0.01, multiplier: 1 },
  { label: "GBP/AUD", ticker: "GBPAUD", pipDistance: 0.0001, multiplier: 1 },
  { label: "GBP/CAD", ticker: "GBPCAD", pipDistance: 0.0001, multiplier: 1 },
  { label: "GBP/CHF", ticker: "GBPCHF", pipDistance: 0.0001, multiplier: 1 },
  { label: "GBP/JPY", ticker: "GBPJPY", pipDistance: 0.01, multiplier: 1 },
  { label: "GBP/NZD", ticker: "GBPNZD", pipDistance: 0.0001, multiplier: 1 },
  { label: "GBP/USD", ticker: "GBPUSD", pipDistance: 0.0001, multiplier: 1 },
  { label: "EUR/USD", ticker: "EURUSD", pipDistance: 0.0001, multiplier: 1 },
  { label: "EUR/AUD", ticker: "EURAUD", pipDistance: 0.0001, multiplier: 1 },
  { label: "EUR/CAD", ticker: "EURCAD", pipDistance: 0.0001, multiplier: 1 },
  { label: "EUR/GBP", ticker: "EURGBP", pipDistance: 0.0001, multiplier: 1 },
  { label: "EUR/JPY", ticker: "EURJPY", pipDistance: 0.01, multiplier: 1 },
  { label: "EUR/CHF", ticker: "EURCHF", pipDistance: 0.0001, multiplier: 1 },
  { label: "EUR/NZD", ticker: "EURNZD", pipDistance: 0.0001, multiplier: 1 },
  { label: "NZD/CAD", ticker: "NZDCAD", pipDistance: 0.0001, multiplier: 1 },
  { label: "NZD/CHF", ticker: "NZDCHF", pipDistance: 0.0001, multiplier: 1 },
  { label: "NZD/JPY", ticker: "NZDJPY", pipDistance: 0.01, multiplier: 1 },
  { label: "NZD/USD", ticker: "NZDUSD", pipDistance: 0.0001, multiplier: 1 },
  { label: "USD/CHF", ticker: "USDCHF", pipDistance: 0.0001, multiplier: 1 },
  { label: "USD/JPY", ticker: "USDJPY", pipDistance: 0.01, multiplier: 1 },
  { label: "USD/CAD", ticker: "USDCAD", pipDistance: 0.0001, multiplier: 1 },

  { label: "Indices", ticker: "", pipDistance: 0, multiplier: 0 },

  { label: "AUS200", ticker: "AUS200", pipDistance: 0.1, multiplier: 10 },
  { label: "CHN50", ticker: "CHN50", pipDistance: 0.1, multiplier: 10 },
  { label: "ESP35", ticker: "ESP35", pipDistance: 0.1, multiplier: 10 },
  { label: "EUSTX50", ticker: "EUSTX50", pipDistance: 0.1, multiplier: 10 },
  { label: "FRA40", ticker: "FRA40", pipDistance: 0.1, multiplier: 10 },
  { label: "GER30", ticker: "GER30", pipDistance: 0.1, multiplier: 10 },
  { label: "HKG33", ticker: "HKG33", pipDistance: 0.1, multiplier: 10 },
  { label: "JPN225", ticker: "JPN225", pipDistance: 0.1, multiplier: 10 },
  { label: "NAS100", ticker: "NAS100", pipDistance: 0.1, multiplier: 10 },
  { label: "SPX500", ticker: "SPX500", pipDistance: 0.1, multiplier: 10 },
  { label: "UK100", ticker: "UK100", pipDistance: 0.1, multiplier: 10 },
  { label: "US2000", ticker: "US2000", pipDistance: 0.1, multiplier: 10 },
  { label: "US30", ticker: "US30", pipDistance: 0.1, multiplier: 10 },

  { label: "Commodities", ticker: "", pipDistance: 0, multiplier: 0 },

  { label: "COFFEE", ticker: "COFFEENYF", pipDistance: 0.1, multiplier: 10 },
  { label: "COPPER", ticker: "COPPER", pipDistance: 0.1, multiplier: 1000 },
  { label: "CORN", ticker: "CORNF", pipDistance: 0.1, multiplier: 10 },
  { label: "GOLD", ticker: "XAUUSD", pipDistance: 0.1, multiplier: 10 },
  { label: "NGAS", ticker: "NGAS", pipDistance: 0.1, multiplier: 1000 },
  { label: "WHEAT", ticker: "WHEATF", pipDistance: 0.1, multiplier: 10 },
  { label: "SILVER", ticker: "XAGUSD", pipDistance: 0.1, multiplier: 100 },
  { label: "SOY", ticker: "SOYF", pipDistance: 0.1, multiplier: 10 },
  { label: "SUGAR", ticker: "SUGARNYF", pipDistance: 0.1, multiplier: 10 },
  { label: "UKOIL", ticker: "UKOIL", pipDistance: 0.1, multiplier: 100 },
  { label: "USOIL", ticker: "USOIL", pipDistance: 0.1, multiplier: 100 },
 
  { label: "Crypto", ticker: "", pipDistance: 0, multiplier: 0 },

  { label: "BTC/USD", ticker: "BTCUSD", pipDistance: 0.1, multiplier: 10 },
  { label: "ETH/USD", ticker: "ETHUSD", pipDistance: 0.1, multiplier: 10 },
];
