import { React, useContext, useState, useEffect } from "react";
import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import useWindowDimensions from "../../utils/width";
import Tooltip from "@mui/material/Tooltip";

const StatsBar = ({
  realisedPercentage,
  realisedPercentageBacktest,
  calculateFields,
}) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const { width } = useWindowDimensions();
  /* eslint-disable no-unused-vars */
  const [storage, setStorage] = useState("");
  const [run, setRun] = useState(false);

  const [headingText, setHeadingText] = useState();

  const setRotater = async () => {
    const storage = localStorage.getItem("rotater");
    if (storage === "Start Of Year") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "3 Months"
      );
      localStorage.setItem("rotater", "3 Months");
      // setStorage("3 Months");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "3 Months") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Monthly"
      );
      localStorage.setItem("rotater", "Monthly");
      // setStorage("Monthly");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "Monthly") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Weekly"
      );
      localStorage.setItem("rotater", "Weekly");
      // setStorage("Weekly");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "Weekly") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "24 Hours"
      );
      localStorage.setItem("rotater", "24 Hours");
      // setStorage("24 Hours");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else if (storage === "24 Hours") {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "All Years"
      );
      localStorage.setItem("rotater", "All Years");
      // setStorage("All Years");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    } else {
      calculateFields(
        auth.trades,
        auth.backtestingMode,
        localStorage.getItem("backtest-active"),
        "Start Of Year"
      );
      localStorage.setItem("rotater", "Start Of Year");
      // setStorage("Start Of Year");
      setTimeout(() => {
        // setStorage("");
        setRun(false);
      }, 500);
    }
  };

  const changeYear = () => {
    const year = localStorage.getItem("year");
    if (year === "0") {
      setHeadingText("2024");
      localStorage.setItem("year", JSON.stringify(2024));
    } else if (year === "2024") {
      setHeadingText("2025");
      localStorage.setItem("year", JSON.stringify(2025));
    } else if (year === "2025") {
      setHeadingText("0");
      localStorage.setItem("year", JSON.stringify(0));
    }
    calculateFields(auth.trades, auth.backtestingMode);
    // add 2026 here
  };

  useEffect(() => {
    setHeadingText(
      localStorage.getItem("year") !== "0"
        ? localStorage.getItem("year")
        : "All Years"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        style={{
          cursor: "pointer",
          marginRight:
            auth.backtestingMode && width > 1049
              ? "18%"
              : !auth.backtestingMode && width > 1049
              ? "20%"
              : auth.backtestingMode && width < 1049
              ? "5%"
              : !auth.backtestingMode && width < 1049
              ? "5%"
              : "0%",
          position: width <= 500 ? "absolute" : null,
          top: "70px",
          left: "122px",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "200px",
        }}
      >
        {auth.backtestingMode ? (
          <>
            <Tooltip title={localStorage.getItem("rotater")} placement="left">
              <p
                onClick={() => {
                  if (!run) {
                    setRun(true);
                    setRotater();
                  }
                }}
                style={{
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  border:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : " 1px solid black",

                  fontWeight: "300",
                  fontSize: "0.68em",
                  borderRadius: "10px",
                  padding: "8px 15px",
                  width: "82px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    color:
                      realisedPercentageBacktest < 0
                        ? "#f23645"
                        : realisedPercentageBacktest > 0
                        ? "#26a65d"
                        : realisedPercentageBacktest === 0
                        ? "#ED9E0E"
                        : "#ED9E0E",
                  }}
                >
                  {Math.abs(realisedPercentageBacktest)}% {storage}
                </span>
              </p>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title={localStorage.getItem("rotater")} placement="left">
              <p
                onClick={() => {
                  if (!run) {
                    setRun(true);
                    setRotater();
                  }
                }}
                style={{
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  border:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid black",

                  fontWeight: "300",
                  fontSize: "0.68em",
                  borderRadius: "10px",
                  padding: "6px 12px",
                  textAlign: "center",
                  width: "82px",
                }}
              >
                <span
                  style={{
                    color:
                      realisedPercentage < 0
                        ? "#f23645"
                        : realisedPercentage > 0
                        ? "#26a65d"
                        : realisedPercentage === 0
                        ? "#ED9E0E"
                        : "#ED9E0E",
                  }}
                >
                  {Math.abs(realisedPercentage)}% {storage && storage}
                </span>{" "}
              </p>
            </Tooltip>
          </>
        )}
        <Tooltip title={"Year"} placement="right">
          <p
            onClick={() => changeYear()}
            style={{
              color: theme[0] === "dark" ? "#FCFCFF" : "black",
              border:
                theme[0] === "dark" ? " 1px solid #FCFCFF" : " 1px solid black",

              fontWeight: "300",
              fontSize: "0.68em",
              borderRadius: "10px",
              padding: "6px 12px",
              width: "96px",
              textAlign: "center",
              marginLeft: "5px",
            }}
          >
            {headingText !== "0" ? headingText : "All Years"}
          </p>
        </Tooltip>
      </div>
    </>
  );
};

export default StatsBar;
