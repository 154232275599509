import moment from "moment-timezone";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import throwMessage from "../../utils/throwMessage.js";
import { mongoDBRequest } from "../../services/api/dbRequest.js";
import worker_script from "../../utils/worker-script.js";

const Logic = () => {
  const timerWorker = new Worker(worker_script);
  const effectRan = useRef(false);
  const [sydneyOpen, setSydneyOpen] = useState("");
  const [newYorkOpen, setNewYorkOpen] = useState("");
  const [tokyoOpen, setTokyoOpen] = useState("");
  const [londonOpen, setLondonOpen] = useState("");
  const [sydneyClose, setSydneyClose] = useState("");
  const [newYorkClose, setNewYorkClose] = useState("");
  const [TokyoClose, setTokyClose] = useState("");
  const [londonClose, setLondonClose] = useState("");
  const [market, setMarket] = useState();

  const [currentTime, setCurrentTime] = useState(moment());

  const [sydney, setSydney] = useState(false);
  const [newYork, setNewYork] = useState(false);
  const [tokyo, setTokyo] = useState(false);
  const [london, setLondon] = useState(false);

  const [sydneyOpenTimer, setSydneyOpenTimer] = useState(false);
  const [newYorkOpenTimer, setNewYorkOpenTimer] = useState(false);
  const [tokyoOpenTimer, setTokyoOpenTimer] = useState(false);
  const [londonOpenTimer, setLondonOpenTimer] = useState(false);

  const [marketOpenCountDown, setMarketOpenCountDown] = useState("");

  const [counter, setCounter] = useState(60);

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  let timer;

  const countDown = async ({
    nextSessionOpen,
    until,
    currentSessionClose,
    close,
  }) => {
    if (nextSessionOpen) {
      const nextSessionOpenDate = moment.utc().add(until, "hours");
      switch (nextSessionOpen) {
        case "cdSydneyOpen":
          setSydneyOpen(nextSessionOpenDate);
          break;
        case "cdNewYorkOpen":
          setNewYorkOpen(nextSessionOpenDate);
          break;
        case "cdTokyoOpen":
          setTokyoOpen(nextSessionOpenDate);
          break;
        case "cdLondonOpen":
          setLondonOpen(nextSessionOpenDate);
          break;
        default:
          break;
      }
    } else if (currentSessionClose) {
      const sessionCloseDate = moment.utc().add(close, "hours");
      switch (currentSessionClose) {
        case "cdSydneyClose":
          setSydneyClose(sessionCloseDate);
          break;
        case "cdNewYorkClose":
          setNewYorkClose(sessionCloseDate);
          break;
        case "cdTokyoClose":
          setTokyClose(sessionCloseDate);
          break;
        case "cdLondonClose":
          setLondonClose(sessionCloseDate);
          break;
        default:
          break;
      }
    }
  };
  const checkIfMarketisOpen = async (
    FX_Market_Open,
    FX_Market_Close,
    sydneyToTokyoOpenMarketOpen,
    AUSDaylightSavings,
    USADaylightSavings,
    UKDaylightSavings
  ) => {
    const workOutDaylightSavings = () => {
      if (AUSDaylightSavings && USADaylightSavings) {
        return 8;
      } else if (!AUSDaylightSavings && !USADaylightSavings) {
        return 1;
      } else if (AUSDaylightSavings && !USADaylightSavings) {
        return 1;
      } else if (!AUSDaylightSavings && USADaylightSavings) {
        return 1;
      } else {
        return 0;
      }
    };

    //------------------------------------------//
    if (FX_Market_Open) {
      setMarket(true);
      countDown({
        currentSessionClose: "cdSydneyClose",
        nextSessionOpen: "cdTokyoOpen",
        until: sydneyToTokyoOpenMarketOpen,
        close: workOutDaylightSavings(),
      });
      return true;
      //------------------------------------------//
    } else if (FX_Market_Close) {
      setMarket(false);
      return true;
      //------------------------------------------//
    } else {
      return true;
      //------------------------------------------//
    }
  };
  const cronClock = async () => {
    const current_time = moment().utc().format();
    const time = current_time.split("T")[1].split(":");
    // true +1 hour false -1 hour
    const AUSDaylightSavings = moment
      .tz(current_time, "Australia/Sydney")
      .isDST();
    const UKDaylightSavings = moment.tz(current_time, "Europe/London").isDST();
    const USADaylightSavings = moment
      .tz(current_time, "America/New_York")
      .isDST();

    //------------------------------------------//

    const sydney_open = AUSDaylightSavings ? "2000" : "2100";
    const sydney_close = AUSDaylightSavings ? "0500" : "0600";
    const tokyo_open = "2400";
    const tokyo_close = "0900";
    const london_open = UKDaylightSavings ? "0700" : "0800";
    const london_close = UKDaylightSavings ? "1600" : "1700";
    const new_york_open = USADaylightSavings ? "1200" : "1300";
    const new_york_close = USADaylightSavings ? "2100" : "2200";

    //------------------------------------------//

    const sydneyToTokyoOpenMarketOpen = (tokyo_open - new_york_close)
      .toString()
      .replace("0", "")
      .replace("0", "");

    const londonCloseToSydneyOpen = (sydney_open - london_close)
      .toString()
      .replace("0", "")
      .replace("0", "");

    const sydneyCloseToLondonOpen = (london_open - sydney_close)
      .toString()
      .replace("0", "")
      .replace("0", "");

    const TokyoCloseNewYorkOpen = (new_york_open - tokyo_close)
      .toString()
      .replace("0", "")
      .replace("0", "");

    const NewYorkCloseTokyoOpen = (tokyo_open - new_york_close)
      .toString()
      .replace("0", "")
      .replace("0", "");

    //------------------------------------------//

    const sydney_open_boolean =
      time[0] + time[1] === sydney_open && moment().utc().day() !== 5;
    const sydney_close_boolean = time[0] + time[1] === sydney_close;

    const tokyo_open_boolean = time[0] + time[1] === "0000";
    const tokyo_close_boolean = time[0] + time[1] === tokyo_close;

    const london_open_boolean = time[0] + time[1] === london_open;
    const london_close_boolean = time[0] + time[1] === london_close;

    const newYork_open_boolean = time[0] + time[1] === new_york_open;
    const newYork_close_boolean = time[0] + time[1] === new_york_close;

    //------------------------------------------//

    // const preMarketopenReminder
    const FX_Market_Open =
      moment().utc().day() === 0 && time[0] + time[1] === new_york_close;
    const FX_Market_Close =
      moment().utc().day() === 5 && time[0] + time[1] === new_york_close;

    //------------------------------------------//
    const checkIfOpen = await checkIfMarketisOpen(
      FX_Market_Open,
      FX_Market_Close,
      sydneyToTokyoOpenMarketOpen,
      AUSDaylightSavings,
      USADaylightSavings,
      UKDaylightSavings
    );

    if (checkIfOpen && market);

    //------------------------------------------//

    if (sydney_open_boolean) {
      setSydney(true);
      setSydneyOpenTimer(false);
      countDown({
        currentSessionClose: "cdSydneyClose",
        nextSessionOpen: "",
        until: 0,
        close: 9,
      });
      //------------------------------------------//
    }
    if (sydney_close_boolean) {
      setSydney(false);
      setLondonOpenTimer(true);
      countDown({
        currentSessionClose: "",
        nextSessionOpen: "cdLondonOpen",
        until: sydneyCloseToLondonOpen,
        close: 0,
      });
      //------------------------------------------//
    }
    if (tokyo_open_boolean) {
      setTokyo(true);
      setTokyoOpenTimer(false);
      countDown({
        currentSessionClose: "cdTokyoClose",
        nextSessionOpen: "",
        until: 0,
        close: 9,
      });
      //------------------------------------------//
    }
    if (tokyo_close_boolean) {
      setTokyo(false);
      setNewYorkOpenTimer(true);
      countDown({
        currentSessionClose: "",
        nextSessionOpen: "cdNewYorkOpen",
        until: TokyoCloseNewYorkOpen,
        close: 0,
      });
      //------------------------------------------//
    }
    if (london_open_boolean) {
      setLondon(true);
      setLondonOpenTimer(false);
      countDown({
        currentSessionClose: "cdLondonClose",
        nextSessionOpen: "",
        until: 0,
        close: 9,
      });
      //------------------------------------------//
    }
    if (london_close_boolean) {
      if (moment().utc().day() !== 5) {
        setLondon(false);
        setSydneyOpenTimer(true);
        countDown({
          currentSessionClose: "",
          nextSessionOpen: "cdSydneyOpen",
          until: londonCloseToSydneyOpen,
          close: 0,
        });
      }
      //------------------------------------------//
    }
    if (newYork_open_boolean) {
      setNewYork(true);
      setNewYorkOpenTimer(false);
      countDown({
        currentSessionClose: "cdNewYorkClose",
        nextSessionOpen: "",
        until: 0,
        close: 9,
      });
      //------------------------------------------//
    }
    if (newYork_close_boolean && moment().utc().day() !== 5) {
      setNewYork(false);
      setTokyoOpenTimer(true);
      countDown({
        currentSessionClose: "",
        nextSessionOpen: "cdTokyoOpen",
        until: NewYorkCloseTokyoOpen,
        close: 0,
      });
      //------------------------------------------//
    }
    if (newYork_close_boolean && moment().utc().day() === 5) {
      countDown({
        currentSessionClose: "",
        nextSessionOpen: "marketOpenCountDown",
        until: 0,
        close: 48,
      });
    }
  };
  const apiCall = async () => {
    return mongoDBRequest("market-times", "GET")
      .then((data) => {
        setSydney(data.sydney);
        setNewYork(data.newYork);
        setTokyo(data.tokyo);
        setLondon(data.london);

        setSydneyOpenTimer(data.sydneyOpenTimer);
        setNewYorkOpenTimer(data.newYorkOpenTimer);
        setTokyoOpenTimer(data.tokyoOpenTimer);
        setLondonOpenTimer(data.londonOpenTimer);

        setSydneyOpen(data.cdSydneyOpen);
        setNewYorkOpen(data.cdNewYorkOpen);
        setTokyoOpen(data.cdTokyoOpen);
        setLondonOpen(data.cdLondonOpen);
        setSydneyClose(data.cdSydneyClose);
        setNewYorkClose(data.cdNewYorkClose);
        setTokyClose(data.cdTokyoClose);
        setLondonClose(data.cdLondonClose);
        setMarket(data.market);
        setMarketOpenCountDown(data.marketOpenCountDown);
      })
      .catch((err) => {
        if (!isEmpty(err)) throwMessage("error", JSON.stringify(err));
      });
  };

  const onVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      apiCall();
      setCurrentTime(moment(new Date()));
    }
  };

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (effectRan.current === false) {
      apiCall();
      timerWorker.onmessage = ({ data: { time } }) => {
        setCurrentTime(moment(time));
        if (time.getMinutes() === 0) {
          cronClock();
        }
      };
      return () => {
        effectRan.current = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    timerWorker.postMessage({ turn: "on" });
    return () => {
      timerWorker.postMessage({ turn: "off" });
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timerCounterDown = () => {
    timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return counter > 0 ? counter : null;
  };

  return {
    sydneyOpen,
    newYorkOpen,
    tokyoOpen,
    londonOpen,
    sydneyClose,
    newYorkClose,
    TokyoClose,
    londonClose,
    market,
    currentTime,
    sydney,
    newYork,
    tokyo,
    london,
    sydneyOpenTimer,
    newYorkOpenTimer,
    tokyoOpenTimer,
    londonOpenTimer,
    timerCounterDown,
    marketOpenCountDown,
  };
};

export default Logic;
