import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";
import Monthly from "./monthly";
import Weekly from "./weekly";
import Quarterly from "./quarterly";
import CustomButtom from "../buttons/";
import moment from "moment";

const EquityCurve = ({ calculateFields, trades }) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const [years, setYears] = useState([]);
  const year = moment().format("Y");
  /* eslint-disable no-unused-vars */
  const [selected, setSelected] = useState(0);
  const [selectedTimeframe, setSelectedTimeframe] = useState(0);
  const timeframes = ["Weekly", "Monthly", "Quarterly"];
  const [headingText, setHeadingText] = useState();

  useEffect(() => {
    setHeadingText(
      localStorage.getItem("year") !== "0"
        ? localStorage.getItem("year")
        : "All Years"
    );

    let all_trades = trades;
    if (auth.backtestingMode) {
      all_trades = trades.filter((tests) => tests?.id?.includes("backtest-"));
    } else {
      all_trades = trades.filter((tests) => tests?.id?.includes("live-"));
    }
    all_trades = all_trades.map((trade) => {
      return moment(trade.entryDate, "DD/MM/YYYY").format("Y");
    });
    all_trades = [...new Set(all_trades)];
    setYears(all_trades);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeYear = () => {
    const year = localStorage.getItem("year");
    if (year === "0") {
      setHeadingText("2024");
      localStorage.setItem("year", JSON.stringify(2024));
    } else if (year === "2024") {
      setHeadingText("2025");
      localStorage.setItem("year", JSON.stringify(2025));
    } else if (year === "2025") {
      setHeadingText("0");
      localStorage.setItem("year", JSON.stringify(0));
    }

    // --------------- this needs to be checked in 2025 --------------- //;
    setSelectedTimeframe(selectedTimeframe);
    // --------------- this needs to be checked in 2025 --------------- //;

    calculateFields(auth.trades, auth.backtestingMode);
    // add 2026 here

    // scroll.scrollToBottom();
  };
  const handleChangeTimeframe = (e) => {
    setSelectedTimeframe((prev) => {
      if (prev === timeframes.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  return (
    <div
      style={{
        minHeight: "100%",
        width: "100%",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
      }}
    >
      <div
        style={{
          // height: "85vh",
          width: "100%s",

          padding: "0 1em 1em 1em",
        }}
      >
        <div
          style={{
            margin: "-1.3em 2em 0 0",
            // width: "225px",
          }}
        >
          <CustomButtom
            variant="outlined"
            label={headingText !== "0" ? headingText : "All Years"}
            onClick={() => handleChangeYear()}
          />
          <CustomButtom
            variant="outlined"
            label={timeframes[selectedTimeframe]}
            onClick={() => handleChangeTimeframe()}
          />
        </div>
        <br />
        {selectedTimeframe === 0 ? (
          <Weekly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : selectedTimeframe === 1 ? (
          <Monthly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : selectedTimeframe === 2 ? (
          <Quarterly
            calculateFields={calculateFields}
            theme={theme}
            years={years[selected]}
            selected={selected}
            year={year}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EquityCurve;
