import { React, useContext, useState } from "react";

import ThemeContext from "../../lib/theme-context";
// import AuthContext from "../../utils/auth-context";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Settings = ({ open, onClose }) => {
  // const auth = useContext(AuthContext);
  const theme = useContext(ThemeContext);

  const [value, setValue] = useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goBack = () => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ maxWidth: "1920px", margin: "0 auto" }}
    >
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          width: "100%",
          height: "100%",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          padding: "10px 20px 20px 20px",
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          sx={{ minHeight: "100%" }}
        >
          <Grid
            item
            xs={12}
            sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
          >
            <h3
              style={{
                textAlign: "center",
                fontWeight: "100",
                borderBottom:
                  theme[0] === "dark" ? " 1px solid #FCFCFF" : "darkGrey",
                width: "500px",
                margin: "1em auto 0 auto",
                padding: "0 0 15px 0",
                cursor: "pointer",
                fontSize: "26px",
              }}
            >
              Settings ( Coming Soon)
            </h3>
          </Grid>

          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              backgroundColor:
                theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              height: "550px",
              margin: "2.5em auto 0 auto",
            }}
          >
            <Tabs
              variant="scrollable"
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                minWidth: "120px",
                borderRight: 1,
                borderColor:
                  theme[0] !== "dark" ? "rgba(40, 42, 46)" : "darkGrey",
              }}
              TabIndicatorProps={{ style: { background: "none" } }}
            >
              <Tab
                label="General"
                {...a11yProps(0)}
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  height: "110px",
                  textTransform: "capitalize",
                  fontSize: "0.7em",
                  "&.Mui-selected": {
                    color: "#ED9E0E",
                    outline: "none",
                  },
                }}
              />
              <Tab
                label="Entry"
                {...a11yProps(1)}
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  height: "110px",
                  textTransform: "capitalize",
                  fontSize: "0.7em",
                  "&.Mui-selected": {
                    color: "#ED9E0E",
                    outline: "none",
                  },
                }}
              />
              <Tab
                label="Checklist"
                {...a11yProps(2)}
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  height: "110px",
                  textTransform: "capitalize",
                  fontSize: "0.7em",
                  "&.Mui-selected": {
                    color: "#ED9E0E",
                    outline: "none",
                  },
                }}
              />
              <Tab
                label="Reflection"
                {...a11yProps(3)}
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  height: "110px",
                  textTransform: "capitalize",
                  fontSize: "0.7em",
                  "&.Mui-selected": {
                    color: "#ED9E0E",
                    outline: "none",
                  },
                }}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <p>General Settings</p>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <p>Customize the dropdowns for the entries menu</p>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <p>Customize your pre-trade checklist questions here</p>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <p>Customize your reflection questions here</p>
            </TabPanel>
          </Box>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 0 1em 0",
            }}
          >
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "0 0 1em 0",
              }}
            >
              <Tooltip title="Close">
                <CloseIcon
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                    cursor: "pointer",
                    margin: "0 -5px 0 0",
                  }}
                  fontSize="large"
                  onClick={() => goBack()}
                />
              </Tooltip>

              <Tooltip title="Save">
                <SaveIcon
                  sx={{
                    // color: formSubmitted ? "lightGrey" : "#26a65d",
                    cursor: "pointer",
                    margin: "0 0 0 -5px",
                  }}
                  fontSize="large"
                  onClick={() => {}}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default Settings;
