import markets from "../../lib/markets";
import useWindowDimensions from "../../utils/width";

const InstrumentAnalytics = ({ trades, runSpeficCal, theme }) => {
  const win = "#26a65d";
  const loss = "#f23645";
  const breakeven = "#ED9E0E";

  const { width } = useWindowDimensions();

  return (
    <div
      style={{
        padding: "0.7em",
        borderRadius: "10px",

        width: "100%",
        maxWidth: "770px",
      }}
    >
      <table
        className="tableHoverEffect"
        style={{
          tableLayout: "fixed",
          borderCollapse: "collapse",
          width: "100%",
          height: "200px",
          fontSize: width <= 700 ? "13px" : "18px",
          margin: "0 auto",
        }}
      >
        <caption
          style={{
            marginBottom: "10px",
            fontSize: width <= 700 ? "16px" : "18px",
            fontWeight: "400",
            borderBottom: `1px solid ${
              theme[0] === "dark" ? "#FCFCFF" : "black"
            }`,
            paddingBottom: "10px",
          }}
        >
          Instrument Analytics
        </caption>
        <thead
          style={{ fontSize: width <= 700 ? "13px" : "16px", height: "50px" }}
        >
          <tr
            style={{
              position: "sticky",
              top: "0",
              backdropFilter: "blur(10px)",
            }}
          >
            <th
              style={{
                fontWeight: "400",
                textAlign: "left",
                paddingLeft: "5px",
              }}
            >
              Instrument
            </th>
            <th style={{ fontWeight: "400", color: win }}>Win</th>
            <th style={{ fontWeight: "400", color: breakeven }}>BE</th>
            <th style={{ fontWeight: "400", color: loss }}>Loss</th>
            <th style={{ fontWeight: "400" }}># Trades</th>
            <th style={{ fontWeight: "400" }}>Return %</th>
          </tr>
        </thead>
        <tbody>
          {markets.map((t, i) => {
            if (!t.ticker || i === 0) {
              return null;
            } else {
              return (
                <tr
                  key={i}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <th
                    style={{
                      fontWeight: "400",
                      textAlign: "left",
                      paddingLeft: "5px",
                      overflowY: "scroll",
                    }}
                    scope="row"
                  >
                    {t.ticker}
                  </th>

                  <td style={{ color: win }}>
                    {
                      trades.filter(
                        (p) =>
                          p.pair === t.ticker &&
                          (p.outcomePC === "Win" || p.outcomeIC === "Win")
                      ).length
                    }
                  </td>
                  <td style={{ color: breakeven }}>
                    {
                      trades.filter(
                        (p) =>
                          p.pair === t.ticker &&
                          (p.outcomePC === "Breakeven" ||
                            p.outcomeIC === "Breakeven")
                      ).length
                    }
                  </td>
                  <td style={{ color: loss }}>
                    {
                      trades.filter(
                        (p) =>
                          p.pair === t.ticker &&
                          (p.outcomePC === "Loss" || p.outcomeIC === "Loss")
                      ).length
                    }
                  </td>
                  <td> {trades.filter((p) => p.pair === t.ticker).length}</td>

                  {runSpeficCal(trades.filter((p) => p.pair === t.ticker))}
                </tr>
              );
            }
          })}
          <tr style={{ textAlign: "center" }}>
            <th
              style={{
                fontWeight: "400",
                textAlign: "left",
                paddingLeft: "5px",
              }}
              scope="row"
            >
              Total
            </th>
            <td style={{ color: win }}>
              {
                trades.filter(
                  (p) => p.outcomePC === "Win" || p.outcomeIC === "Win"
                ).length
              }
            </td>
            <td style={{ color: breakeven }}>
              {
                trades.filter(
                  (p) =>
                    p.outcomePC === "Breakeven" || p.outcomeIC === "Breakeven"
                ).length
              }
            </td>
            <td style={{ color: loss }}>
              {
                trades.filter(
                  (p) => p.outcomePC === "Loss" || p.outcomeIC === "Loss"
                ).length
              }
            </td>
            <td>{trades.length}</td>

            {runSpeficCal(trades)}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InstrumentAnalytics;