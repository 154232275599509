import { useContext } from "react";
import { Document, Page } from "react-pdf";
import TradingPlan from "./plan.pdf";
import ThemeContext from "../../lib/theme-context";
import useWindowDimensions from "../../utils/width";
require("pdfjs-dist/build/pdf.worker.entry.js");

const Plan = () => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const Loader = () => {
    return (
      <div>
        <p></p>
      </div>
    );
  };

  const workOutWidth = () => {
    if (width < 500) {
      return 430;
    } else if ((width > 501) && (width < 999)) {
      return 790;
    } else if (width > 1000) {
      return 980;
    }
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100%",
        display: "flex",
        alignItems: "center !important",
        justifyContent: "center",
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
        margin: "auto",
      }}
    >
      <Document file={TradingPlan} loading={<Loader />}>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
          return (
            <Page
              key={i}
              width={workOutWidth()}
              pageNumber={i}
              scale={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
            />
          );
        })}
      </Document>
    </div>
  );
};

export default Plan;
