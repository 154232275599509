import { useState, useContext, useEffect } from "react";
import AuthContext from "../../lib/auth-context";
import markets from "../../lib/markets";
import moment from "moment";
import ThemeContext from "../../lib/theme-context";
import ResultsTrackerLogic from "../results-tracker/logic";

const Logic = () => {
  const [trades, setTrades] = useState([]);
  const auth = useContext(AuthContext);
  const mode = localStorage.getItem("equity-mode");
  const year = localStorage.getItem("year");
  const theme = useContext(ThemeContext);
  const { calculateFields } = ResultsTrackerLogic();

  useEffect(() => {
    let all_trades;

    if (auth.backtestingMode) {
      all_trades = auth.trades.filter((trades) =>
        trades?.id?.includes("backtest-")
      );
    } else {
      all_trades = auth.trades.filter((trades) =>
        trades?.id?.includes("live-")
      );
    }

    runCalculations(all_trades);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, year]);

  const checkStopLossMutiplyer = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { multiplier: pairObject?.multiplier };

    return schema;
  };
  const checkTypeOfPair = (p) => {
    const pairObject = markets.find((m) => m.ticker === p);

    const schema = { pipDistance: pairObject?.pipDistance };

    return schema;
  };
  const calculateOutcomeOfSlippage = (
    closePrice,
    openPrice,
    direction,
    pair
  ) => {
    const typeofPair = checkTypeOfPair(pair);

    if (isNaN(closePrice) || closePrice === "") {
      return "In Progress";
    } else {
      if (closePrice === openPrice) {
        return "Breakeven";
      }

      if (direction === "Long") {
        if (closePrice < openPrice) {
          const amountOfPipsLostBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice > openPrice) {
          const amountOfPipsWonBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      } else if (direction === "Short") {
        if (closePrice > openPrice) {
          const amountOfPipsLostBy =
            (closePrice - openPrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsLostBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Loss";
          }
        } else if (closePrice < openPrice) {
          const amountOfPipsWonBy =
            (openPrice - closePrice) / typeofPair.pipDistance;
          if (parseFloat(amountOfPipsWonBy) <= 0.5) {
            return "Breakeven";
          } else {
            return "Win";
          }
        }
      }
    }
  };
  const workOutWinLoseInPips = (
    outcome,
    direction,
    closePrice,
    openPrice,
    pair
  ) => {
    let pipsWonBy;
    let pipsLostBy;

    const typeofPair = checkTypeOfPair(pair);
    if (outcome === "Breakeven" || outcome === "In Progress") {
      pipsWonBy = 0;
      pipsLostBy = 0;
    }
    // handle longs
    if (direction === "Long" && outcome === "Win") {
      const amountOfPipsWonBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Long" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }
    // handle Shorts
    if (direction === "Short" && outcome === "Win") {
      const amountOfPipsWonBy =
        (openPrice - closePrice) / typeofPair.pipDistance;
      pipsWonBy = parseFloat(amountOfPipsWonBy);
      pipsLostBy = 0;
    } else if (direction === "Short" && outcome === "Loss") {
      const amountOfPipsLostBy =
        (closePrice - openPrice) / typeofPair.pipDistance;
      pipsLostBy = parseFloat(amountOfPipsLostBy);
      pipsWonBy = 0;
    }

    return { pipsLostBy: pipsLostBy, pipsWonBy: pipsWonBy };
  };
  const calculatePercentageReturn = (
    outcome,
    direction,
    pair,
    pipsWonBy,
    pipsLostBy,
    openPrice,
    risk,
    stopLossPips
  ) => {
    let percent = 0;
    let stop;
    let ESD;
    let DWP = pipsWonBy;
    let DLP = pipsLostBy;

    const multiplier = checkStopLossMutiplyer(pair).multiplier;
    const stopLoss = parseFloat(stopLossPips) / multiplier;
    const pipDistance = checkTypeOfPair(pair).pipDistance;

    if (outcome === "Breakeven" || outcome === "In Progress") {
      percent = 0;
    }

    if (direction === "Long" && outcome === "Win") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Long" && outcome === "Loss") {
      stop = parseFloat(openPrice) - stopLoss * pipDistance;
      ESD = (parseFloat(openPrice) - stop) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Loss") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DLP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }
    if (direction === "Short" && outcome === "Win") {
      stop = stopLoss * pipDistance + parseFloat(openPrice);
      ESD = (stop - parseFloat(openPrice)) / pipDistance;
      const r = ((DWP / ESD) * 100) / 100;
      percent = parseFloat(r.toFixed(2)) * risk;
    }

    return percent;
  };
  const runCalculations = async (_trades) => {
    let trades = _trades;

    if (localStorage.getItem("year") !== "0") {
      const currentYearSet = moment(localStorage.getItem("year")).format("YY");

      trades = trades.filter((t, index) => {
        let dateInput = t.entryDate; // DD/MM/YY format

        let dateArray = dateInput.split("/");

        let newDate = dateArray[2];

        if (Number(newDate) === Number(currentYearSet)) {
          return t;
        } else {
          return false;
        }
      });
    }

    let virtualArray = [];

    let totalOutcomesPC = [];
    let totalOutcomesIC = [];

    let returnDollarTotals_Investor_summed;
    let returnDollarTotals_Personal_summed;

    let returnPercentTotalsIC = [];
    let returnDollarTotalsInvestor = [];

    let returnPercentTotalsPC = [];
    let returnDollarTotalsPersonal = [];

    let profitLossDollarInvestor;
    let profitLossPercentageIC;
    let profitLossDollarPersonal;
    let profitLossPercentagePC;
    let allFeesIC = [];
    let allFeesPC = [];

    if (mode === "Personal Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType !== "Investor Capital"
      );
    }
    if (mode === "Investor Capital") {
      trades = trades.filter(
        (trades) => trades?.equityType !== "Personal Capital"
      );
    }
    for (let index = 0; index < trades.length; index++) {
      const trade = trades[index];
      const capital = trade.equityType;

      if (capital === "Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const outcome1 = outcomeOfSlippageIC;

        allFeesIC.push(parseFloat(trade.feeIC));

        if (outcome1 === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Breakeven" || outcome1 === "In Progress") {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        returnPercentTotalsIC.push(profitLossPercentageIC);
        returnDollarTotalsInvestor.push(profitLossDollarInvestor);
        totalOutcomesIC.push(outcomeOfSlippageIC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: 0,
          profitLossPercentagePC: 0,
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          outcomePC: "",
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: 0,
          closePricePC: 0,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          risk: trade.risk,
          direction: trade.direction,
          stopLossPipsPC: 0,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: 0,
          returnTotalsInvestor: returnDollarTotals_Investor_summed?.toFixed(2),
          created: trade.created,
          equityType: trade.equityType,
        };
        virtualArray.push(schema);
      } else if (capital === "Personal Capital" || "Backtest") {
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippagePC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );
        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );
        allFeesPC.push(parseFloat(trade.feePC));

        const outcome2 = outcomeOfSlippagePC;

        if (outcome2 === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Breakeven" || outcome2 === "In Progress") {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        returnPercentTotalsPC.push(profitLossPercentagePC);
        returnDollarTotalsPersonal.push(profitLossDollarPersonal);

        totalOutcomesPC.push(outcomeOfSlippagePC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: 0,
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: "",
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          risk: trade.risk,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed?.toFixed(2),
          returnTotalsInvestor: 0,
          created: trade.created,
          equityType: trade.equityType,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
        };
        virtualArray.push(schema);
      } else if (capital === "Personal & Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );

        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );

        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );

        allFeesIC.push(parseFloat(trade.feeIC));
        allFeesPC.push(parseFloat(trade.feePC));

        const outcome1 = outcomeOfSlippageIC;
        const outcome2 = outcomeOfSlippagePC;

        if (outcome1 === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        } else if (outcome1 === "Breakeven" || outcome1 === "In Progress") {
          profitLossPercentageIC = 0;
          profitLossDollarInvestor = parseFloat(trade.profitLossDollarInvestor);
        }

        if (outcome2 === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        } else if (outcome2 === "Breakeven" || outcome2 === "In Progress") {
          profitLossPercentagePC = 0;
          profitLossDollarPersonal = parseFloat(trade.profitLossDollarPersonal);
        }

        if (outcome2 !== "In Progress" || outcome1 !== "In Progress") {
          returnPercentTotalsIC.push(profitLossPercentageIC);
          returnDollarTotalsInvestor.push(profitLossDollarInvestor);
          returnPercentTotalsPC.push(profitLossPercentagePC);
          returnDollarTotalsPersonal.push(profitLossDollarPersonal);
        }

        totalOutcomesPC.push(outcomeOfSlippagePC);
        totalOutcomesIC.push(outcomeOfSlippageIC);

        const schema = {
          timezone: trade.timezone,
          id: trade.id,
          _id: trade._id,
          profitLossDollarInvestor: trade.profitLossDollarInvestor,
          profitLossDollarPersonal: trade.profitLossDollarPersonal,
          profitLossPercentagePC: parseFloat(profitLossPercentagePC).toFixed(2),
          profitLossPercentageIC: parseFloat(profitLossPercentageIC).toFixed(2),
          outcomePC: outcomeOfSlippagePC,
          outcomeIC: outcomeOfSlippageIC,
          openPricePC: trade.openPricePC,
          closePricePC: trade.closePricePC,
          openPriceIC: trade.openPriceIC,
          closePriceIC: trade.closePriceIC,
          pair: trade.pair,
          direction: trade.direction,
          stopLossPipsPC: trade.stopLossPipsPC,
          stopLossPipsIC: trade.stopLossPipsIC,
          entryDate: trade.entryDate,
          entryTime: trade.entryTime,
          exitDate: trade.exitDate,
          exitTime: trade.exitTime,
          feePC: trade.feePC,
          feeIC: trade.feeIC,
          pattern: trade.pattern,
          ro3: trade.ro3,
          duration: "",
          risk: trade.risk,
          foreCastId: trade.foreCastId,
          min5_before: trade.min5_before,
          min15_before: trade.min15_before,
          hour1_before: trade.hour1_before,
          hour1_after: trade.hour1_after,
          hour4_before: trade.hour4_before,
          hour4_after: trade.hour4_after,
          daily_before: trade.daily_before,
          daily_after: trade.daily_after,
          weekly_before: trade.weekly_before,
          weekly_after: trade.weekly_after,
          journalComplete: trade.journalComplete,
          journalID: trade.journalID,
          entryType: trade.entryType,
          grade: trade.grade,
          highlightedPair: trade.highlightedPair,
          firstEntryOrScaleIn: trade.firstEntryOrScaleIn,
          testId:
            trade.missedTrade === "Yes" ? "All Missed Trades" : trade.testId,
          missedTrade: trade.missedTrade,
          returnTotalsPersonal: returnDollarTotals_Personal_summed?.toFixed(2),
          returnTotalsInvestor: returnDollarTotals_Investor_summed?.toFixed(2),
          created: trade.created,
          equityType: trade.equityType,
          // newTransaction: newTransaction,
          // transanctionNotes: note,
          // transanctionReason: reason,
        };

        virtualArray.push(schema);
      }
    } // end of for loop

    // --------------------------------- ///

    setTrades(virtualArray);
  };
  const runSpeficCal = (_trade, topBorder) => {
    let profitLossPercentageIC;
    let profitLossPercentagePC;
    let totalIC = [];
    let totalPC = [];

    for (let index = 0; index < _trade.length; index++) {
      const trade = _trade[index];
      const capital = trade.equityType;

      if (capital === "Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );

        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );

        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const outcome1 = outcomeOfSlippageIC;

        if (outcome1 === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
        } else if (outcome1 === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
        } else if (outcome1 === "Breakeven" || outcome1 === "In Progress") {
          profitLossPercentageIC = 0;
        }

        totalIC.push(profitLossPercentageIC);
      } else if (capital === "Personal Capital" || "Backtest") {
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippagePC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );
        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );

        const outcome2 = outcomeOfSlippagePC;

        if (outcome2 === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
        } else if (outcome2 === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
        } else if (outcome2 === "Breakeven" || outcome2 === "In Progress") {
          profitLossPercentagePC = 0;
        }
        totalPC.push(profitLossPercentagePC);
      } else if (capital === "Personal & Investor Capital") {
        const outcomeOfSlippageIC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.direction,
          trade.pair
        );
        const outcomeOfSlippagePC = calculateOutcomeOfSlippage(
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.direction,
          trade.pair
        );

        const workOutWinLoseInPipsIC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePriceIC),
          parseFloat(trade.openPriceIC),
          trade.pair
        );
        const workOutWinLoseInPipsPC = workOutWinLoseInPips(
          outcomeOfSlippageIC,
          trade.direction,
          parseFloat(trade.closePricePC),
          parseFloat(trade.openPricePC),
          trade.pair
        );

        const returnPercentIC = calculatePercentageReturn(
          outcomeOfSlippageIC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsIC.pipsWonBy,
          workOutWinLoseInPipsIC.pipsLostBy,
          parseFloat(trade.openPriceIC),
          trade.risk,
          trade.stopLossPipsIC
        );

        const returnPercentPC = calculatePercentageReturn(
          outcomeOfSlippagePC,
          trade.direction,
          trade.pair,
          workOutWinLoseInPipsPC.pipsWonBy,
          workOutWinLoseInPipsPC.pipsLostBy,
          parseFloat(trade.openPricePC),
          trade.risk,
          trade.stopLossPipsPC
        );

        const outcome1 = outcomeOfSlippageIC;
        const outcome2 = outcomeOfSlippagePC;

        if (outcome1 === "Win") {
          profitLossPercentageIC = returnPercentIC;
          profitLossPercentageIC = parseFloat(profitLossPercentageIC);
        } else if (outcome1 === "Loss") {
          profitLossPercentageIC = returnPercentIC;
          //convert to negative number
          profitLossPercentageIC = -Math.abs(
            parseFloat(profitLossPercentageIC)
          );
        } else if (outcome1 === "Breakeven" || outcome1 === "In Progress") {
          profitLossPercentageIC = 0;
        }

        if (outcome2 === "Win") {
          profitLossPercentagePC = returnPercentPC;
          profitLossPercentagePC = parseFloat(profitLossPercentagePC);
        } else if (outcome2 === "Loss") {
          profitLossPercentagePC = returnPercentPC;
          //convert to negative number
          profitLossPercentagePC = -Math.abs(
            parseFloat(profitLossPercentagePC)
          );
        } else if (outcome2 === "Breakeven" || outcome2 === "In Progress") {
          profitLossPercentagePC = 0;
        }

        totalPC.push(profitLossPercentagePC);
        totalIC.push(profitLossPercentageIC);
      }
    }

    const sumedTotalIC =
      totalIC.length > 0
        ? totalIC.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    const sumedTotalPC =
      totalPC.length > 0
        ? totalPC.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )
        : 0;

    const both = sumedTotalIC + sumedTotalPC;

    const mode = localStorage.getItem("equity-mode");

    const percentValueToUse =
      mode === "Personal Capital"
        ? sumedTotalPC
        : mode === "Investor Capital"
        ? sumedTotalIC
        : both;

    return (
      <td
        style={{
          color:
            percentValueToUse < 0
              ? "#f23645"
              : percentValueToUse > 0
              ? "#26a65d"
              : "#ED9E0E",
          borderTop: topBorder
            ? `1px solid ${theme[0] === "dark" ? "#FCFCFF" : "black"}`
            : "none",
        }}
      >
        {Math.abs(percentValueToUse).toFixed(2)}
      </td>
    );
  };
  const changeYear = () => {
    if (year === "0") {
      localStorage.setItem("year", JSON.stringify(2024));
    } else if (year === "2024") {
      localStorage.setItem("year", JSON.stringify(2025));
    } else if (year === "2025") {
      localStorage.setItem("year", JSON.stringify(0));
    }
    runCalculations(auth.trades);
    calculateFields(auth.trades, auth.backtestingMode);
  };
  const handleMode = () => {
    if (mode === "Personal Capital") {
      localStorage.setItem("equity-mode", "Investor Capital");
      auth.setEquityTypeMode("Investor Capital");
    } else if (mode === "Investor Capital") {
      localStorage.setItem("equity-mode", "Personal & Investor Capital");
      auth.setEquityTypeMode("Personal & Investor Capital");
    } else if (mode === "Personal & Investor Capital") {
      localStorage.setItem("equity-mode", "Personal Capital");
      auth.setEquityTypeMode("Personal Capital");
    }
    runCalculations(auth.trades);
    calculateFields(
      auth.trades,
      auth.backtestingMode,
      auth.backtestingMode ? localStorage.getItem("backtest-active") : ""
    );
  };

  return {
    trades,
    runSpeficCal,
    theme,
    changeYear,
    handleMode,
    auth,
  };
};

export default Logic;
