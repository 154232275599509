import { useContext } from "react";

import Logic from "./logic";
import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";

import throwMessage from "../../utils/throwMessage";

import Deposit from "../../components/modals/deposit";
import NewEntry from "../../components/modals/new-entry";
import Backtests from "../../components/modals/backtests";
import Journal from "../../components/modals/journal";
import PosCal from "../../components/modals/cal";
import EquityCurve from "../../components/equityCurve";

import columnsComplex from "../../lib/columnsComplex";
import columnsSimple from "../../lib/columnsSimple";

import moment from "moment";

import UnArchivedChipList from "../../components/chips";
import CustomDataGrid from "../../components/dataGrid/index";
import ArchivedTestsDropDown from "../../components/select/archivedTestsDropDown";
import StatsBar from "../../components/statsbar";
import BacktestingModeSwitch from "../../components/switch";
import InProgressModeSwitch from "../../components/switch";
import ViewModeSwitch from "../../components/switch";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import ShowChartIcon from "@mui/icons-material/ShowChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import UnarchiveIcon from "@mui/icons-material/Unarchive";

import CandlestickChartOutlinedIcon from "@mui/icons-material/CandlestickChartOutlined";
import AppsIcon from "@mui/icons-material/Apps";
import CloseIcon from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import JoinRightOutlinedIcon from "@mui/icons-material/JoinRightOutlined";

import useWindowDimensions from "../../utils/width";

const ResultsTracker = () => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const mode = localStorage.getItem("equity-mode");
  const { width } = useWindowDimensions();

  const {
    calculateFields,
    allbackTestSchemas,
    setAllbackTestSchemas,
    showBacktestTrades,
    remove,
    handleRemove,
    handleOpen,
    realisedPercentage,
    handleBacktestingMode,
    selectedArchivedTests,
    setSelectedArchivedTests,
    handleOpenBacktest,
    handleArchivingTest,
    handleDeleteTest,
    setSelectedArchivedTestsBoolean,
    selectedArchivedTestsBoolean,
    handleUnArchivedTest,
    showSpecficTest,
    realisedPercentageBacktest,
    handleInProgressMode,
    handleEditCell,
    setToRemove,
    idTradeGenerator,
    runDurationCal,
    handleOpenJournal,
    handleRemoveButton,
    handleClose,
    open,
    handleCloseNewDeposit,
    openNewDeposit,
    handleCloseBacktest,
    openNewTest,
    handleCloseJournal,
    openJournal,
    handleSimpleView,
    formSubmitted,
    handleCloseCal,
    openCal,
    handleOpenCal,
    calculateOutcome,
    calculateOutcomeOfSlippage,
    equityCurve,
    setEquityCurve,
  } = Logic();

  const calHeight = () => {
    if (auth.backtestingMode && !localStorage.getItem("backtest-active")) {
      return "calc(100vh - 235px)";
    } else if (
      auth.backtestingMode &&
      localStorage.getItem("backtest-active") === "Missed Trades"
    ) {
      return "calc(100vh - 232px)";
    } else if (
      auth.backtestingMode &&
      localStorage.getItem("backtest-active") !== "Missed Trades"
    ) {
      return "calc(100vh - 301px)";
    } else if (!auth.backtestingMode) {
      return "calc(100vh - 136px)";
    }
  };
  const setColor = () => {
    const mode = localStorage.getItem("equity-mode");
    switch (mode) {
      case "Personal Capital":
        return (
          <SavingsOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Investor Capital":
        return (
          <AccountBalanceOutlinedIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
      case "Personal & Investor Capital":
        return (
          <JoinRightOutlinedIcon
            sx={{
              color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
              transform: "rotate(180deg)",
              fontSize: "35px",
            }}
          />
        );
      default:
        return (
          <MoreVertIcon
            fontSize="large"
            sx={{ color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF" }}
          />
        );
    }
  };
  const handleMode = () => {
    if (mode === "Personal Capital") {
      localStorage.setItem("equity-mode", "Investor Capital");
      auth.setEquityTypeMode("Investor Capital");
    } else if (mode === "Investor Capital") {
      localStorage.setItem("equity-mode", "Personal & Investor Capital");
      auth.setEquityTypeMode("Personal & Investor Capital");
    } else if (mode === "Personal & Investor Capital") {
      localStorage.setItem("equity-mode", "Personal Capital");
      auth.setEquityTypeMode("Personal Capital");
    }
    calculateFields(
      auth.trades,
      auth.backtestingMode,
      auth.backtestingMode ? localStorage.getItem("backtest-active") : ""
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: calHeight(),
        backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
      }}
    >
      <div
        style={{
          display: "flex",
          flexWrap: width <= 500 ? "wrap" : null,
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          margin: "0 auto",
          padding: "0 1em 0 1em",
          color: "#FCFCFF",
          marginTop:
            !auth.backtestingMode && width >= 500 && equityCurve
              ? "1em"
              : !auth.backtestingMode && width < 500
              ? "1em"
              : 0,
        }}
      >
        <div style={{ width: "428px" }}>
          {!auth.backtestingMode && (
            <Tooltip
              title={mode}
              fontSize="large"
              sx={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  margin: "0 0.7em 0 -0.5em ",
                  position: equityCurve ? "absolute" : "initial",
                  left: width >= 500 ? "268px" : "236px",
                  top: width >= 500 ? "123px" : "82.5px",
                  width: "50px",
                  display: width >= 500 ? "inline" : "block",
                }}
              >
                <IconButton
                  sx={{
                    color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  }}
                  onClick={handleMode}
                >
                  {setColor()}
                </IconButton>
              </div>
            </Tooltip>
          )}
          {!equityCurve && (
            <div
              style={{
                display: width <= 500 ? "flex" : "inline",
                flexWrap: "wrap",
                margin: width <= 500 ? "2em auto 1em auto" : "",
              }}
            >
              <BacktestingModeSwitch
                label={auth.backtestingMode ? "Backtesting" : "Live"}
                checked={auth.backtestingMode}
                onChange={(e) => handleBacktestingMode(e, width)}
                name="Backtesting Switch"
              />
              <InProgressModeSwitch
                label={auth.inProgressMode ? "In Progress" : "Completed"}
                checked={auth.inProgressMode}
                onChange={handleInProgressMode}
                name="In Progress Switch"
              />
              <ViewModeSwitch
                label={auth.viewMode === "all-view" ? "Complex" : "Simple"}
                checked={auth.viewMode === "all-view" ? true : false}
                onChange={handleSimpleView}
                name="View Mode Switch"
              />
            </div>
          )}
        </div>

        {!equityCurve && (
          <StatsBar
            realisedPercentage={realisedPercentage}
            realisedPercentageBacktest={realisedPercentageBacktest}
            calculateFields={calculateFields}
          />
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          {remove ? (
            <div
              onClick={handleRemove}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              <DeleteOutlinedIcon
                fontSize="large"
                sx={{
                  color: "#f23645",
                  marginRight: "5px",
                }}
              />
              <p style={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}>
                Delete
              </p>
            </div>
          ) : (
            <div
              style={{
                margin: width <= 500 ? "0 auto 1em auto" : null,
              }}
            >
              {!equityCurve ? (
                <Tooltip
                  title="Equity Curve"
                  fontSize="large"
                  sx={{
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    cursor: "pointer",
                  }}
                >
                  <ShowChartIcon
                    sx={{
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      cursor: "pointer",
                      marginRight: "15px",
                      marginBottom: equityCurve ? "-20px" : "0",
                      marginTop: equityCurve ? "20px" : "0",
                      zIndex: "100",
                    }}
                    fontSize="large"
                    onClick={() => {
                      localStorage.setItem(
                        "equityCurve",
                        equityCurve ? false : true
                      );
                      setEquityCurve(equityCurve ? false : true);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title="Close"
                  fontSize="large"
                  sx={{
                    color: theme[0] === "dark" ? "#FCFCFF" : "black",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon
                    sx={{
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      cursor: "pointer",
                      marginRight: "15px",
                      marginBottom: equityCurve ? "0.3em" : "0",
                      zIndex: "100",
                    }}
                    fontSize="large"
                    onClick={() => {
                      localStorage.setItem(
                        "equityCurve",
                        equityCurve ? false : true
                      );
                      setEquityCurve(equityCurve ? false : true);
                    }}
                  />
                </Tooltip>
              )}

              {!equityCurve && (
                <>
                  <Tooltip
                    title="Position Calculator"
                    fontSize="large"
                    sx={{
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      cursor: "pointer",
                    }}
                  >
                    <AppsIcon
                      sx={{
                        color: theme[0] === "dark" ? "#FCFCFF" : "black",
                        cursor: "pointer",
                        marginRight: "15px",
                      }}
                      fontSize="large"
                      onClick={handleOpenCal}
                    />
                  </Tooltip>
                  <Tooltip
                    title="Entry"
                    fontSize="large"
                    sx={{
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      cursor: "pointer",
                    }}
                  >
                    <CandlestickChartOutlinedIcon
                      sx={{
                        color: theme[0] === "dark" ? "#FCFCFF" : "black",
                        cursor: "pointer",

                        marginRight: auth.backtestingMode ? "15px" : "0",
                      }}
                      fontSize="large"
                      onClick={handleOpen}
                    />
                  </Tooltip>
                  {auth.backtestingMode && !remove && !equityCurve ? (
                    <Tooltip title="Backtest">
                      <BarChartIcon
                        sx={{
                          color: theme[0] === "dark" ? "#FCFCFF" : "black",
                          cursor: "pointer",
                        }}
                        onClick={handleOpenBacktest}
                        fontSize="large"
                      />
                    </Tooltip>
                  ) : null}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {auth.backtestingMode ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            margin: "1em auto",
          }}
        >
          {/* ------------------ Chips List of Unarchived Tests starts here ------------------ */}

          <UnArchivedChipList
            allbackTestSchemas={allbackTestSchemas}
            showSpecficTest={showSpecficTest}
            showBacktestTrades={showBacktestTrades}
            handleArchivingTest={handleArchivingTest}
            formSubmitted={formSubmitted}
          />

          {/* ------------------ Test Information Text
       starts here ------------------ */}

          {allbackTestSchemas
            ?.filter((tests) => tests.archived?.includes("false"))
            .map((t) => {
              return showSpecficTest === t.testId ? (
                <div
                  key={t.testId}
                  style={{
                    textAlign: "center",
                    width: "400px",
                    // marginLeft: "1em",
                    height: "120px",
                    overflowY: "auto",
                  }}
                >
                  <p
                    style={{
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      fontSize: "14.5px",
                    }}
                  >
                    {t.pair} {t.duration}{" "}
                    {parseInt(t.duration) === 1
                      ? "Week Of Data"
                      : "Weeks Of Data"}
                    <br />
                    {moment(t.startDate).format("Do MMM YYYY")} to{" "}
                    {moment(t.exitDate).format("Do MMM YYYY")} <br />
                  </p>
                  <p
                    style={{
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      fontSize: "13px",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {t.notes}
                  </p>
                </div>
              ) : null;
            })}

          {/* ------------------ Archived Tests Drop Down/select starts here ------------------ */}

          <ArchivedTestsDropDown
            selectedArchivedTestsBoolean={selectedArchivedTestsBoolean}
            selectedArchivedTests={selectedArchivedTests}
            setSelectedArchivedTestsBoolean={setSelectedArchivedTestsBoolean}
            setSelectedArchivedTests={setSelectedArchivedTests}
            allbackTestSchemas={allbackTestSchemas}
          />

          {/* ------------------ Unarchive / Delete Test Buttons starts here ------------------ */}

          {selectedArchivedTestsBoolean ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "25%",
              }}
            >
              {!formSubmitted && (
                <div
                  onClick={() => handleUnArchivedTest(selectedArchivedTests)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  <UnarchiveIcon
                    sx={{
                      fontSize: "33px",
                      color: "#26a65d",
                      marginRight: "5px",
                    }}
                  />
                  <p>Unarchive</p>
                </div>
              )}
              {!formSubmitted && (
                <div
                  onClick={() => handleDeleteTest(selectedArchivedTests)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  <DeleteOutlinedIcon
                    sx={{
                      fontSize: "35px",
                      color: "#f23645",
                      marginRight: "5px",
                    }}
                  />
                  <p>Delete</p>
                </div>
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      {/* ------------------ DataTable starts here ------------------ */}

      {!equityCurve ? (
        <CustomDataGrid
          handleEditCell={handleEditCell}
          showSpecficTest={showSpecficTest}
          setToRemove={setToRemove}
          inProgressMode={auth.inProgressMode}
          viewMode={auth.viewMode}
          columns={
            auth.viewMode === "all-view"
              ? columnsComplex(
                  theme,
                  throwMessage,
                  idTradeGenerator,
                  runDurationCal,
                  handleOpenJournal,
                  auth
                )
              : auth.viewMode === "simple-view"
              ? columnsSimple(
                  theme,
                  throwMessage,
                  idTradeGenerator,
                  runDurationCal,
                  handleOpenJournal,
                  auth
                )
              : []
          }
          handleRemoveButton={handleRemoveButton}
        />
      ) : (
        <EquityCurve calculateFields={calculateFields} trades={auth.trades} />
      )}

      {/* ------------------ Modal starts here ------------------ */}

      <Journal
        open={openJournal}
        onClose={handleCloseJournal}
        calculateFields={calculateFields}
        calculateOutcome={calculateOutcome}
        calculateOutcomeOfSlippage={calculateOutcomeOfSlippage}
      />
      <Backtests
        open={openNewTest}
        onClose={handleCloseBacktest}
        setAllbackTestSchemas={setAllbackTestSchemas}
        handleArchivingTest={handleArchivingTest}
      />
      <Deposit open={openNewDeposit} onClose={handleCloseNewDeposit} />
      <NewEntry
        open={open}
        onClose={handleClose}
        calculateFields={calculateFields}
      />

      <PosCal open={openCal} onClose={handleCloseCal} />
    </div>
  );
};

export default ResultsTracker;
