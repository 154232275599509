import { React, useContext } from "react";
import TextField from "@mui/material/TextField";
import ThemeContext from "../../lib/theme-context";

const CustomMultiTextField = ({
  label,
  value,
  onChange,
  rows,
  variant,
  disableUnderline,
  fontSize,
  onBlur,
  name,
  labelColor,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <TextField
      InputLabelProps={{
        style: {
          color: labelColor
            ? labelColor
            : theme[0] === "dark"
            ? "#FCFCFF"
            : "rgba(40, 42, 46)"  ,
            fontSize: '15px'
        },
      }}
      InputProps={{
        disableUnderline: disableUnderline ? disableUnderline : false,
        style: {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
          fontSize: fontSize ? fontSize : null,
        },
        inputProps: {
          style: { textAlign: "center" },
        },
      }}
      sx={{
        "& .MuiInputLabel-root": {
          right: 0,
          textAlign: "center",
        },
        "& fieldset": {
          // - The <fieldset> inside the Input-root
          bordercolor: theme[0] === "dark" ? "#FCFCFF" : "black", // - Set the Input border
        },
        "&:hover fieldset": {
          bordercolor: theme[0] === "dark" ? "#FCFCFF" : "black", // - Set the Input border when parent has :hover
        },
        "&.Mui-focused fieldset": {
          // - Set the Input border when parent is focused
          bordercolor: theme[0] === "dark" ? "#FCFCFF" : "black",
        },
        input: {
          color: theme[0] === "dark" ? "#FCFCFF" : "black",
        },
      }}
      value={value}
      fullWidth
      multiline
      rows={rows}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      variant={"standard"}
    />
  );
};

export default CustomMultiTextField;
