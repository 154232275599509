import moment from "moment";
import markets from "./markets";
import DoneIcon from "@mui/icons-material/Done";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import throwMessage from "../utils/throwMessage";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const runDurationCal = (params) => {
  let entryDate = params.row.entryDate.split(/\//);
  let exitDate = params.row.exitDate.split(/\//);

  entryDate = [entryDate[1], entryDate[0], entryDate[2]].join("/");
  exitDate = [exitDate[1], exitDate[0], exitDate[2]].join("/");

  const date = {
    entryDate: entryDate,
    entryTime: params.row.entryTime,
    exitDate: exitDate,
    exitTime: params.row.exitTime,
  };

  const startDate = moment(new Date(date.entryDate + " " + date.entryTime)).tz(
    params.row.timezone
  );

  const endDate = moment(new Date(date.exitDate + " " + date.exitTime)).tz(
    params.row.timezone
  );

  let hours = moment
    .duration(moment(endDate).diff(moment(startDate)))
    .asHours();

  let days = moment
    .duration(moment(endDate).diff(moment(startDate)))
    .asDays()
    .toFixed(1);

  if (!isNaN(hours)) {
    return (
      <p>
        {hours >= 24 ? days : hours.toFixed(1)} {hours >= 24 ? "Days" : "Hrs"}
      </p>
    );
  } else {
    return <p>In Progress</p>;
  }
};

const checkStopLossType = (p) => {
  const pairObject = markets.find((m) => m.ticker === p);
  if (pairObject?.multiplier === 10) {
    return "Ticks";
  } else if (pairObject?.multiplier === 1) {
    return "Pips";
  }
};
const columnsReflections = (
  setSingleJournalView,
  setSingleReflectionView,
  setOpenJournal,
  setSingleForecastView,
  handleCloseNewReflection,
  theme
) => {
  const saved =
    localStorage.getItem("reflection-saved") === "true" ? true : false;

  let array = [
    {
      field: "pair",
      headerName: "Instrument",
      width: 175,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <p
              style={{
                color: theme[0] === "dark" ? "#FCFCFF" : "black",
              }}
            >
              {params.row.pair}
            </p>
          );
        }
      },
    },
    {
      field: "equityType",
      headerName: "Capital",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (params.row.equityType === "Personal & Investor Capital") {
          return <p>P I C</p>;
        } else if (params.row.equityType === "Personal Capital") {
          return <p>PC</p>;
        } else if (params.row.equityType === "Investor Capital") {
          return <p>IC</p>;
        } else if (params.row.equityType === "Backtest") {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "journalID",
      headerName: "Journal",
      width: 95,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.pair) {
          return "";
        } else {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                width: "100%",
                margin: "0 15px 0 0",
              }}
            >
              <p
                style={{ cursor: "pointer", margin: "0 0 0 10px" }}
                onClick={() => {
                  if (!params.row.journalID) {
                    console.log("No Journal Found");
                  } else {
                    if (saved) {
                      setSingleJournalView(true);
                      setSingleReflectionView(false);
                      setOpenJournal(true);
                      localStorage.setItem(
                        "journal-portal",
                        JSON.stringify({
                          journalID: params.row.journalID,
                          trade_id: params.row._id,
                        })
                      );
                      localStorage.setItem(
                        "trade-portal",
                        JSON.stringify(params.row)
                      );
                      handleCloseNewReflection();
                    } else {
                      throwMessage("info", "Save Reflection");
                    }
                  }
                }}
              >
                {params.row.journalComplete === "true" ? (
                  <DoneIcon
                    style={{
                      color: "26a65d",
                    }}
                  />
                ) : (
                  <MenuBookIcon
                    style={{
                      color: "#f23645",
                    }}
                  />
                )}
              </p>
            </div>
          );
        }
      },
    },
    {
      field: "foreCastId",
      headerName: "Forecast",
      width: 93,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              margin: "0 15px 0 0",
            }}
          >
            {params.row.foreCastId === "Empty" ? (
              <HorizontalRuleIcon />
            ) : params.row.foreCastId ? (
              <p
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (saved) {
                    setSingleReflectionView(false);
                    localStorage.setItem(
                      "forecast-to-view",
                      params.row.foreCastId
                    );
                    setSingleForecastView(true);
                    handleCloseNewReflection();
                  } else {
                    throwMessage("info", "Save Reflection");
                  }
                }}
              >
                <DoneIcon
                  style={{
                    color: "26a65d",
                  }}
                />
              </p>
            ) : (
              <QueryStatsIcon
                style={{
                  color: "#f23645",
                }}
              />
            )}
          </div>
        );
      },
    },

    {
      field: "entryDate",
      headerName: "Entry Date",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "entryTime",
      headerName: "Entry Time",
      width: 160,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "direction",
      headerName: "Direction",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.direction) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.direction === "Short"
                    ? "#db0671"
                    : params.row.direction === "Long"
                    ? "#0079cf"
                    : "",
              }}
            >
              {params.row.direction}
            </p>
          );
        }
      },
    },
    {
      field: "outcomePC",
      headerName: "Outcome PC",
      width: 105,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomePC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomePC === "Loss"
                    ? "#f23645"
                    : params.row.outcomePC === "Win"
                    ? "#26a65d"
                    : params.row.outcomePC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomePC}
            </p>
          );
        }
      },
    },
    {
      field: "outcomeIC",
      headerName: "Outcome IC",
      width: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.outcomeIC) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          return (
            <p
              style={{
                color:
                  params.row.outcomeIC === "Loss"
                    ? "#f23645"
                    : params.row.outcomeIC === "Win"
                    ? "#26a65d"
                    : params.row.outcomeIC === "Breakeven"
                    ? "#ED9E0E"
                    : "",
              }}
            >
              {params.row.outcomeIC}
            </p>
          );
        }
      },
    },
    {
      field: "grade",
      headerName: "Grade",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (!params.row.grade) {
          return "";
        } else {
          return (
            <p
              style={{
                color:
                  params.row.grade === "Low Risk"
                    ? "#26a65d"
                    : params.row.grade === "High Risk"
                    ? "#f23645"
                    : "",
              }}
            >
              {params.row.grade}
            </p>
          );
        }
      },
    },

    {
      field: "profitLossPercentagePC",
      headerName: "% PC",
      width: 85,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.outcomeIC === "In Progress" ||
          params.row.outcomePC === "In Progress"
        ) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          if (
            params.row.equityType === "Personal & Investor Capital" ||
            params.row.equityType === "Personal Capital" ||
            params.row.equityType === "Backtest"
          ) {
            if (!params.row.profitLossPercentagePC) {
              return <p style={{ color: "#ED9E0E" }}>0 %</p>;
            } else {
              return (
                <p
                  style={{
                    color:
                      params.row.profitLossPercentagePC < 0
                        ? "#f23645"
                        : params.row.profitLossPercentagePC > 0
                        ? "#26a65d"
                        : "#ED9E0E",
                  }}
                >
                  {Math.abs(params.row.profitLossPercentagePC)} %
                </p>
              );
            }
          } else {
            return (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            );
          }
        }
      },
    },
    {
      field: "profitLossDollarPersonal",
      headerName: "£ PC",
      width: 85,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.outcomeIC === "In Progress" ||
          params.row.outcomePC === "In Progress"
        ) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          if (
            params.row.equityType === "Personal & Investor Capital" ||
            params.row.equityType === "Personal Capital" ||
            params.row.equityType === "Backtest"
          ) {
            if (!params.row.profitLossDollarPersonal) {
              return <p style={{ color: "#ED9E0E" }}>£ 0</p>;
            } else {
              return (
                <p
                  style={{
                    color:
                      params.row.profitLossDollarPersonal < 0
                        ? "#f23645"
                        : params.row.profitLossDollarPersonal > 0
                        ? "#26a65d"
                        : "#ED9E0E",
                  }}
                >
                  £ {Math.abs(params.row.profitLossDollarPersonal)}
                </p>
              );
            }
          } else {
            return (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            );
          }
        }
      },
    },

    {
      field: "profitLossPercentageIC",
      headerName: "% IC",
      width: 85,
      valueFormatter: ({ value }) => `${value} %`,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.outcomeIC === "In Progress" ||
          params.row.outcomePC === "In Progress"
        ) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          if (
            params.row.equityType === "Personal & Investor Capital" ||
            params.row.equityType === "Investor Capital"
          ) {
            if (!params.row.profitLossPercentageIC) {
              return <p style={{ color: "#ED9E0E" }}>0 %</p>;
            } else {
              return (
                <p
                  style={{
                    color:
                      params.row.profitLossPercentageIC < 0
                        ? "#f23645"
                        : params.row.profitLossPercentageIC > 0
                        ? "#26a65d"
                        : "#ED9E0E",
                  }}
                >
                  {Math.abs(params.row.profitLossPercentageIC)} %
                </p>
              );
            }
          } else {
            return (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            );
          }
        }
      },
    },
    {
      field: "profitLossDollarInvestor",
      headerName: "$ IC",
      width: 85,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.outcomeIC === "In Progress" ||
          params.row.outcomePC === "In Progress"
        ) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          if (
            params.row.equityType === "Personal & Investor Capital" ||
            params.row.equityType === "Investor Capital"
          ) {
            if (!params.row.profitLossDollarInvestor) {
              return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
            } else {
              return (
                <p
                  style={{
                    color:
                      params.row.profitLossDollarInvestor < 0
                        ? "#f23645"
                        : params.row.profitLossDollarInvestor > 0
                        ? "#26a65d"
                        : "#ED9E0E",
                  }}
                >
                  $ {Math.abs(params.row.profitLossDollarInvestor)}
                </p>
              );
            }
          } else {
            return (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            );
          }
        }
      },
    },

    {
      field: "feePC",
      headerName: "Fee PC",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.outcomeIC === "In Progress" ||
          params.row.outcomePC === "In Progress"
        ) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          if (
            params.row.equityType === "Personal & Investor Capital" ||
            params.row.equityType === "Personal Capital" ||
            params.row.equityType === "Backtest"
          ) {
            if (!params.row.feePC) {
              return <p style={{ color: "#ED9E0E" }}>£ 0</p>;
            } else {
              return (
                <p
                  style={{
                    color:
                      params.row.feePC < 0
                        ? "#f23645"
                        : params.row.feePC > 0
                        ? "#26a65d"
                        : "#ED9E0E",
                  }}
                >
                  £ {Math.abs(params.row.feePC)}
                </p>
              );
            }
          } else {
            return (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            );
          }
        }
      },
    },
    {
      field: "feeIC",
      headerName: "Fee IC",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.outcomeIC === "In Progress" ||
          params.row.outcomePC === "In Progress"
        ) {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        } else {
          if (
            params.row.equityType === "Personal & Investor Capital" ||
            params.row.equityType === "Investor Capital"
          ) {
            if (!params.row.feeIC) {
              return <p style={{ color: "#ED9E0E" }}>$ 0</p>;
            } else {
              return (
                <p
                  style={{
                    color:
                      params.row.feeIC < 0
                        ? "#f23645"
                        : params.row.feeIC > 0
                        ? "#26a65d"
                        : "#ED9E0E",
                  }}
                >
                  $ {Math.abs(params.row.feeIC)}
                </p>
              );
            }
          } else {
            return (
              <HorizontalRuleIcon
                sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
              />
            );
          }
        }
      },
    },
    {
      field: "stopLossPipsPC",
      headerName: "Stop Loss PC",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Personal Capital" ||
          params.row.equityType === "Backtest"
        ) {
          if (!params.row.stopLossPipsPC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsPC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "stopLossPipsIC",
      headerName: "Stop Loss IC",
      width: 120,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        if (
          params.row.equityType === "Personal & Investor Capital" ||
          params.row.equityType === "Investor Capital"
        ) {
          if (!params.row.stopLossPipsIC) {
            return "";
          } else {
            return (
              <p>
                {params.row.stopLossPipsIC} {checkStopLossType(params.row.pair)}
              </p>
            );
          }
        } else {
          return (
            <HorizontalRuleIcon
              sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
            />
          );
        }
      },
    },
    {
      field: "duration",
      headerName: "Duration",
      width: 90,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return runDurationCal(params);
      },
    },

    {
      field: "highlightedPair",
      headerName: "Watchlist",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "firstEntryOrScaleIn",
      headerName: "Entry No",
      width: 85,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "ro3",
    //   headerName: "Rule Of 3",
    //   flex: 1,
    //   minWidth: 90,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "entryType",
      headerName: "Entry Type",
      flex: 1,
      minWidth: 110,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "pattern",
      headerName: "Pattern",
      width: 230,
      headerAlign: "center",
      align: "center",
    },
  ];
  const mode = localStorage.getItem("equity-mode");

  const PC_Fields = [
    "outcomePC",
    "profitLossPercentagePC",
    "profitLossDollarPersonal",
    "feePC",
    "returnTotalsPersonal",
    "stopLossPipsPC",
    "openPricePC",
    "closePricePC",
  ];
  const IC_Fields = [
    "outcomeIC",
    "profitLossPercentageIC",
    "profitLossDollarInvestor",
    "feeIC",
    "returnTotalsInvestor",
    "stopLossPipsIC",
    "openPriceIC",
    "closePriceIC",
  ];

  if (mode === "Personal Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !IC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else if (mode === "Investor Capital") {
    let filteredData;
    filteredData = array.filter((obj) => !PC_Fields.includes(obj.field));
    array = filteredData;
    return array;
  } else {
    return array;
  }
};

export default columnsReflections;
