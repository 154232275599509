import { React, useContext, useState } from "react";

import AuthContext from "../../lib/auth-context";
import { DataFetcher } from "../../utils/dataFetcher";
import moment from "moment";
import ThemeContext from "../../lib/theme-context";
import CustomSingleTextField from "../textfields/singleRow";
import throwMessage from "../../utils/throwMessage";

import CustomSelect from "../select/index";
import CustomDateTimePicker from "../dateTimePickers/DateTimePicker";

import Modal from "@mui/material/Modal";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import markets from "../../lib/markets";

import ChecklistIcon from "@mui/icons-material/Checklist";

import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import InputLabel from "@mui/material/InputLabel";

import Select from "@mui/material/Select";

import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";

import useWindowDimensions from "../../utils/width";

const questions = [
  "Am I positioned well?",
  "What kind sequence has priced formed?",
  "Is this trade a HIGH or LOW risk trade?",
  "Can I move my stop loss to BE before the next major inflection point?",
  "Why would I be wrong?",
  "Are the spreads low enough to safely enter the position?",
];

const NewEntry = ({ open, onClose, calculateFields }) => {
  const theme = useContext(ThemeContext);
  const auth = useContext(AuthContext);
  const { width } = useWindowDimensions();

  const [q1, setQ1] = useState(false);
  const [q2, setQ2] = useState(false);
  const [q3, setQ3] = useState(false);
  const [q4, setQ4] = useState(false);
  const [q5, setQ5] = useState(false);
  const [q6, setQ6] = useState(false);

  const handleChangeQuestions = (event, index) => {
    switch (index) {
      case 0:
        setQ1(event.target.checked);
        break;
      case 1:
        setQ2(event.target.checked);
        break;
      case 2:
        setQ3(event.target.checked);
        break;
      case 3:
        setQ4(event.target.checked);
        break;
      case 4:
        setQ5(event.target.checked);
        break;
      case 5:
        setQ6(event.target.checked);
        break;
      default:
        break;
    }
  };

  const QuestionsList = () => {
    return questions.map((q, i) => {
      return (
        <FormGroup
          key={q}
          defaultunchecked="true"
          sx={{
            color: theme[0] === "dark" ? "#FCFCFF" : "black",
            width: width <= 500 ? "100%" : "50%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            paddingLeft: width <= 500 ? "0px" : "40px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  i === 0
                    ? q1
                    : i === 1
                    ? q2
                    : i === 2
                    ? q3
                    : i === 3
                    ? q4
                    : i === 4
                    ? q5
                    : i === 5
                    ? q6
                    : null
                }
                onChange={(e) => handleChangeQuestions(e, i)}
                sx={{
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  margin: "20px",
                }}
              />
            }
            label={q}
          />
        </FormGroup>
      );
    });
  };
  const [preTradeCheckList, setPreTradeCheckList] = useState(false);
  const [checkListView, setCheckListView] = useState(true);

  //inputs
  const [pair, setPair] = useState("");
  const [direction, setDirection] = useState("");
  const [entry, setEntry] = useState(dayjs(new Date()));
  const [exit, setExit] = useState(dayjs(new Date()));
  const [stopLossPips, setStopLossPips] = useState(0);
  const [risk, setRisk] = useState(1);
  const [openPrice, setOpenPrice] = useState("");
  const [closePrice, setClosePrice] = useState("");
  const [entryType, setEntryType] = useState("");
  const [grade, setGrade] = useState("");
  const [highlightedPair, setHighlightedPair] = useState("");
  const [firstEntryOrScaleIn, setFirstEntryOrScaleIn] = useState("");
  const [pattern, setpattern] = useState("");
  // const [ro3, setRo3] = useState("");
  const [saveSlotTwo, setSaveSlotTwo] = useState();
  const [assignedTestId, setAssignedTestId] = useState("");
  const [missedTradeYes, setMissedTradeYes] = useState("");
  const [missedTradeNo, setMissedTradeNo] = useState("No");
  const [saveSlotOne, setSaveSlotOne] = useState(
    JSON.parse(localStorage.getItem("saved-trades-1"))
  );
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [equityType, setEquityType] = useState("Personal & Investor Capital");

  const assignTrade = (test) => {
    setAssignedTestId(test.testId);
  };
  const save = (preTradeCheckList) => {
    if (pair) {
      const saveScheme = {
        id: auth.backtestingMode
          ? idTradeGenerator("backtest")
          : idTradeGenerator("live"),
        pair: pair,
        direction: direction,
        entryDate: dayjs(entry).format("DD/MM/YY"),
        entryTime: dayjs(entry).format("HH:mm"),
        exitDate: dayjs(exit).format("DD/MM/YY"),
        exitTime: dayjs(entry).format("HH:mm"),
        openPricePC:
          equityType === "Personal Capital" ||
          equityType === "Personal & Investor Capital" ||
          equityType === "Backtest"
            ? openPrice
            : "",
        closePricePC:
          equityType === "Personal Capital" ||
          equityType === "Personal & Investor Capital" ||
          equityType === "Backtest"
            ? closePrice
            : "",
        openPriceIC:
          equityType === "Investor Capital" ||
          equityType === "Personal & Investor Capital"
            ? openPrice
            : "",
        closePriceIC:
          equityType === "Investor Capital" ||
          equityType === "Personal & Investor Capital"
            ? closePrice
            : "",
        entryType: entryType,
        grade: grade,
        highlightedPair: highlightedPair,
        firstEntryOrScaleIn: firstEntryOrScaleIn,
        pattern: pattern,
        stopLossPipsIC:
          equityType === "Investor Capital" ||
          equityType === "Personal & Investor Capital"
            ? stopLossPips
            : "",
        stopLossPipsPC:
          equityType === "Personal Capital" ||
          equityType === "Personal & Investor Capital" ||
          equityType === "Backtest"
            ? stopLossPips
            : "",
        testId: assignedTestId,
        created: entry,
        preTradeCheckList: preTradeCheckList ? preTradeCheckList : "",
        equityType: auth.backtestingMode ? "Backtest" : equityType,
      };

      const name = checkSavedItems();

      if (name) {
        localStorage.setItem(name, JSON.stringify(saveScheme));
        if (name === "saved-trade-1") {
          setSaveSlotOne(saveScheme);
        } else if (name === "saved-trade-2") {
          setSaveSlotTwo(saveScheme);
        }

        throwMessage(
          "success",
          `Saved slot ${name === "saved-trade-1" ? "one" : "two"}`
        );
      }
    }
  };
  const idTradeGenerator = (type) => {
    if (type === "backtest") {
      const id = "backtest-" + Math.random().toString(16).slice(2);
      return id;
    } else if (type === "live") {
      const id = "live-" + Math.random().toString(16).slice(2);
      return id;
    } else if (type === "journal") {
      const id = "journal-" + Math.random().toString(16).slice(2);
      return id;
    }
  };
  const handleMissedYes = (e) => {
    setMissedTradeNo("");
    setMissedTradeYes(e.target.value);
    localStorage.setItem("missed-trade", e.target.value);
  };
  const handleMissedNo = (e) => {
    setMissedTradeYes("");
    setMissedTradeNo(e.target.value);
    localStorage.setItem("missed-trade", e.target.value);
  };
  const handleCheckListView = (d) => {
    setCheckListView(d);
  };
  const checkSavedItems = () => {
    if (
      JSON.parse(localStorage.getItem("saved-trade-1"))?.pair &&
      JSON.parse(localStorage.getItem("saved-trade-2"))?.pair
    ) {
      throwMessage("warning", "Saved Slots Full");
      return false;
    } else if (
      JSON.parse(localStorage.getItem("saved-trade-1"))?.pair &&
      !JSON.parse(localStorage.getItem("saved-trade-2"))?.pair
    ) {
      return "saved-trade-2";
    } else if (
      !JSON.parse(localStorage.getItem("saved-trade-1"))?.pair &&
      !JSON.parse(localStorage.getItem("saved-trade-2"))?.pair
    ) {
      return "saved-trade-1";
    } else if (
      JSON.parse(localStorage.getItem("saved-trade-2"))?.pair &&
      !JSON.parse(localStorage.getItem("saved-trade-1"))?.pair
    ) {
      return "saved-trade-1";
    }
  };
  const handleSavedTrade = (trade) => {
    if (auth.backtestingMode) {
      setEquityType("Personal & Investor Capital");
    }

    const stop =
      trade.equityType === "Personal Capital"
        ? trade?.stopLossPipsPC
        : trade.equityType === "Investor Capital"
        ? trade?.stopLossPipsIC
        : trade.equityType === "Personal & Investor Capital"
        ? trade?.stopLossPipsPC
        : trade.equityType === "Backtest"
        ? trade?.stopLossPipsPC
        : 0;
    const open =
      trade.equityType === "Personal Capital"
        ? trade?.openPricePC
        : trade.equityType === "Investor Capital"
        ? trade?.openPriceIC
        : trade.equityType === "Personal & Investor Capital"
        ? trade?.openPricePC
        : trade.equityType === "Backtest"
        ? trade?.openPricePC
        : 0;

    const close =
      trade.equityType === "Personal Capital"
        ? trade?.closePricePC
        : trade.equityType === "Investor Capital"
        ? trade?.closePriceIC
        : trade.equityType === "Personal & Investor Capital"
        ? trade?.closePricePC
        : trade.equityType === "Backtest"
        ? trade?.closePricePC
        : 0;

    if (trade?.pair) {
      setEquityType(
        !auth.backtestingMode && trade.equityType === "Backtest"
          ? "Personal & Investor Capital"
          : auth.backtestingMode
          ? "Backtest"
          : trade?.equityType
      );
      setDirection(trade?.direction);
      setStopLossPips(stop);
      setOpenPrice(open);
      setClosePrice(close ? close : "");
      setEntryType(trade?.entryType);
      setGrade(trade?.grade);
      setHighlightedPair(trade?.highlightedPair);
      setFirstEntryOrScaleIn(trade?.firstEntryOrScaleIn);
      setpattern(trade?.pattern);
      setPair(trade?.pair);
      setEntry(
        dayjs(new Date(trade?.created))
          ? dayjs(new Date(trade?.created))
          : dayjs()
      );
      throwMessage("success", `${trade?.pair} Loaded`);
    }
  };
  const getTimezone = () => {
    let date = new Date().toString().split("GMT")[1];
    date = `${date[0]} ${date[1]}${date[2]}${date[3]}${date[4]}`;
    return date;
  };

  const submit = (handleClose) => {
    const checks =
      pair &&
      direction &&
      openPrice &&
      entryType &&
      grade &&
      highlightedPair &&
      firstEntryOrScaleIn &&
      pattern;
    if (auth.backtestingMode && pair ? true : checks) {
      const sendRequest = async () => {
        const schema = {
          id: auth.backtestingMode
            ? idTradeGenerator("backtest")
            : idTradeGenerator("live"),
          pair: pair,
          direction: direction,
          entryDate: dayjs(entry).format("DD/MM/YY"),
          entryTime: dayjs(entry).format("HH:mm"),
          exitDate: auth.backtestingMode ? dayjs(exit).format("DD/MM/YY") : "",
          exitTime: auth.backtestingMode ? dayjs(exit).format("HH:mm") : "",
          openPricePC:
            equityType === "Personal Capital" ||
            equityType === "Personal & Investor Capital" ||
            equityType === "Backtest"
              ? openPrice
              : "",
          closePricePC:
            equityType === "Personal Capital" ||
            equityType === "Personal & Investor Capital" ||
            equityType === "Backtest"
              ? closePrice
              : "",
          openPriceIC:
            equityType === "Investor Capital" ||
            equityType === "Personal & Investor Capital"
              ? openPrice
              : "",
          closePriceIC:
            equityType === "Investor Capital" ||
            equityType === "Personal & Investor Capital"
              ? closePrice
              : "",

          entryType: entryType,
          grade: grade,
          highlightedPair: highlightedPair,
          firstEntryOrScaleIn: firstEntryOrScaleIn,
          pattern: pattern,
          equityType: auth.backtestingMode ? "Backtest" : equityType,
          // stopLossPips: stopLossPips,
          // openPrice: openPrice,
          // closePrice: closePrice,
          stopLossPipsIC:
            equityType === "Investor Capital" ||
            equityType === "Personal & Investor Capital"
              ? stopLossPips
              : "",
          stopLossPipsPC:
            equityType === "Personal Capital" ||
            equityType === "Personal & Investor Capital" ||
            equityType === "Backtest"
              ? stopLossPips
              : "",
          risk: risk,
          testId:
            localStorage.getItem("missed-trade") === "Yes"
              ? "Missed Trades"
              : assignedTestId,
          created: entry,
          timezone: getTimezone(),
          missedTrade:
            missedTradeYes === "Yes"
              ? "Yes"
              : missedTradeNo === "No"
              ? "No"
              : "",
        };

        const result = await DataFetcher("new-trade-entry", schema);
        if (result) {
          const virtual_array = auth.trades;

          virtual_array.push(result.trade);

          virtual_array.sort((a, b) => {
            return moment(a.created).diff(b.created);
          });

          auth.setTrades(virtual_array);

          calculateFields(virtual_array, auth.backtestingMode);

          if (auth.backtestingMode) {
            auth.setJournalsBacktesting([
              ...auth.journalsBacktesting,
              result.journal,
            ]);
          } else {
            auth.setJournals([...auth.journals, result.journal]);
          }
          throwMessage("success", `${schema?.pair} Added`);
          handleClose();
          clearForm();
        } else {
          throwMessage("error", "Something went wrong");
          setFormSubmitted(false);
        }
      };
      if (!auth.backtestingMode) {
        if (!formSubmitted);
        setFormSubmitted(true);
        sendRequest();
      } else if (auth.backtestingMode && assignedTestId) {
        if (!formSubmitted);
        setFormSubmitted(true);
        sendRequest();
      } else if (localStorage.getItem("missed-trade") === "Yes") {
        if (!formSubmitted);
        setFormSubmitted(true);
        sendRequest();
      } else {
        throwMessage("warning", "Assign Test");
      }
    } else {
      throwMessage("warning", "Missing Fields");
    }
  };
  const clearForm = () => {
    setFormSubmitted(false);
    // setRo3("");
    setpattern("");
    setFirstEntryOrScaleIn("");
    setHighlightedPair("");
    setGrade("");
    setEntryType("");
    setClosePrice("");
    setOpenPrice("");
    setStopLossPips(15);
    setEntry(dayjs(new Date()));
    setExit(dayjs(new Date()));
    setDirection("");
    setPair("");
    setMissedTradeYes("");
    setMissedTradeNo("No");
    localStorage.setItem("pre-trade-checklist", false);
  };
  const handleChecklist = () => {
    if (q1 && q2 && q3 && q4 && q5 && q6) {
      handleCheckListView(false);
      setPreTradeCheckList(true);
      localStorage.setItem("pre-trade-checklist", true);
    } else {
      throwMessage("error", "Complete Checklist");
    }
  };
  const style = { color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)" };
  const handleEquityType = () => {
    if (equityType === "Personal Capital") {
      setEquityType("Investor Capital");
    } else if (equityType === "Investor Capital") {
      setEquityType("Personal & Investor Capital");
    } else if (equityType === "Personal & Investor Capital") {
      setEquityType("Personal Capital");
    } else if (equityType === "Backtest") {
      setEquityType("Personal & Investor Capital");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "rgba(40, 42, 46)",
          width: "80%",
          maxWidth: "1000px",
          maxHeight: "800px",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          border:
            theme[0] !== "dark"
              ? "1px solid rgba(37,37,37)"
              : " 1px solid #FCFCFF",

          borderRadius: "10px",
          outline: "none",
          p: 4,
        }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="space-around"
          alignItems="center"
        >
          {!checkListView ? (
            <>
              <Grid
                item
                xs={12}
                sx={{
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    fontWeight: "300",
                    borderBottom:
                      theme[0] === "dark"
                        ? " 1px solid #FCFCFF"
                        : "1px solid rgba(37,37,37)",
                    maxWidth: "450px",

                    margin: "0 auto 5px auto",
                    padding: "0 0 10px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => handleEquityType()}
                >
                  {auth.backtestingMode ? "Backtesting Entry" : equityType}
                </h3>
              </Grid>
              <div
                style={{
                  height: width > 500 ? "60px" : null,
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "20px",
                  flexWrap: "wrap",
                }}
              >
                <Grid item xs={width <= 500 ? 12 : width > 500 ? 4 : 0}>
                  <FormControl fullWidth size={"small"}>
                    <InputLabel
                      sx={{
                        color:
                          theme[0] === "dark"
                            ? "#FCFCFF !important"
                            : "rgba(37,37,37) !important",
                        borderOutline:
                          theme[0] === "dark"
                            ? "#FCFCFF !important"
                            : "rgba(37,37,37) !important",
                      }}
                    >
                      Instrument
                    </InputLabel>

                    <Select
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor:
                              theme[0] === "dark"
                                ? "rgba(40, 42, 46)"
                                : "#FCFCFF",
                            maxHeight: "450px",

                            "& .MuiMenuItem-root": {
                              padding: 1.5,
                              justifyContent: "center",
                            },
                          },
                        },
                      }}
                      defaultValue=""
                      fullWidth
                      sx={{
                        ".MuiSvgIcon-root": {
                          fill:
                            theme[0] === "light"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        },
                        color: theme[0] === "dark" ? "#FCFCFF" : "black",

                        "& fieldset": {
                          // - The <fieldset> inside the Input-root

                          borderColor:
                            theme[0] === "dark"
                              ? "#FCFCFF"
                              : "rgba(40, 42, 46)", // - Set the Input border
                        },
                        "&:hover fieldset": {
                          borderColor:
                            theme[0] === "dark"
                              ? "#FCFCFF"
                              : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
                        },
                        "&.Mui-focused fieldset": {
                          // - Set the Input border when parent is focused
                          borderColor:
                            theme[0] === "dark"
                              ? "#FCFCFF"
                              : "rgba(40, 42, 46)",
                        },

                        input: {
                          margin: "0",
                          color: theme[0] === "dark" ? "#FCFCFF" : "black",
                        },
                      }}
                      value={pair}
                      label="Instrument"
                      onChange={(event) => {
                        setPair(event.target.value);
                      }}
                    >
                      {markets.map((m) => {
                        if (m.label === "Forex") {
                          return (
                            <ListSubheader
                              key={m.label}
                              sx={{
                                textAlign: "center",
                                bgcolor:
                                  theme[0] === "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                                color: "#ED9E0E",
                                fontSize: "0.6em",
                              }}
                            >
                              Forex
                            </ListSubheader>
                          );
                        } else if (m.label === "Indices") {
                          return (
                            <ListSubheader
                              key={m.label}
                              sx={{
                                textAlign: "center",
                                bgcolor:
                                  theme[0] === "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                                color: "#ED9E0E",
                                fontSize: "0.6em",
                              }}
                            >
                              Indices
                            </ListSubheader>
                          );
                        } else if (m.label === "Commodities") {
                          return (
                            <ListSubheader
                              key={m.label}
                              sx={{
                                textAlign: "center",
                                bgcolor:
                                  theme[0] === "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                                color: "#ED9E0E",
                                fontSize: "0.6em",
                              }}
                            >
                              Commodities
                            </ListSubheader>
                          );
                        } else if (m.label === "Crypto") {
                          return (
                            <ListSubheader
                              key={m.label}
                              sx={{
                                textAlign: "center",
                                bgcolor:
                                  theme[0] === "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                                color: "#ED9E0E",
                                fontSize: "0.6em",
                              }}
                            >
                              Crypto
                            </ListSubheader>
                          );
                        } else {
                          return (
                            <MenuItem key={m.label} sx={style} value={m.ticker}>
                              {m.label}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid
                  sx={{ marginTop: width <= 500 ? "1em" : "0" }}
                  item
                  xs={width <= 500 ? 12 : 3}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Chip
                      deleteIcon={
                        <Tooltip title="Clear">
                          <DeleteOutlinedIcon
                            sx={{
                              color: "#f23645 !important",
                            }}
                          />
                        </Tooltip>
                      }
                      sx={{
                        backgroundColor: theme[0] !== "dark" ? "#FCFCFF" : "",
                        marginRight: "1em",
                        color:
                          theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                        border:
                          theme[0] === "dark"
                            ? " 1px solid #FCFCFF"
                            : "1px solid rgba(37,37,37)",
                      }}
                      label={
                        saveSlotOne
                          ? saveSlotOne.pair
                          : localStorage.getItem("saved-trade-1")
                          ? JSON.parse(localStorage.getItem("saved-trade-1"))
                              .pair
                          : "Empty"
                      }
                      variant={"filled"}
                      onDelete={() => {
                        setSaveSlotOne({ pair: "Empty" });
                        localStorage.removeItem("saved-trade-1");

                        if (!localStorage.getItem("saved-trade-1"))
                          throwMessage("error", "Erased slot one");
                      }}
                      onClick={() => {
                        if (localStorage.getItem("saved-trade-1")) {
                          handleSavedTrade(
                            JSON.parse(localStorage.getItem("saved-trade-1"))
                          );
                          setPreTradeCheckList(
                            JSON.parse(localStorage.getItem("saved-trade-1"))
                              ?.preTradeCheckList
                          );
                        }
                      }}
                    />
                    <Chip
                      deleteIcon={
                        <Tooltip title="Clear">
                          <DeleteOutlinedIcon
                            sx={{
                              color: "#f23645 !important",
                            }}
                          />
                        </Tooltip>
                      }
                      sx={{
                        backgroundColor: theme[0] !== "dark" ? "#FCFCFF" : "",
                        color:
                          theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                        border:
                          theme[0] === "dark"
                            ? " 1px solid #FCFCFF"
                            : "1px solid rgba(37,37,37)",
                      }}
                      label={
                        saveSlotTwo
                          ? saveSlotTwo.pair
                          : localStorage.getItem("saved-trade-2")
                          ? JSON.parse(localStorage.getItem("saved-trade-2"))
                              .pair
                          : "Empty"
                      }
                      variant={"filled"}
                      onDelete={() => {
                        setSaveSlotTwo({ pair: "Empty" });
                        localStorage.removeItem("saved-trade-2");
                        if (!localStorage.removeItem("saved-trade-2"))
                          throwMessage("error", "Erased slot two");
                      }}
                      onClick={() => {
                        if (localStorage.getItem("saved-trade-2"));
                        handleSavedTrade(
                          JSON.parse(localStorage.getItem("saved-trade-2"))
                        );
                      }}
                    />
                  </Stack>
                </Grid>

                {auth.backtestingMode &&
                localStorage.getItem("missed-trade") === "No" ? (
                  auth.backtests
                    ?.filter((tests) => tests.archived?.includes("false"))
                    ?.filter((tests) => {
                      if (tests.testId === "Missed Trades") {
                        return "";
                      } else {
                        return tests;
                      }
                    })?.length > 0 ? (
                    <Grid
                      item
                      xs={width > 500 ? 2 : 12}
                      sx={{ margin: width > 500 ? "0" : "1em auto 0 auto" }}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {auth.backtests

                          ?.filter((tests) => tests.archived?.includes("false"))
                          ?.filter((tests) => {
                            if (tests.testId === "Missed Trades") {
                              return "";
                            } else {
                              return tests;
                            }
                          })
                          .slice(0, 2)
                          .map((t) => {
                            return (
                              <Tooltip title={t.testId}>
                                <Chip
                                  key={t}
                                  sx={{
                                    width: "80px",
                                    // textOverflow: "ellipsis",
                                    backgroundColor:
                                      theme[0] !== "dark" ? "#FCFCFF" : "",
                                    color:
                                      theme[0] === "dark"
                                        ? "#FCFCFF"
                                        : "rgba(40, 42, 46)",

                                    border:
                                      assignedTestId === t.testId
                                        ? " 2px solid #1976d2"
                                        : "2px solid rgba(128,128,128)",
                                  }}
                                  // label={TextAbstract(t.testId, 5)}
                                  label={t.testId}
                                  variant={
                                    assignedTestId === t.testId
                                      ? "filled"
                                      : "outlined"
                                  }
                                  onClick={(e) => assignTrade(t)}
                                />
                              </Tooltip>
                            );
                          })}
                      </Stack>
                    </Grid>
                  ) : (
                    <p
                      style={{
                        fontSize: "14px",
                        color:
                          theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
                        marginRight: width > 500 ? "30px" : "",
                        paddingTop: width > 500 ? "" : "30px",
                      }}
                    >
                      All Tests Archived
                    </p>
                  )
                ) : null}
              </div>
              <Grid item xs={12} sx={{ marginTop: "-5px" }}>
                <hr />
              </Grid>
              <Grid item xs={5}>
                <CustomSingleTextField
                  type="number"
                  size="small"
                  fullWidth
                  label="Risk %"
                  value={risk}
                  onChange={(event) => setRisk(event.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <CustomSingleTextField
                  type="number"
                  size="small"
                  fullWidth
                  label="Open Price"
                  inputProps={{ inputMode: "numeric" }}
                  value={openPrice}
                  onChange={(event) => setOpenPrice(event.target.value)}
                />
              </Grid>

              <Grid item xs={5}>
                <CustomDateTimePicker
                  defaultValue={entry}
                  size="small"
                  label="Entry"
                  value={entry}
                  onChange={(newValue) => setEntry(newValue.$d)}
                />
              </Grid>

              <Grid item xs={5}>
                <CustomSingleTextField
                  type="number"
                  size="small"
                  fullWidth
                  label="Stop Loss"
                  value={stopLossPips}
                  onChange={(event) => setStopLossPips(event.target.value)}
                />
              </Grid>

              {auth.backtestingMode ? (
                <>
                  <Grid item xs={5}>
                    <CustomSingleTextField
                      type="number"
                      size="small"
                      fullWidth
                      label="Close Price"
                      inputProps={{ inputMode: "numeric" }}
                      value={closePrice}
                      onChange={(event) => setClosePrice(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <CustomDateTimePicker
                      size="small"
                      value={exit}
                      onChange={(newValue) => setExit(newValue.$d)}
                      label="Exit"
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={width <= 500 ? 6 : 4}>
                <CustomSelect
                  size="small"
                  inputLabel="Direction"
                  value={direction}
                  label="Direction"
                  onChange={(e) => setDirection(e.target.value)}
                  menuItemArray={JSON.stringify([
                    { value: "Short", label: "Short" },
                    { value: "Long", label: "Long" },
                  ])}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <CustomSelect
                  size="small"
                  inputLabel="Rule Of 3"
                  value={ro3}
                  label="Rule Of 3"
                  onChange={(event) => setRo3(event.target.value)}
                  menuItemArray={JSON.stringify([
                    { value: "Impulsive", label: "Impulsive" },
                    { value: "Corrective", label: "Corrective" },
                    { value: "Structural", label: "Structural" },
                  ])}
                />
              </Grid> */}
              <Grid item xs={width <= 500 ? 6 : 4}>
                <FormControl fullWidth size={"small"}>
                  <InputLabel
                    sx={{
                      color:
                        theme[0] === "dark"
                          ? "#FCFCFF !important"
                          : "rgba(37,37,37) !important",
                      borderOutline:
                        theme[0] === "dark"
                          ? "#FCFCFF !important"
                          : "rgba(37,37,37) !important",
                    }}
                  >
                    Pattern
                  </InputLabel>

                  <Select
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor:
                            theme[0] === "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          maxHeight: "450px",

                          "& .MuiMenuItem-root": {
                            padding: 1.5,
                            justifyContent: "center",
                          },
                        },
                      },
                    }}
                    defaultValue=""
                    fullWidth
                    sx={{
                      ".MuiSvgIcon-root": {
                        fill:
                          theme[0] === "light" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      },
                      color: theme[0] === "dark" ? "#FCFCFF" : "black",

                      "& fieldset": {
                        // - The <fieldset> inside the Input-root

                        borderColor:
                          theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border
                      },
                      "&:hover fieldset": {
                        borderColor:
                          theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
                      },
                      "&.Mui-focused fieldset": {
                        // - Set the Input border when parent is focused
                        borderColor:
                          theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)",
                      },

                      input: {
                        margin: "0",
                        color: theme[0] === "dark" ? "#FCFCFF" : "black",
                      },
                    }}
                    value={pattern}
                    label="Pattern"
                    onChange={(event) => {
                      setpattern(event.target.value);
                    }}
                  >
                    {/* <ListSubheader sx={ListSubheaderStyle}>
                      Continuations
                    </ListSubheader> */}

                    <ListSubheader
                      sx={{
                        textAlign: "center",
                        bgcolor:
                          theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                        color: "#f23645",
                        fontSize: "0.6em",
                      }}
                    >
                      Bearish
                    </ListSubheader>
                    <MenuItem sx={style} value="2 Touch Bear Flag">
                      2 Touch Bear Flag
                    </MenuItem>
                    <MenuItem sx={style} value="3 Touch Bear Flag">
                      3 Touch Bear Flag
                    </MenuItem>

                    <MenuItem sx={style} value="2 Touch Flat Bear Flag">
                      2 Touch Flat Bear Flag
                    </MenuItem>
                    <MenuItem sx={style} value="3 Touch Flat Bear Flag">
                      3 Touch Flat Bear Flag
                    </MenuItem>

                    <MenuItem sx={style} value="Symmetrical Bear Triangle">
                      Symmetrical Bear Triangle
                    </MenuItem>

                    <MenuItem sx={style} value="Expanding Bear Triangle">
                      Expanding Bear Triangle
                    </MenuItem>

                    <MenuItem sx={style} value="Advanced Bear Flag">
                      Advanced Bear Flag
                    </MenuItem>

                    <MenuItem sx={style} value="Ascending Channel">
                      Ascending Channel
                    </MenuItem>

                    <MenuItem sx={style} value="Rising Wedge">
                      Rising Wedge
                    </MenuItem>

                    <MenuItem sx={style} value="Head & Shoulders">
                      Head & Shoulders
                    </MenuItem>

                    <MenuItem sx={style} value="Double Top">
                      Double Top
                    </MenuItem>

                    <MenuItem sx={style} value="Triple Top">
                      Triple Top
                    </MenuItem>

                    <ListSubheader
                      sx={{
                        textAlign: "center",
                        bgcolor:
                          theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                        color: "#26a65d",
                        fontSize: "0.6em",
                      }}
                    >
                      Bullish
                    </ListSubheader>

                    <MenuItem sx={style} value="2 Touch Bull Flag">
                      2 Touch Bull Flag
                    </MenuItem>

                    <MenuItem sx={style} value="3 Touch Bull Flag">
                      3 Touch Bull Flag
                    </MenuItem>

                    <MenuItem sx={style} value="2 Touch Flat Bull Flag">
                      2 Touch Flat Bull Flag
                    </MenuItem>

                    <MenuItem sx={style} value="3 Touch Flat Bull Flag">
                      3 Touch Flat Bull Flag
                    </MenuItem>

                    <MenuItem sx={style} value="Symmetrical Bull Triangle">
                      Symmetrical Bull Triangle
                    </MenuItem>

                    <MenuItem sx={style} value="Expanding Bull Triangle">
                      Expanding Bull Triangle
                    </MenuItem>

                    <MenuItem sx={style} value="Advanced Bull Flag">
                      Advanced Bull Flag
                    </MenuItem>

                    <MenuItem sx={style} value="Descending Channel">
                      Descending Channel
                    </MenuItem>

                    <MenuItem sx={style} value="Falling Wedge">
                      Falling Wedge
                    </MenuItem>

                    <MenuItem sx={style} value="Inverse Head & Shoulders">
                      Inverse Head & Shoulders
                    </MenuItem>

                    <MenuItem sx={style} value="Double Bottom">
                      Double Bottom
                    </MenuItem>

                    <MenuItem sx={style} value="Triple Bottom">
                      Triple Bottom
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={width <= 500 ? 6 : 4}>
                <CustomSelect
                  size="small"
                  inputLabel="Watchlist"
                  value={highlightedPair}
                  label="Watchlist"
                  onChange={(event) => setHighlightedPair(event.target.value)}
                  menuItemArray={JSON.stringify([
                    { value: "Top", label: "Top" },
                    { value: "Additional", label: "Additional" },
                    { value: "None", label: "None" },
                  ])}
                />
              </Grid>
              <Grid item xs={width <= 500 ? 6 : 4}>
                <CustomSelect
                  size="small"
                  inputLabel="Grade"
                  value={grade}
                  label="Grade"
                  onChange={(event) => setGrade(event.target.value)}
                  menuItemArray={JSON.stringify([
                    { value: "High Risk", label: "High Risk" },
                    { value: "Low Risk", label: "Low Risk" },
                  ])}
                />
              </Grid>
              <Grid item xs={width <= 500 ? 6 : 4}>
                <CustomSelect
                  size="small"
                  inputLabel="First Entry Scale-In"
                  value={firstEntryOrScaleIn}
                  label="First Entry Scale-In"
                  onChange={(event) =>
                    setFirstEntryOrScaleIn(event.target.value)
                  }
                  menuItemArray={JSON.stringify([
                    { value: "1st Entry", label: "First Entry" },
                    { value: "Scale In", label: "Scale In" },
                  ])}
                />
              </Grid>
              <Grid item xs={width <= 500 ? 6 : 4}>
                <CustomSelect
                  size="small"
                  inputLabel="Entry Type"
                  value={entryType}
                  label="Entry Type"
                  onChange={(event) => setEntryType(event.target.value)}
                  menuItemArray={JSON.stringify([
                    { value: "Risk", label: "Risk Entry" },
                    { value: "Reduced Risk", label: "Reduced Risk Entry" },
                  ])}
                />
              </Grid>
              <Grid
                item
                xs={!auth.backtestingMode && !preTradeCheckList ? 5 : 8}
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  height: "61px",
                  margin: "20px 0 3px 0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: preTradeCheckList ? "183px" : "150px",
                  }}
                >
                  <Tooltip title="Save To Slots">
                    <ContentCopyIcon
                      sx={{
                        color: "#1C83DE",
                        cursor: "pointer",
                      }}
                      fontSize="large"
                      onClick={() => save(preTradeCheckList)}
                    />
                  </Tooltip>
                  <Tooltip title="Save">
                    <SaveIcon
                      sx={{
                        color: formSubmitted ? "lightGrey" : "#26a65d",

                        cursor: "pointer",
                        marginLeft: preTradeCheckList ? "20px" : "0",
                      }}
                      fontSize="large"
                      onClick={() => {
                        submit(onClose);
                      }}
                    />
                  </Tooltip>
                </div>

                {!preTradeCheckList
                  ? null
                  : !auth.backtestingMode && (
                      <Tooltip title="Checklist Completed">
                        <ChecklistIcon
                          fontSize="large"
                          sx={{ color: "#26a65d" }}
                        />
                      </Tooltip>
                    )}

                {auth.backtestingMode ? (
                  <FormControl
                    row
                    sx={{
                      margin:
                        width < 500 ? "-20px 0 10px 0px" : "0px 0 10px 0px",
                    }}
                  >
                    <FormLabel
                      sx={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                        textAlign: "center",
                      }}
                      id="missed trade"
                    >
                      Missed Trade
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="missed trade"
                      defaultValue="No"
                      sx={{
                        color:
                          theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                      }}
                    >
                      <FormControlLabel
                        value="Yes"
                        control={
                          <Radio
                            onChange={handleMissedYes}
                            sx={{
                              color:
                                theme[0] !== "dark"
                                  ? "rgba(40, 42, 46)"
                                  : "#FCFCFF",
                            }}
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        value="No"
                        control={
                          <Radio
                            onChange={handleMissedNo}
                            sx={{
                              color:
                                theme[0] !== "dark"
                                  ? "rgba(40, 42, 46)"
                                  : "#FCFCFF",
                            }}
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                ) : null}
              </Grid>
            </>
          ) : (
            <>
              <h4
                style={{
                  textAlign: "center",
                  fontWeight: "300",
                  color: theme[0] === "dark" ? "#FCFCFF" : "black",
                  borderBottom:
                    theme[0] === "dark"
                      ? " 1px solid #FCFCFF"
                      : "1px solid rgba(37,37,37)",
                  width: "60%",
                  margin: "15px auto 30px auto",
                  padding: "10px 0 10px 0",
                }}
              >
                Pre-Trade Checklist
              </h4>
              <QuestionsList />
              <div
                style={{
                  margin: "1em auto 0 auto",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "35px",
                }}
              >
                <Tooltip title="Continue">
                  <CheckOutlinedIcon
                    sx={{
                      color: "#26a65d",
                      cursor: "pointer",
                    }}
                    fontSize="large"
                    onClick={handleChecklist}
                  />
                </Tooltip>
              </div>
            </>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewEntry;
