import { useEffect, useState } from "react";
import { LogInBtn, UserName, Password } from "./log-in.styles";

import { useNavigate } from "react-router-dom";

const LogIn = ({ handleLogIn }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleInputs = (value) => {
    switch (value.target.name) {
      case "email":
        setEmail(value.target.value);
        break;
      case "password":
        setPassword(value.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("TC_TOKEN_AUTH") && localStorage.getItem("user")) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        overFlowY: "hidden",
        position: "fixed",
      }}
    >
      <div
        style={{
          position: "fixed",
          height: "100%",
          width: "100%",
          backgroundImage: "url(./login.jpeg)",
          backgroundPosition: "right",
          backgroundRepeat: "noRepeat",
          backgroundSize: "cover",
          opacity: 0.4,
        }}
      ></div>
      <form style={{ height: "50%", width: "100%", margin: "0 auto" }}>
        <div
          style={{
            height: "100%",
            width: "100%",
            margin: "0 auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <Title>Trading Centre</Title> */}

          <div
            style={{
              marginTop: "10%",
              width: "400px",
              height: "400px",
              display: "grid",
              gridTemplateColumns: "1fr",
              gridTemplateRows: "repeat(3, 1fr)",
              gridRowGap: "0px",
            }}
          >
            <UserName
              sx={{
                "& fieldset": { border: "none" },
              }}
              name="email"
              value={email}
              onChange={handleInputs}
              variant="outlined"
              type="email"
              placeholder="Email"
              id="username"
              inputProps={{
                autoComplete: "username",
              }}
            />
            <Password
              sx={{
                "& fieldset": { border: "none" },
              }}
              id="password"
              name="password"
              onChange={handleInputs}
              value={password}
              variant="outlined"
              type="password"
              placeholder="Password"
              inputProps={{
                autoComplete: "current-password",
              }}
            />

            <LogInBtn
              onClick={() => {
                handleLogIn(email, password);
                // setTimeout(() => {
                //   document.body.requestFullscreen();
                // }, 400);
              }}
            >
              Log In
            </LogInBtn>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LogIn;

/* <div
style={{
  fontSize: "16px",
  color: "#FCFCFF",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
}}
>
<p>Mobile Version Coming Soon....</p>
</div> */
