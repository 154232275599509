import { React, useContext } from "react";
import ThemeContext from "../../lib/theme-context";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PositionSizeCalculator from "../../utils/PositionSizeCalculator";
// import News from "../../lib/news";

const Cal = ({ open, onClose }) => {
  const theme = useContext(ThemeContext);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          outline: "none",
        }}
      >
        <PositionSizeCalculator onClose={onClose} />
        {/* <News onClose={onClose} /> */}
      </Box>
    </Modal>
  );
};

export default Cal;
