import { useState } from "react";
import markets from "../../lib/markets";
import { DataFetcher } from "../../utils/dataFetcher";

const Logic = () => {
  const [pair, setPair] = useState("");
  const [timeFrame, setTimeFrame] = useState("");
  const [priceLarger, setPriceLarger] = useState(0);
  const [priceSmaller, setPriceSmaller] = useState(0);
  const [candleFormation, setCandleFormation] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [action, setAction] = useState(undefined);

  const [openPositions, setOpenPositions] = useState();

  // const getOpenPositions = async () => {
  // const result = await DataFetcher("open-positions");
  // if (result) {
  //   setOpenPositions(result);
  // }
  // };

  // useEffect(() => {
  //   getOpenPositions();
  // }, []);

  const timeframesArray = [
    { value: "M5", label: "5 Min" },
    { value: "M15", label: "15 Min" },
    { value: "H1", label: "1 Hour" },
    { value: "H4", label: "4 Hour" },
    { value: "D1", label: "Daily" },
    { value: "W1", label: "Weekly" },
    { value: "M1", label: "Monthly" },
  ];

  const candleFormationArray = [
    { value: "BullishRetrace", label: "Bullish Retrace" },
    { value: "BearishRetrace", label: "Bearish Retrace" },
    { value: "LowTest", label: "Low Test" },
    { value: "highTest", label: "High Test" },
  ];

  const actionArray = [
    { value: 1, label: "Move SL To BE - Price" },
    { value: 2, label: "Move SL To BE - Percent" },
    { value: 3, label: "Open Position - Assisted" },
    { value: 4, label: "Open Position - Standard" },
  ];

  const submit = async (
    pair,
    timeFrame,
    priceSmaller,
    priceLarger,
    candleFormation
  ) => {
    if (!formSubmitted);
    setFormSubmitted(true);

    const payLoad = {
      pair: pair,
      timeFrame: timeFrame,
      priceSmaller: priceSmaller,
      priceLarger: priceLarger,
      candleFormation: candleFormation,
    };

    const result = await DataFetcher("trade-assist", payLoad);
    if (result) {
      setFormSubmitted(false);
    }
  };

  return {
    markets,
    pair,
    setPair,
    timeFrame,
    setTimeFrame,
    timeframesArray,
    priceLarger,
    setPriceLarger,
    priceSmaller,
    setPriceSmaller,
    candleFormation,
    setCandleFormation,
    candleFormationArray,
    formSubmitted,
    submit,
    openPositions,
    setOpenPositions,
    action,
    setAction,
    actionArray,
  };
};

export default Logic;
