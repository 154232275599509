import { mongoDBRequest } from "../services/api/dbRequest";
import throwMessage from "../utils/throwMessage";

export const DataFetcher = async (url_screen, payload) => {
  // get functions
  const getAllTrades = async () => {
    return await mongoDBRequest("all-trades", "GET")
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-trades");
      });
  };
  const getAllReflections = async () => {
    return await mongoDBRequest("get-all-reflections", "GET")
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-reflections");
      });
  };
  const getAllForecasts = async () => {
    return await mongoDBRequest("get-all-forecast", "GET")
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-forecast");
      });
  };
  const getAllForecastsBacktesting = async () => {
    return await mongoDBRequest("get-all-forecast-backtesting", "GET")
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-forecast-backtesting");
      });
  };
  const getAllJournals = async () => {
    return await mongoDBRequest("all-journal", "GET")
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-journals");
      });
  };
  const getAllJournalsBacktesting = async () => {
    return await mongoDBRequest("all-journal-backtesting", "GET")
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-journals");
      });
  };
  const getAllBacktests = async () => {
    return await mongoDBRequest("all-backtests", "GET")
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-backtests");
      });
  };

  // post functions
  // ----- results-tracker -----
  const postNewTrade = async () => {
    return await mongoDBRequest("new-trade-entry", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-all-backtests");
      });
  };
  const postSingleTrade = async () => {
    return await mongoDBRequest("single-trade", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-single-trade");
      });
  };
  const postGenerateTrades = async () => {
    return await mongoDBRequest("generate-trades", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-generate-trades");
      });
  };

  // ----- calendar -----
  const postSingleReflection = async () => {
    return await mongoDBRequest("single-reflection", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-single-reflection");
      });
  };
  const postNewReflection = async () => {
    return await mongoDBRequest("new-reflection", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-new-reflection");
      });
  };
  const postTradesToReflect = async () => {
    return await mongoDBRequest("get-trades-to-reflect", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-get-trades-to-reflect");
      });
  };
  const postTradesToReflectHistoric = async () => {
    return await mongoDBRequest(
      "get-trades-to-reflect-historic",
      "POST",
      payload
    )
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-get-trades-to-reflect-historic");
      });
  };
  const deleteReflection = async () => {
    return await mongoDBRequest("delete-reflection", "DELETE", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "delete-reflection");
      });
  };
  const postEditReflection = async () => {
    return await mongoDBRequest("edit-reflection", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-edit-reflection");
      });
  };
  const postNewJournal = async () => {
    return await mongoDBRequest("new-journal", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-new-journal");
      });
  };
  const postAllJournal = async () => {
    return await mongoDBRequest("all-journal", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-all-journal");
      });
  };
  const postGetSingleJournal = async () => {
    return await mongoDBRequest("get-single-journal", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-get-single-journal");
      });
  };
  const postEditJournal = async () => {
    return await mongoDBRequest("edit-journal", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-edit-journal");
      });
  };
  const postNewForecast = async () => {
    return await mongoDBRequest("new-forecast", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-new-forecast");
      });
  };
  const postEditForecast = async () => {
    return await mongoDBRequest("edit-forecast", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-edit-forecast");
      });
  };
  const postSingleForecast = async () => {
    return await mongoDBRequest("single-forecast", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-single-forecast");
      });
  };
  const postNewBacktest = async () => {
    return await mongoDBRequest("new-backtest", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-new-backtest");
      });
  };
  // put functions
  // ----- results-tracker -----
  const putEditTrade = async () => {
    return await mongoDBRequest("edit-trade", "PUT", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", " get-all-backtests");
      });
  };

  const putEditBacktest = async () => {
    return await mongoDBRequest("edit-backtest", "PUT", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "edit-backtest");
      });
  };
  // ----- calendar -----
  // delete functions

  // ----- results-tracker -----
  const deleteTrade = async () => {
    return await mongoDBRequest("remove-trade", "DELETE", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "delete-remove-trade");
      });
  };
  const deleteForecast = async () => {
    return await mongoDBRequest("delete-forecast", "DELETE", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "delete-delete-forecast");
      });
  };
  const deleteBacktest = async () => {
    return await mongoDBRequest("remove-backtest", "DELETE", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "delete-remove-backtest");
      });
  };
  // ----- calendar -----

  // auth

  // post
  const postNewTransaction = async () => {
    return await mongoDBRequest("new-transaction", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-new-transaction");
      });
  };

  // ----- Auto Trader -----

  // post
  const postTradeAssist = async () => {
    return await mongoDBRequest("trade-assist", "POST", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "post-trade-assist");
      });
  };

  // get
  const getTradeAssist = async () => {
    return await mongoDBRequest("open-positions", "GET", payload)
      .then(async (data) => {
        return data;
      })
      .catch((err) => {
        throwMessage("error", "get-open-positions");
      });
  };

  switch (url_screen) {
    // ------------ Screens ----------------- //
    case "run-all":
      try {
        const database_dump_screen_one = {
          trades: await getAllTrades(),
          reflections: await getAllReflections(),
          forecasts: await getAllForecasts(),
          forecastsBacktesting: await getAllForecastsBacktesting(),
          journals: await getAllJournals(),
          journalsBacktesting: await getAllJournalsBacktesting(),
          backtests: await getAllBacktests(),
        };

        return database_dump_screen_one;
      } catch (error) {
        return;
      }

    case 0:
      try {
        const database_dump_screen_one = {
          // trades: await getAllTrades(),
          // reflections: await getAllReflections(),
          // forecasts: await getAllForecasts(),
          // forecastsBacktesting: await getAllForecastsBacktesting(),
          // journals: await getAllJournals(),
          // backtests: await getAllBacktests(),
        };
        return database_dump_screen_one;
      } catch (error) {
        return;
      }

    case 1:
      try {
        // limit these calls to 50 records ??
        const database_dump_screen_two = {
          // trades: await getAllTrades(),
          // journals: await getAllJournals(),
          // backtests: await getAllBacktests(),
          // forecasts: await getAllForecasts(),
        };
        return database_dump_screen_two;
      } catch (error) {
        return;
      }
    // ------------ Results Tracker ----------------- //
    case "all-trades":
      try {
        return await getAllTrades();
      } catch (error) {
        return;
      }
    case "new-trade-entry":
      try {
        return await postNewTrade();
      } catch (error) {
        return;
      }
    case "single-trade":
      try {
        return await postSingleTrade();
      } catch (error) {
        return;
      }
    case "generate-trades":
      try {
        return await postGenerateTrades();
      } catch (error) {
        return;
      }
    case "edit-trade":
      try {
        return await putEditTrade();
      } catch (error) {
        return;
      }
    // case "mass-edit-trade":
    //   try {
    //     return await putMassEditTrade();
    //   } catch (error) {
    //     return;
    //   }
    case "remove-trade":
      try {
        return await deleteTrade();
      } catch (error) {
        return;
      }

    case "new-backtest":
      try {
        return await postNewBacktest();
      } catch (error) {
        return;
      }
    case "edit-backtest":
      try {
        return await putEditBacktest();
      } catch (error) {
        return;
      }
    case "remove-backtest":
      try {
        return await deleteBacktest();
      } catch (error) {
        return;
      }

    // ------------ Calendar ----------------- //

    case "get-trades-to-reflect":
      try {
        return await postTradesToReflect();
      } catch (error) {
        return;
      }
    case "get-trades-to-reflect-historic":
      try {
        return await postTradesToReflectHistoric();
      } catch (error) {
        return;
      }

    case "new-reflection":
      try {
        return await postNewReflection();
      } catch (error) {
        return;
      }
    case "single-reflection":
      try {
        return await postSingleReflection();
      } catch (error) {
        return;
      }

    case "edit-reflection":
      try {
        return await postEditReflection();
      } catch (error) {
        return;
      }

    case "delete-reflection":
      try {
        return await deleteReflection();
      } catch (error) {
        return;
      }

    case "new-journal":
      try {
        return await postNewJournal();
      } catch (error) {
        return;
      }
    case "all-journal":
      try {
        return await postAllJournal();
      } catch (error) {
        return;
      }

    case "get-single-journal":
      try {
        return await postGetSingleJournal();
      } catch (error) {
        return;
      }
    case "edit-journal":
      try {
        return await postEditJournal();
      } catch (error) {
        return;
      }

    case "single-forecast":
      try {
        return await postSingleForecast();
      } catch (error) {
        return;
      }
    case "edit-forecast":
      try {
        return await postEditForecast();
      } catch (error) {
        return;
      }
    case "new-forecast":
      try {
        return await postNewForecast();
      } catch (error) {
        return;
      }
    case "delete-forecast":
      try {
        return await deleteForecast();
      } catch (error) {
        return;
      }

    case "new-transaction":
      try {
        return await postNewTransaction();
      } catch (error) {
        return;
      }

    // ------------ Auto Trader ----------------- //

    case "trade-assist":
      try {
        return await postTradeAssist();
      } catch (error) {
        return;
      }
    case "open-positions":
      try {
        return await getTradeAssist();
      } catch (error) {
        return;
      }

    default:
      break;
  }
};
