import { React, useState, useContext, useCallback } from "react";

import ThemeContext from "../../lib/theme-context";
import AuthContext from "../../lib/auth-context";

import dayjs from "dayjs";
import moment from "moment";

import CustomSelect from "../select/index";
import CustomMultiTextField from "../textfields/multiRow_standard";
import CustomDatePicker from "../dateTimePickers/DateTimePicker";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import markets from "../../lib/markets";
import { DataFetcher } from "../../utils/dataFetcher";
import throwMessage from "../../utils/throwMessage";
import { useLongPress } from "use-long-press";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import Tooltip from "@mui/material/Tooltip";

import useWindowDimensions from "../../utils/width";

const imgStyle = {
  width: "100%",
  height: "430px",
  objectFit: "cover",
  borderRadius: "15px",
  cursor: "pointer",
  padding: "10px",
};

const NewForecast = ({ open, onClose }) => {
  const auth = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const [pair, setPair] = useState("");
  const [direction, setDirection] = useState("");
  const [date, setDate] = useState(dayjs(new Date()));
  const [changeDate, setChangeDate] = useState(false);
  const [sequence, setSequence] = useState(undefined);
  const [positioning, setPositioning] = useState(undefined);
  const [grade, setGrade] = useState(undefined);
  const [dailyLink, setDailyLink] = useState("");
  const [hourlyLink, setHourlyLink] = useState("");
  const [dailyDescrtpiton, setDailyDescrtpiton] = useState("");
  const [hourlyDescrtpiton, setHourlyDescrtpiton] = useState("");
  const [lowerTimeframeName, setLowerTimeframeName] = useState("");
  const [higherTimeframeName, setHigherTimeframeName] = useState("");

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChangeSequence = (event) => {
    setSequence(event.target.value);
  };
  const handleChangePositioning = (event) => {
    setPositioning(event.target.value);
  };
  const handleChangeGrade = (event) => {
    setGrade(event.target.value);
  };
  const handleSave = async () => {
    const schema = {
      active: "true",
      pair: pair,
      direction: direction,
      date: date,
      sequence: sequence,
      positioning: positioning,
      grade: grade,
      lowerTimeframeName: lowerTimeframeName,
      higherTimeframeName: higherTimeframeName,
      dailyLink: dailyLink,
      hourlyLink: hourlyLink,
      dailyDescrtpiton: dailyDescrtpiton,
      hourlyDescrtpiton: hourlyDescrtpiton,
      backtestingMode: auth.backtestingMode,
    };
    if (
      schema.pair &&
      schema.direction &&
      schema.date &&
      schema.sequence &&
      schema.positioning &&
      schema.grade &&
      schema.dailyLink &&
      schema.hourlyLink
    ) {
      if (!formSubmitted);
      setFormSubmitted(true);
      const result = await DataFetcher("new-forecast", schema);
      if (result) {
        if (auth.backtestingMode) {
          auth.setForecastsBacktesting([...auth.forecastsBacktesting, result]);
        } else {
          auth.setForecasts([...auth.forecasts, result]);
        }
        throwMessage("success", "Success");
        goBack();
        setFormSubmitted(false);
      } else {
        throwMessage("error", "Something went wrong");
        setFormSubmitted(false);
      }
    } else {
      auth?.throwMessage("warning", "Missing Fields");
    }
  };
  const goBack = () => {
    onClose();
    setDate(dayjs(new Date()));
    setPair("");
    setChangeDate(false);
    setSequence("");
    setPositioning("");
    setGrade("");
    setDailyLink("");
    setHourlyLink("");
    setDailyDescrtpiton("");
    setHourlyDescrtpiton("");
    setDirection("");
    setHigherTimeframeName("");
    setLowerTimeframeName("");
  };

  const callback = useCallback((props) => {
    if (props.target.alt === "setDailyLink") {
      setDailyLink("");
    } else if (props.target.alt === "setHourlyLink") {
      setHourlyLink("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bind = useLongPress(callback, { threshold: 1000 });

  const style = { color: theme[0] === "dark" ? "#FCFCFF" : "rgba(40, 42, 46)" };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ maxWidth: "1920px", margin: "0 auto" }}
    >
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          width: "100%",
          height: "100%",
          backgroundColor: theme[0] === "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
          padding: "10px 15px 15px 15px",
          margin: "0 auto",
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ minHeight: "100%" }}
        >
          <Grid
            item
            xs={12}
            sx={{ color: theme[0] === "dark" ? "#FCFCFF" : "black" }}
          >
            <h3
              style={{
                textAlign: "center",
                fontWeight: "300",
                borderBottom:
                  theme[0] === "dark"
                    ? " 1px solid #FCFCFF"
                    : "1px solid rgba(37,37,37)",
                maxWidth: "750px",
                margin: "1em auto 50px auto",
                padding: "0 0 15px 0",
                cursor: "pointer",
                fontSize: "26px",
              }}
            >
              <span onClick={() => setPair("")}>{pair}</span>{" "}
              <span
                style={{
                  color:
                    direction === "Short"
                      ? "#db0671"
                      : direction === "Long"
                      ? "#26a65d"
                      : "#ED9E0E",
                }}
                onClick={() => setDirection("")}
              >
                {direction}
              </span>{" "}
              <span
                onClick={() => {
                  setChangeDate(true);
                  setDate(dayjs(new Date()));
                }}
              >
                {changeDate && !pair
                  ? "New"
                  : moment(date).format("dddd Do YYYY")}{" "}
                Forecast
              </span>{" "}
            </h3>
          </Grid>

          {changeDate ? (
            <Grid item xs={12} sx={{ margin: "0em auto 2em auto" }}>
              <div style={{ width: "300px", margin: "0 auto" }}>
                <CustomDatePicker
                  size="small"
                  label="Event Date"
                  value={date}
                  onChange={(newValue) => {
                    setChangeDate(false);
                    setDate(newValue.$d);
                  }}
                />
              </div>
            </Grid>
          ) : null}
          <>
            {pair && direction ? (
              <div
                style={{
                  margin: width <= 500 ? "-1.5em auto 0 auto" : "0 auto",
                  display: "flex",
                  flexDirection: width <= 500 ? "column" : "row",
                  justifyContent:
                    width <= 500 ? "space-around" : "space-between",
                  alignItems: "center",
                  width: "100%",
                  height: width <= 500 ? "300px" : "80px",
                }}
              >
                {positioning ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                    }}
                  >
                    <p
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "300",
                        color:
                          positioning === "Excellent Positioning"
                            ? "#26a65d"
                            : positioning === "Average Positioning"
                            ? "#ED9E0E"
                            : "#f23645",
                      }}
                      onClick={() => setPositioning(undefined)}
                    >
                      {positioning}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      margin: "0 auto",
                    }}
                  >
                    <FormControl>
                      <FormLabel
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                        id="Positioning"
                      >
                        Positioning
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="positioning"
                        defaultValue=""
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      >
                        <FormControlLabel
                          value="Poor Positioning"
                          control={
                            <Radio
                              onChange={handleChangePositioning}
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Poor
                            </p>
                          }
                        />
                        <FormControlLabel
                          value="Average Positioning"
                          control={
                            <Radio
                              onChange={handleChangePositioning}
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Average
                            </p>
                          }
                        />
                        <FormControlLabel
                          value="Excellent Positioning"
                          control={
                            <Radio
                              onChange={handleChangePositioning}
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Excellent
                            </p>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                {sequence ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                    }}
                  >
                    <p
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "300",
                        color:
                          sequence === "Excellent Sequence"
                            ? "#26a65d"
                            : sequence === "Average Sequence"
                            ? "#ED9E0E"
                            : "#f23645",
                      }}
                      onClick={() => setSequence(undefined)}
                    >
                      {sequence}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      margin: "0 auto",
                    }}
                  >
                    <FormControl>
                      <FormLabel
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                        id="sequence"
                      >
                        Sequence
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="go-to"
                        defaultValue=""
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      >
                        <FormControlLabel
                          value="Poor Sequence"
                          control={
                            <Radio
                              onChange={handleChangeSequence}
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Poor
                            </p>
                          }
                        />
                        <FormControlLabel
                          value="Average Sequence"
                          control={
                            <Radio
                              onChange={handleChangeSequence}
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Average
                            </p>
                          }
                        />

                        <FormControlLabel
                          value="Excellent Sequence"
                          control={
                            <Radio
                              onChange={handleChangeSequence}
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Excellent
                            </p>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
                {grade ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                    }}
                  >
                    <p
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "300",
                        color: grade === "Low Risk" ? "#26a65d" : "#f23645",
                      }}
                      onClick={() => setGrade(undefined)}
                    >
                      {grade}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      margin: "0 auto",
                    }}
                  >
                    <FormControl>
                      <FormLabel
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                        id="grade"
                      >
                        Grade
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="grade"
                        defaultValue=""
                        sx={{
                          color:
                            theme[0] !== "dark"
                              ? "rgba(40, 42, 46)"
                              : "#FCFCFF",
                        }}
                      >
                        <FormControlLabel
                          value="Low Risk"
                          control={
                            <Radio
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                              onChange={handleChangeGrade}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              Low Risk
                            </p>
                          }
                        />

                        <FormControlLabel
                          value="High Risk"
                          control={
                            <Radio
                              sx={{
                                color:
                                  theme[0] !== "dark"
                                    ? "rgba(40, 42, 46)"
                                    : "#FCFCFF",
                              }}
                              onChange={handleChangeGrade}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "13px",
                              }}
                            >
                              High Risk
                            </p>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}
              </div>
            ) : (
              <>
                {!pair ? (
                  <Grid item xs={width <= 500 ? 8 : 3} sx={{ margin: width <= 500 ? "-1.5em auto 0 auto" : "2em auto" }}>
                    <FormControl fullWidth size={"small"}>
                      <InputLabel
                        sx={{
                          color:
                            theme[0] === "dark"
                              ? "#FCFCFF !important"
                              : "rgba(37,37,37) !important",
                          borderOutline:
                            theme[0] === "dark"
                              ? "#FCFCFF !important"
                              : "rgba(37,37,37) !important",
                        }}
                      >
                        Instrument
                      </InputLabel>

                      <Select
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              bgcolor:
                                theme[0] === "dark"
                                  ? "rgba(40, 42, 46)"
                                  : "#FCFCFF",
                              maxHeight: "450px",

                              "& .MuiMenuItem-root": {
                                padding: 1.5,
                                justifyContent: "center",
                              },
                            },
                          },
                        }}
                        defaultValue=""
                        fullWidth
                        sx={{
                          ".MuiSvgIcon-root": {
                            fill:
                              theme[0] === "light"
                                ? "rgba(40, 42, 46)"
                                : "#FCFCFF",
                          },
                          color: theme[0] === "dark" ? "#FCFCFF" : "black",

                          "& fieldset": {
                            // - The <fieldset> inside the Input-root

                            borderColor:
                              theme[0] === "dark"
                                ? "#FCFCFF"
                                : "rgba(40, 42, 46)", // - Set the Input border
                          },
                          "&:hover fieldset": {
                            borderColor:
                              theme[0] === "dark"
                                ? "#FCFCFF"
                                : "rgba(40, 42, 46)", // - Set the Input border when parent has :hover
                          },
                          "&.Mui-focused fieldset": {
                            // - Set the Input border when parent is focused
                            borderColor:
                              theme[0] === "dark"
                                ? "#FCFCFF"
                                : "rgba(40, 42, 46)",
                          },

                          input: {
                            margin: "0",
                            color: theme[0] === "dark" ? "#FCFCFF" : "black",
                          },
                        }}
                        value={pair}
                        label="Instrument"
                        onChange={(event) => {
                          setPair(event.target.value);
                        }}
                      >
                        {markets.map((m) => {
                          if (m.label === "Forex") {
                            return (
                              <ListSubheader
                                key={m.label}
                                sx={{
                                  textAlign: "center",
                                  bgcolor:
                                    theme[0] === "dark"
                                      ? "rgba(40, 42, 46)"
                                      : "#FCFCFF",
                                  color: "#ED9E0E",
                                  fontSize: "0.6em",
                                }}
                              >
                                Forex
                              </ListSubheader>
                            );
                          } else if (m.label === "Indices") {
                            return (
                              <ListSubheader
                                key={m.label}
                                sx={{
                                  textAlign: "center",
                                  bgcolor:
                                    theme[0] === "dark"
                                      ? "rgba(40, 42, 46)"
                                      : "#FCFCFF",
                                  color: "#ED9E0E",
                                  fontSize: "0.6em",
                                }}
                              >
                                Indices
                              </ListSubheader>
                            );
                          } else if (m.label === "Commodities") {
                            return (
                              <ListSubheader
                                key={m.label}
                                sx={{
                                  textAlign: "center",
                                  bgcolor:
                                    theme[0] === "dark"
                                      ? "rgba(40, 42, 46)"
                                      : "#FCFCFF",
                                  color: "#ED9E0E",
                                  fontSize: "0.6em",
                                }}
                              >
                                Commodities
                              </ListSubheader>
                            );
                          } else if (m.label === "Crypto") {
                            return (
                              <ListSubheader
                                key={m.label}
                                sx={{
                                  textAlign: "center",
                                  bgcolor:
                                    theme[0] === "dark"
                                      ? "rgba(40, 42, 46)"
                                      : "#FCFCFF",
                                  color: "#ED9E0E",
                                  fontSize: "0.6em",
                                }}
                              >
                                Crypto
                              </ListSubheader>
                            );
                          } else {
                            return (
                              <MenuItem
                                key={m.label}
                                sx={style}
                                value={m.ticker}
                              >
                                {m.label}
                              </MenuItem>
                            );
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                {!direction ? (
                  <Grid item xs={width <= 500 ? 8 : 3} sx={{ margin: width <= 500 ? "0 auto" : "2em auto" }}>
                    <CustomSelect
                      id="Direction"
                      size="small"
                      inputLabel="Direction"
                      value={direction}
                      label="Direction"
                      onChange={(e) => setDirection(e.target.value)}
                      menuItemArray={JSON.stringify([
                        { value: "Short", label: "Short" },
                        { value: "Long", label: "Long" },
                        { value: "Netural", label: "Netural" },
                      ])}
                    />
                  </Grid>
                ) : null}
              </>
            )}

            <hr style={{ width: "90%" }} />

            <Grid
              container
              spacing={0}
              sx={{ margin: "0.5em auto 0 auto", width: "100%" }}
            >
              <Grid item xs={6}>
                <div
                  style={{
                    minWidth: higherTimeframeName ? "80%" : "100%",
                    minHeight: "60px",
                    margin: "1em auto 0 auto",
                  }}
                >
                  <CustomMultiTextField
                    name="Higher Timeframe Name"
                    size="small"
                    rows={1}
                    variant="standard"
                    value={higherTimeframeName}
                    label={higherTimeframeName ? "" : "Enter Timeframe"}
                    onChange={(e) => {
                      setHigherTimeframeName(e.target.value);
                    }}
                    fontSize="20px"
                    disableUnderline={true}
                  />
                </div>

                {dailyLink ? (
                  dailyLink.startsWith("https://www.tradingview.com") ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={dailyLink}
                      alt="setDailyLink"
                      height="auto"
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                      }}
                      onClick={() => setDailyLink("")}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minWidth: dailyDescrtpiton ? "80%" : "100%",
                      minHeight: "60px",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      name="Higher Timeframe Screenshot Link"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={dailyLink}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setDailyLink(e.target.value);
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}
                <div
                  style={{
                    width: dailyDescrtpiton ? "80%" : "100%",
                    margin: "0 auto",
                  }}
                >
                  <CustomMultiTextField
                    name="Higher Timeframe Descrtpiton"
                    size="small"
                    rows={6}
                    variant="standard"
                    value={dailyDescrtpiton}
                    label={dailyDescrtpiton ? "" : "Enter Descrtpiton"}
                    onChange={(e) => {
                      setDailyDescrtpiton(e.target.value);
                    }}
                    fontSize="16px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{
                    minHeight: "60px",
                    margin: "1em auto 0 auto",
                  }}
                >
                  <CustomMultiTextField
                    name="Lower Timeframe Name"
                    size="small"
                    rows={1}
                    variant="standard"
                    value={lowerTimeframeName}
                    label={lowerTimeframeName ? "" : "Enter Timeframe"}
                    onChange={(e) => {
                      setLowerTimeframeName(e.target.value);
                    }}
                    fontSize="20px"
                    disableUnderline={true}
                  />
                </div>
                {hourlyLink ? (
                  hourlyLink.startsWith("https://www.tradingview.com") ? (
                    <img
                      {...bind()}
                      style={imgStyle}
                      src={hourlyLink}
                      alt="setHourlyLink"
                      height="auto"
                    />
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#f23645",
                        cursor: "pointer",
                      }}
                      onClick={() => setHourlyLink("")}
                    >
                      Invalid Link
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      minHeight: "60px",
                      margin: "0 auto",
                    }}
                  >
                    <CustomMultiTextField
                      labelColor="#f23645"
                      name="Lower Timeframe Screenshot Link"
                      size="small"
                      rows={1}
                      variant="standard"
                      value={hourlyLink}
                      label="No Screenshot Detected"
                      onChange={(e) => {
                        setHourlyLink(e.target.value);
                      }}
                      fontSize="16px"
                      disableUnderline={true}
                    />
                  </div>
                )}

                <div
                  style={{
                    width: hourlyDescrtpiton ? "80%" : "100%",

                    margin: "0 auto",
                  }}
                >
                  <CustomMultiTextField
                    name="Lower Timeframe Descrtpiton"
                    size="small"
                    rows={6}
                    variant="standard"
                    value={hourlyDescrtpiton}
                    label={hourlyDescrtpiton ? "" : "Enter Descrtpiton"}
                    onChange={(e) => {
                      setHourlyDescrtpiton(e.target.value);
                    }}
                    fontSize="16px"
                    disableUnderline={true}
                  />
                </div>
              </Grid>
            </Grid>
          </>

          <Grid
            item
            xs={width <= 500 ? 6 : 2}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              margin: "0 0 1em 0",
            }}
          >
            <Tooltip title="Close">
              <CloseIcon
                sx={{
                  color: theme[0] !== "dark" ? "rgba(40, 42, 46)" : "#FCFCFF",
                  cursor: "pointer",
                  margin: "0 -5px 0 0",
                }}
                fontSize="large"
                onClick={() => goBack()}
              />
            </Tooltip>

            <Tooltip title="Save">
              <SaveIcon
                sx={{
                  color: formSubmitted ? "lightGrey" : "#26a65d",
                  cursor: "pointer",
                  margin: "0 0 0 -5px",
                }}
                fontSize="large"
                onClick={() => {
                  handleSave();
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewForecast;
